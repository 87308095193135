import classNames from 'classnames';
import React, { ReactNode, useEffect } from 'react';

import patternBottomLeft from '../../assets/images/pattern-bottom-left.svg';
import patternBottomRight from '../../assets/images/pattern-bottom-right.svg';
import AnnouncementKeys from '../announcements/AnnouncementKeys';
import Announcements from '../announcements/Announcements';
import { BackButton } from '../back-button/BackButton';
import Button, { ButtonProps } from '../button/Button';
import { LoadingSpinnerOverlay } from '../loading-spinner-overlay/LoadingSpinnerOverlay';
import Navigation from '../navigation/Navigation';
import { ProgressBar } from '../progress-bar/ProgressBar';
import { useZendesk } from '../zendesk/Zendesk';
import Counter from './counter/Counter';

import './Layout.scss';

export interface LayoutProps {
    className?: string;
    isContentCentered?: boolean;
    isContentCenteredWide?: boolean;
    isDashboard?: boolean;
    nav?: boolean;
    bgPattern?: boolean;
    showZendesk?: boolean;
    showProgressBar?: boolean;
    currentProgress?: number;
    showBackButton?: boolean;
    showLoading?: boolean;
    title?: string;
    description?: string | React.ReactNode;
    children?: React.ReactNode;
    banner?: React.ReactNode;
    footer?: React.ReactNode;
    showPdsVersion?: boolean;
    primaryCta?: ButtonProps;
    secondaryCta?: ButtonProps;
    customFixedCTA?: ReactNode;
    ctaDescription?: string | ReactNode;
    showAnnouncement?: AnnouncementKeys;
    currentStepCount?: number;
    totalStepCount?: number;
    disableBackButton?: boolean;
    extraWide?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
    className,
    isContentCentered = true,
    isContentCenteredWide,
    isDashboard,
    children,
    nav = false,
    bgPattern = true,
    showZendesk = false,
    showProgressBar = false,
    currentProgress,
    showBackButton = false,
    showLoading = false,
    banner,
    title,
    description,
    footer,
    showPdsVersion = true,
    primaryCta,
    secondaryCta,
    ctaDescription,
    customFixedCTA,
    showAnnouncement,
    currentStepCount,
    totalStepCount,
    disableBackButton = false,
    extraWide = false,
}) => {
    const {
        isZendeskLoaded,
        showZendesk: showZendeskWidget,
        hideZendesk,
        minimiseZendesk,
        setWithNavBar,
    } = useZendesk();

    useEffect(() => {
        if (isZendeskLoaded) {
            if (showZendesk) {
                showZendeskWidget();
                setWithNavBar(nav);
            } else {
                hideZendesk();
            }
        }
    }, [hideZendesk, isZendeskLoaded, nav, setWithNavBar, showZendesk, showZendeskWidget]);

    useEffect(() => {
        // Scroll to top when change path so that on shorter screens the user reads from the top
        setTimeout(() => {
            window.scroll({
                top: 0,
                behavior: 'smooth',
            });
        }, 0);

        // Return cleanup function to minimise zendesk on move away from page
        return () => {
            if (showZendesk) {
                minimiseZendesk();
            }
        };
    }, []);

    const renderFixedCTA = () => {
        return (
            <>
                {(ctaDescription || primaryCta || secondaryCta || customFixedCTA) && (
                    <div
                        className={classNames(
                            'layout__fixed-bottom',
                            !customFixedCTA && 'layout__fixed-bottom--with-bg',
                        )}
                    >
                        {customFixedCTA || (
                            <>
                                {ctaDescription &&
                                    (typeof ctaDescription === 'string' ? (
                                        <p className="layout__cta-description">{ctaDescription}</p>
                                    ) : (
                                        { ctaDescription }
                                    ))}

                                <div className="layout__fixed-bottom-cta-section">
                                    {primaryCta && (
                                        <Button
                                            {...primaryCta}
                                            className={classNames('layout__fixed-bottom-cta', primaryCta.className)}
                                        />
                                    )}
                                    {secondaryCta && (
                                        <Button
                                            {...secondaryCta}
                                            className={classNames(
                                                'layout__fixed-bottom-cta',
                                                'layout__fixed-bottom-cta--secondary',
                                                secondaryCta.className,
                                            )}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </>
        );
    };

    const renderLayoutContent = () => (
        <>
            {showLoading && <LoadingSpinnerOverlay />}
            {showBackButton && <BackButton disabled={disableBackButton} />}
            {currentStepCount && totalStepCount && <Counter current={currentStepCount} total={totalStepCount} />}
            {!!title && <h1 className="layout__header">{title}</h1>}
            {!!description && <p className="layout__description">{description}</p>}
            {children}
            {renderFixedCTA()}
        </>
    );

    const showFooter = !!footer || showPdsVersion;
    const shouldRenderProgressBar = showProgressBar && !!currentProgress;

    return (
        <main className={classNames(shouldRenderProgressBar && 'layout--offset-for-progress-bar')}>
            {/* Position Fixed Nav */}
            {nav && <Navigation />}

            {/* Position Fixed Background Pattern - only on md and above */}
            {bgPattern && (
                <>
                    <img src={patternBottomLeft} alt="background pattern" className="layout--bg-pattern-left" />
                    <img src={patternBottomRight} alt="background pattern" className="layout--bg-pattern-right" />
                </>
            )}

            {/* Progress Bar - Fixed on top */}
            {shouldRenderProgressBar && (
                <ProgressBar className="layout__progress-bar" completed={currentProgress as number} />
            )}

            {/* Banner goes on top of layout */}
            {banner && banner}

            {/* Announcement goes on bottom of layout */}
            {showAnnouncement && <Announcements showAnnouncement={showAnnouncement} />}

            {/* Main content area */}
            <div
                className={classNames(
                    isContentCentered && !isDashboard ? 'layout--centered' : 'layout',
                    isContentCenteredWide && 'layout--centered-wide',
                    isDashboard && 'layout--dashboard',
                    showFooter && 'layout--with-footer',
                    extraWide && 'layout--extraWide',
                    className,
                )}
            >
                {showFooter ? (
                    <>
                        <div>{renderLayoutContent()}</div>
                        <div>{!!footer && footer}</div>
                    </>
                ) : (
                    renderLayoutContent()
                )}
            </div>
        </main>
    );
};

export default Layout;
