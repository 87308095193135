import History from 'history';
import StorageKeys from './constants/StorageKeys';

const storeLocationState = (location: History.Location): void => {
    const locationState = location.state;

    if (locationState !== undefined) {
        sessionStorage.setItem(StorageKeys.LOCATION_STATE, JSON.stringify(locationState));
    }
};

export default storeLocationState;
