import LocalizedStrings from 'react-localization';

const notFoundContent = new LocalizedStrings({
    en: {
        heading: 'Page not found',
        description: 'The page you&apos;re looking for doesn&apos;t exist or has been moved.',
        primaryCTA: 'Home',
        secondaryCTA: 'Our products',
    },
});

export default notFoundContent;
