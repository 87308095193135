import LocalizedStrings from 'react-localization';

const checkAgeEligibility = new LocalizedStrings({
    en: {
        title: 'You need to be {minAge} to be eligible to purchase cover.',
        description:
            'If you need further information, or your details are incorrect, please reach out to {customerSupport}',
        cta: 'Back to dashboard',
    },
});

export default checkAgeEligibility;
