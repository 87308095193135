import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const getCreditBalance = async ({ accessToken, axios }: { accessToken: string } & ServiceArgs) => {
    const {
        data: { credits },
    } = await axios.get<{ credits: number }>(`${baseApiPath}/api/v1/billing/stripe/balance`, toHeaders(accessToken));

    return credits;
};

export default asService(getCreditBalance);
