// The HTML gods have blessed us with a HTML5 implementation of "accordions"
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details

import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import './DisclosureWidget.scss';

export interface DisclosureWidgetProps {
    summary: string | ReactNode;
    children: ReactNode;
    className?: string;
    // Provide open and onToggle props to make this a controlled component
    // Leave as undefined to use as uncontrolled component ie DOM controlled
    open?: boolean;
    onToggle?: () => void;
}

const DisclosureWidget: FC<DisclosureWidgetProps> = ({ summary, children, open, onToggle, className }) => {
    const handleClick = (e: any) => {
        if (onToggle) {
            // Needs prevent default to make this a controlled component
            // https://github.com/facebook/react/issues/15486#issuecomment-873516817
            e.preventDefault();
            onToggle();
        }
    };

    return (
        <details className={classNames('disclosure-widget', className)} open={open}>
            <summary className="disclosure-widget__summary" onClick={handleClick}>
                {summary}
            </summary>
            {children}
        </details>
    );
};

export default DisclosureWidget;
