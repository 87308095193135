import { createMachine } from 'xstate';
import ProductGroupsResponse from '../../../business-logic/models/ProductGroupsResponse';
import { initialContext, PurchaseEntryMachineContextTypes } from './context/purchaseEntryMachineContext';
import {
    initialiseContext,
    setSelectedProductGrouping,
    setOnlyProductOptionAsSelectedProductOption,
} from './actions/purchaseEntryMachineActions';
import {
    isValidProductGroup,
    isProductOptionSelectionRequired,
    isDestinationSelectionRequired,
} from './guards/purchaseEntryMachineGuards';

const purchaseEntryMachine = createMachine(
    {
        id: 'PurchaseEntryMachine',
        initial: 'waitForContextInitialisation',
        tsTypes: {} as import('./purchaseEntryMachine.typegen').Typegen0,
        schema: {
            context: {} as PurchaseEntryMachineContextTypes,
            events: {} as {
                type: 'CONTEXT_INITIALISED';
                data: {
                    productGroups: ProductGroupsResponse;
                    selectedProductGroupingParam: string | undefined;
                };
            },
        },
        context: initialContext,
        states: {
            waitForContextInitialisation: {
                on: {
                    CONTEXT_INITIALISED: {
                        actions: 'initialiseContext',
                        target: 'validateProductGroupParam',
                    },
                },
            },
            validateProductGroupParam: {
                always: [
                    {
                        cond: 'isValidProductGroup',
                        actions: 'setSelectedProductGrouping',
                        target: 'validateIfProductOptionSelectedRequired',
                    },
                    {
                        target: 'redirectToProductGroupSelection',
                    },
                ],
            },
            validateIfProductOptionSelectedRequired: {
                always: [
                    {
                        cond: 'isProductOptionSelectionRequired',
                        target: 'redirectToProductOptionSelection',
                    },
                    {
                        target: 'validateIfDestinationSelectionRequired',
                        actions: 'setOnlyProductOptionAsSelectedProductOption',
                    },
                ],
            },
            validateIfDestinationSelectionRequired: {
                always: [
                    {
                        cond: 'isDestinationSelectionRequired',
                        target: 'redirectToDestinationSelection',
                    },
                    {
                        target: 'redirectToDatesSelection',
                    },
                ],
            },
            redirectToProductGroupSelection: {
                entry: 'redirectToProductGroupSelection',
                type: 'final',
            },
            redirectToProductOptionSelection: {
                entry: 'redirectToProductOptionSelection',
                type: 'final',
            },
            redirectToDestinationSelection: {
                entry: 'redirectToDestinationSelection',
                type: 'final',
            },
            redirectToDatesSelection: {
                entry: 'redirectToDatesSelection',
                type: 'final',
            },
        },
    },
    {
        actions: {
            initialiseContext,
            setSelectedProductGrouping,
            setOnlyProductOptionAsSelectedProductOption,
        },
        guards: {
            isValidProductGroup,
            isProductOptionSelectionRequired,
            isDestinationSelectionRequired,
        },
    },
);

export default purchaseEntryMachine;
