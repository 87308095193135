import LocalizedStrings from 'react-localization';
import ExternalLinks from '../../../../utils/constants/ExternalLinks';

const yourDetails = new LocalizedStrings({
    en: {
        heading: 'Your details',
        agreeTerms: `By continuing you agree to the <a href="${ExternalLinks.termsAndConditions}" target="_blank">Flip Terms of Use</a> and <a href="${ExternalLinks.privacyPolicy}" target="_blank">Privacy Policy</a>.`,
        cta: 'Continue',
        name: {
            firstName: 'First Name',
            lastName: 'Last Name',
        },
        email: 'Email address',
        dateOfBirth: {
            day: 'Day',
            month: 'Month',
            year: 'Year',
            errorInvalidDate: 'Sorry, the date doesn’t look right',
        },
        gender: {
            title: 'Select your gender',
            placeholder: 'Please select',
            options: [
                { value: 'female', label: 'Female' },
                { value: 'male', label: 'Male' },
                { value: 'non-binary', label: 'Non-binary' },
                { value: 'not-defined', label: 'I prefer not to say' },
            ],
        },
    },
});

export default yourDetails;
