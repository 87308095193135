import axios from 'axios';
import ProductGroupsResponse from '../../business-logic/models/ProductGroupsResponse';
import asService from '../utils/asService';
import isAxios404Error from '../utils/isAxios404Error';

const baseApiPath = import.meta.env.VITE_BASE_CDN_PATH;

const getProductGroups = async (): Promise<ProductGroupsResponse> => {
    try {
        const { data } = await axios.get<ProductGroupsResponse>(`${baseApiPath}/json/productGroupConfig.json`);
        return data;
    } catch (error) {
        if (isAxios404Error(error)) {
            throw new Error(`Unable to retrieve data from Flip content delivery network.`);
        }
        throw new Error(`Error retrieving product grouping resource from Flip content delivery network`);
    }
};

export default asService(getProductGroups, false);
