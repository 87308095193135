import React, { ChangeEvent, FocusEvent, KeyboardEvent, forwardRef } from 'react';
import classNames from 'classnames';
import Regex from '../../utils/RegexCollection';
import InputType from '../../utils/constants/InputType';

import './TextField.scss';

export enum TextFieldStyle {
    CUPERTINO = 'cupertino',
    MATERIAL = 'material',
}

export interface TextFieldProps {
    id?: string;
    name: string;
    type?: InputType;
    label?: string | React.ReactNode;
    isError?: boolean;
    error?: string;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
    value?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
    minLength?: number;
    maxLength?: number;
    inputMode?: 'text' | 'numeric';
    form?: string;
    style?: TextFieldStyle;
    prefixIcon?: string;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
    (
        {
            id,
            name,
            label,
            placeholder,
            type,
            value,
            isError,
            error,
            disabled,
            onChange,
            onFocus,
            onBlur,
            onKeyDown,
            className,
            minLength,
            maxLength,
            inputMode,
            form,
            style = TextFieldStyle.CUPERTINO, // default style is border all around field
            prefixIcon,
        },
        ref,
    ) => {
        const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
            const input = e.target.value;

            if (type !== InputType.NUMBER) {
                onChange(e);
                return;
            }

            const allowedText = Regex.numbers;
            if (input === '' || allowedText.test(input)) {
                onChange(e);
            }
        };

        return (
            <div className={classNames('text-field', className)}>
                {label && (
                    <label className={classNames('text-field__label', disabled && 'disabled')} htmlFor={id}>
                        {label}
                    </label>
                )}
                <div
                    className={classNames(
                        'text-field__input',
                        style === TextFieldStyle.MATERIAL
                            ? 'text-field__input--material'
                            : 'text-field__input--cupertino',
                        disabled && 'text-field__input--disabled',
                        isError && 'text-field__input--error',
                        'text-field__input__wrapper',
                    )}
                >
                    {prefixIcon && <img src={prefixIcon} alt="" className="text-field__icon--prefix" />}
                    <input
                        id={id}
                        ref={ref}
                        name={name}
                        // dont use number type, we'll handle not
                        // allowing non-number on chnage instead
                        // to avoid the default number input actions
                        type={type === InputType.NUMBER ? InputType.TEXT : type}
                        inputMode={inputMode}
                        value={value}
                        placeholder={placeholder}
                        disabled={disabled}
                        onChange={handleOnChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        minLength={minLength}
                        maxLength={maxLength}
                        onKeyDown={onKeyDown}
                        form={form}
                    />
                </div>
                {isError && error && <span className="text-field__error">{error}</span>}
            </div>
        );
    },
);

export default TextField;
