import { Destination } from '../../../../business-logic/models/CoverConfig';
import { SelectStartingDestinationMachineContextTypes } from '../context/selectStartingDestinationMachineContext';

export const hasMultipleRegions = (ctx: SelectStartingDestinationMachineContextTypes): boolean => {
    const destination = ctx.availableDestinations.find(
        (item: Destination) => item.destination === ctx.startDestination,
    );
    return !!destination && destination.regions.length > 1;
};

export const areDestinationsChosen = (ctx: SelectStartingDestinationMachineContextTypes): boolean =>
    !!ctx.chosenDestinations.length;
