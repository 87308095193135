import LocalizedStrings from 'react-localization';

const activities = new LocalizedStrings({
    en: {
        heading: 'Activities',
        label: 'What activities will you participate in',
        signUp: "<strong>Help us get to know you better.</strong> Tell us which activities you plan to participate in. Don't worry, this doesn't limit your cover to the selected activity or affect the price of your insurance.<br/><br/>Select as many as you like.",
        purchase:
            "<strong>Help us get to know you better. What activity did you have in mind for this specific purchase?</strong> Don't worry, this doesn't limit your cover to the selected activity or affect the price of your insurance.",
        cta: 'Continue',
        tooShortFreeTextError: 'Please enter your activity to proceed.',
        tooLongFreeTextError: 'Your activity has exceeded 256 characters, please shorten your description.',
    },
});

export default activities;
