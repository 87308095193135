import get from 'lodash/get';
import { FC, ReactElement } from 'react';
import { LocalizedStrings } from 'react-localization';

import disclaimers from '../../content/common/disclaimers';
import errors from '../../content/common/errors';

export interface WithContentProps<T extends string> {
    content: Record<T, unknown>;
}

function withContent<T extends WithContentProps<U & V>, U extends string, V extends string>(
    WrappedComponent: FC<T>,
    contentMap: Record<U, string>,
    uiContent: LocalizedStrings<Record<V, unknown>>,
) {
    return (props: Omit<T, keyof WithContentProps<U & V>>): ReactElement => {
        const content = {
            disclaimers,
            errors,
            ui: uiContent,
        };

        return (
            <WrappedComponent
                {...(props as T)}
                content={(Object.entries(contentMap) as [U, string][]).reduce((acc, [key, path]) => {
                    acc[key] = get(content, path, '');
                    return acc;
                }, {} as Record<U | V, unknown>)}
            />
        );
    };
}

export default withContent;
