import classNames from 'classnames';

import { RadioCheckboxProps } from '../RadioCheckboxProps';

import './Checkbox.scss';

const Checkbox: React.FC<RadioCheckboxProps> = ({
    id,
    name,
    label,
    className,
    checked,
    form,
    onChange,
    onBlur,
    onFocus,
    disabled,
}) => (
    <label htmlFor={id} className={classNames('checkbox', disabled && 'checkbox--disabled', className)}>
        <input
            type="checkbox"
            id={id}
            name={name}
            checked={!!checked}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            className="checkbox__input"
            disabled={disabled}
            form={form}
        />
        <div className={classNames('checkbox__decorated-checkbox', { checked })} />
        {label}
    </label>
);

export default Checkbox;
