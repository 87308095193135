import { datadogRum } from '@datadog/browser-rum';
import DependantsResponse from '../../business-logic/models/DependantsResponse';
import UserDetails from '../../business-logic/models/UserDetails';
import asService, { ServiceArgs } from '../utils/asService';
import isAxios409Error from '../utils/isAxios409Error';
import toHeaders from '../utils/toHeaders';
import withRetriesAsync from '../utils/withRetriesAsync';
import transformDependantsResponse from './transformDependantsResponse';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const getDependants = async ({
    accessToken,
    axios,
}: {
    accessToken: string;
} & ServiceArgs): Promise<{ dependants: UserDetails[] }> => {
    const { data } = await withRetriesAsync(
        () => axios.get<DependantsResponse>(`${baseApiPath}/api/v1/person/relation/dependant`, toHeaders(accessToken)),
        undefined,
        (error) => {
            // If 409, log error and retry fetch
            if (isAxios409Error(error)) {
                datadogRum.addError(error, {
                    serviceName: 'getDependants',
                    description: 'conflict in dependants get call',
                });
                return true;
            }
            return false;
        },
    );

    return transformDependantsResponse(data);
};

export default asService(getDependants);
