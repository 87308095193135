import { isSameDay, parse } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import DateFormat from './constants/DateFormat';

const getStartingTime = (coverStartTime: string, userTimeZone: string) => {
    let scheduledStartTime: string | null = null;

    const parsedUTCDate = zonedTimeToUtc(parse(coverStartTime, DateFormat.DEFAULT, new Date()), userTimeZone);

    // If same day leave start time as null ie start immediately, otherwise set time to 12 midnight
    if (!isSameDay(utcToZonedTime(new Date(), userTimeZone), utcToZonedTime(parsedUTCDate, userTimeZone))) {
        scheduledStartTime = parsedUTCDate.toISOString();
    }
    return scheduledStartTime;
};

export default (coverStartTime: string, userTimeZone: string): string | null => {
    return getStartingTime(coverStartTime, userTimeZone);
};
