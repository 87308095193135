import { FC } from 'react';
import SlideInDownTransition from '../../../transitions/slide-in-down/SlideInDownTransition';

import './BaseBanner.scss';

export interface BaseBannerProps {
    text?: string | string[];
    variant?: 'success' | 'error' | 'info' | 'alert';
}

/**
 * Base component for banner, to be used in Layout. This component also caters for async loading of content.
 * In async scenarios leave the text prop undefined until content is ready to be displayed. The banner
 * will then load with a slide in animation
 *
 * @param param0
 * @returns
 */
const BaseBanner: FC<BaseBannerProps> = ({ text, variant }) => {
    return (
        <div className={`base-banner base-banner--${variant}`}>
            <SlideInDownTransition in={!!text}>
                <p className="base-banner__text">
                    <strong>{text}</strong>
                </p>
            </SlideInDownTransition>
        </div>
    );
};

BaseBanner.defaultProps = {
    variant: 'success',
};

export default BaseBanner;
