import UserPreferences from '../business-logic/models/UserPreferences';

export type AuthenticationMethod = 'email' | 'apple' | 'google';

export type InvoiceType = 'discounted' | 'full price' | 'free';

export interface AnalyticsUserTraits {
    username: string; // email
    first_name: string;
    last_name: string;
    birth_date?: string;
    gender?: string;
    email: string;
    createdAt: string; // signup date, use ISO date string
    comm_preferences?: UserPreferences;
    authenticationMethod?: AuthenticationMethod;
}

export enum Category {
    ONBOARDING = 'Onboarding',
    CHECKOUT = 'Checkout',
    CANCELLATION = 'Cancellation',
    CLAIM_PROCESS = 'Claim Process',
    ACCOUNT = 'Account',
    AUTHENTICATION = 'Authentication',
    DASHBOARD = 'Dashboard',
    REFERRAL = 'Referral',
    SIGNUP = 'Sign up',
}

export type PageEvent = [Category, string];
