import History from 'history';
import StorageKeys from './constants/StorageKeys';
import getPartnerEventSignUpCode from './getPartnerEventSignUpCode';

const storePartnerEventSignUpCode = (location: History.Location): void => {
    const signUpCode = getPartnerEventSignUpCode(location);

    if (typeof signUpCode === 'string') {
        sessionStorage.setItem(StorageKeys.PARTNER_EVENT_SIGN_UP_CODE, signUpCode);
    }
};

export default storePartnerEventSignUpCode;
