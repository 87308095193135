import classNames from 'classnames';
import { ReactNode } from 'react';

import './Fieldset.scss';

export interface FieldsetProps {
    legend: string | ReactNode;
    visuallyHideLegend?: boolean;
    className?: string;
    form?: string;
}

const Fieldset: React.FC<FieldsetProps> = ({ legend, visuallyHideLegend, className, children, form }) => (
    <fieldset className={classNames('fieldset', className)} form={form}>
        <legend
            className={classNames('fieldset__legend', {
                'fieldset__legend--hide': visuallyHideLegend,
            })}
        >
            {legend}
        </legend>
        {children}
    </fieldset>
);

export default Fieldset;
