const landingPageBaseUrl = 'https://www.getflip.com.au';
const webflowBaseUrl = 'https://getflip.webflow.io';
const pdsDocument = 'https://www.getflip.com.au/product-disclosure-statement';
const tmdDocument = 'https://www.getflip.com.au/tmd';

const ExternalLinks = {
    landingPage: landingPageBaseUrl,
    privacyPolicy: `${landingPageBaseUrl}/privacy`,
    products: `${landingPageBaseUrl}/products`,
    termsAndConditions: `${landingPageBaseUrl}/terms`,
    referralTermsAndConditions: `${landingPageBaseUrl}/referral-terms`,
    directDebitAgreement: `${landingPageBaseUrl}/debit`,
    whatsCovered: `${landingPageBaseUrl}/whats-covered`,
    productDisclosureStatement: pdsDocument,
    targetMarketDetermination: tmdDocument,
    frequentlyAskedQuestions: `${landingPageBaseUrl}/faq`,
    claimsExplainerVideo: 'https://youtu.be/r8qO-5PxfDY',
    stripePrivacy: 'https://stripe.com/en-au/privacy',
    stripeTerms: 'https://stripe.com/en-au/checkout/legal',
    referralLinkBase: `${landingPageBaseUrl}/referral`,
    webflowReferralLinkBase: `${webflowBaseUrl}/referral`,
    mobileAppAppleAppStore: 'https://apps.apple.com/app/flip-insurance/id1623123867',
    mobileAppGooglePlayStore: 'https://play.google.com/store/apps/details?id=au.com.getflip.insurance',
    productPage: `${landingPageBaseUrl}/accidental-injury-insurance`,
};

export default ExternalLinks;
