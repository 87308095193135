import { FC, useState } from 'react';

import classNames from 'classnames';
import close from '../../assets/images/close.svg';
import check from '../../assets/images/deep-green-check.svg';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import { CoverInfoV3 } from '../../content/coverTermsTypes';
import useCoverInformation from '../../hooks/useCoverInformation';
import useCoverTerms from '../../hooks/useCoverTerms';
import common from '../../strings/common';
import CoverCode from '../../utils/constants/CoverCode';
import parseString from '../../utils/parseString';
import stringToReactKey from '../../utils/stringToReactKey';
import Alert, { AlertTypes } from '../alert/Alert';
import Button from '../button/Button';
import { ListItemProps } from '../list-item/ListItem';
import List from '../list/List';
import Modal from '../modal/Modal';

import './SlideInCoverTerms.scss';

interface SlideInCoverTermsProps {
    coverCode: CoverCode;
    pdsVersion: string;
    isOpen: boolean;
    showGroupPrice?: boolean;
    onClose: () => void;
    onOpen: () => void;
    hidePriceChip?: boolean;
    canCancel?: boolean;
    initiateCancellation?: () => void;
}

const SlideInCoverTerms: FC<SlideInCoverTermsProps> = ({
    coverCode,
    pdsVersion,
    isOpen,
    showGroupPrice = false,
    onOpen,
    onClose,
    hidePriceChip = false,
    canCancel,
    initiateCancellation,
}) => {
    const [multiContentTarget, setMultiContentTarget] = useState<keyof CoverInfoV3>('mainMenu');
    const { coverCategories } = useProduct();
    const coverInformation = useCoverInformation(coverCode, pdsVersion);
    const selectedCoverCategory = coverCategories.find((x) => x.coverCodes.includes(coverCode));
    const newCoverTerms = useCoverTerms(coverCode, pdsVersion)?.coverTermsSections;
    const mainMenuLinks = newCoverTerms?.map((item) => {
        return { target: item.id, label: item.label };
    });
    const closeModal = () => {
        onClose();
        setMultiContentTarget('mainMenu'); // reset
    };

    const switchSubMenu = (id: string) => {
        onClose();
        setTimeout(() => {
            setMultiContentTarget(id as keyof CoverInfoV3);
            onOpen();
        }, 600);
    };

    const renderTerms = () => {
        const newSelectedContent = newCoverTerms?.find((x) => x.id === multiContentTarget)?.content;
        return newSelectedContent?.map(({ contentBlockType, content }) => {
            switch (contentBlockType) {
                case 'title':
                    return (
                        <h1 className="slide-in-coverterms__title" key={content}>
                            {content}
                        </h1>
                    );
                case 'text':
                    return (
                        <div className="slide-in-coverterms__text" key={content}>
                            {parseString(content)}
                        </div>
                    );
                case 'steps':
                    return (
                        <div key={content.join('')}>
                            <div className="slide-in-coverterms__howitworks">
                                {content.map((step: string, stepIndex: number) => (
                                    <div key={step} className="slide-in-coverterms__howitworks__step">
                                        <div className="slide-in-coverterms__howitworks__step__number">
                                            {stepIndex + 1}
                                        </div>
                                        <span className="slide-in-coverterms__howitworks__step__content">
                                            {parseString(step || '')}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                case 'payoutCategories':
                    return (
                        <div key={content.toString()} className="slide-in-coverterms__payout-categories">
                            {content.map(({ categoryName, payouts }) => (
                                <div className="slide-in-coverterms__payout-category" key={categoryName}>
                                    {categoryName ? (
                                        <p className="slide-in-coverterms__payout-category-name">{categoryName}</p>
                                    ) : null}
                                    {payouts.map((payout: { injurySeverity: string; payoutAmount: string }) => (
                                        <div className="slide-in-coverterms__payout" key={payout.injurySeverity}>
                                            <div className="slide-in-coverterms__payout-severity-wrapper">
                                                <img
                                                    src={check}
                                                    alt=""
                                                    className="slide-in-coverterms__payout-severity-icon"
                                                />
                                                <span>{payout.injurySeverity}</span>
                                            </div>
                                            <span>{payout.payoutAmount}</span>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    );
                case 'iconList':
                    return (
                        <div key={content.toString()}>
                            <div className="slide-in-coverterms__additional-notes">
                                {content.map((iconItem) => (
                                    <div
                                        key={iconItem.toString()}
                                        className="slide-in-coverterms__additional-note__item"
                                    >
                                        <img
                                            src={iconItem.icon}
                                            alt=""
                                            className="slide-in-coverterms__additional-note__icon"
                                        />
                                        <span>{parseString(iconItem.content)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                case 'disclosureList': {
                    const exclusionsList: ListItemProps[] = content.map((exclusion) => ({
                        id: stringToReactKey(exclusion.summary),
                        icon: exclusion.icon,
                        data: exclusion.summary,
                        dataExpanded: (
                            <span className="slide-in-coverterms__exclusion--data-expanded">{exclusion.content}</span>
                        ),
                    }));
                    return <List items={exclusionsList} className="slide-in-coverterms__exclusions" />;
                }
                case 'navigateToCoverTermsSection':
                    return (
                        <div key={content.label}>
                            <Button
                                variant="drawer-link"
                                className="slide-in-cover-terms__trigger-button"
                                label={content.label}
                                onClick={() => switchSubMenu(content.toCoverTermsSectionId)}
                                width="full"
                            />
                        </div>
                    );
                case 'info':
                    return (
                        <Alert
                            key={content.toString()}
                            className="slide-in-coverterms__alert"
                            message={<span>{parseString(content)}</span>}
                            type={AlertTypes.INFO}
                        />
                    );
                default:
                    return '';
            }
        });
    };

    return (
        <>
            <Modal isOpen={isOpen} parentClass="#root" onClose={onClose} variant="slide-in" className="no-scroll">
                <div className="slide-in-coverterms__modal-close-button-wrapper">
                    <button
                        type="button"
                        className="slide-in-coverterms__modal-close-button"
                        aria-label="Close"
                        onClick={closeModal}
                    >
                        <img src={close} alt="Close" />
                    </button>
                    {multiContentTarget !== 'mainMenu' && (
                        <Button
                            type="button"
                            variant="link"
                            width="standard"
                            className="slide-in-coverterms__modal__menu-button"
                            label={`${selectedCoverCategory?.name} Menu`}
                            onClick={() => setMultiContentTarget('mainMenu')}
                        />
                    )}
                </div>
                <div
                    className={classNames(
                        'slide-in-coverterms',
                        !!multiContentTarget && multiContentTarget !== 'mainMenu' && 'active',
                    )}
                >
                    <div className="slide-in-coverterms__menu">
                        {!hidePriceChip && (
                            <div className="slide-in-coverterms__main__pricing">
                                <span>
                                    {parseString(
                                        (showGroupPrice
                                            ? coverInformation?.groupPrice
                                            : `${coverInformation?.price} per ${coverInformation?.priceTimePeriod}`) ||
                                            '',
                                    )}
                                </span>
                            </div>
                        )}
                        <h1>{selectedCoverCategory?.name}</h1>
                        <p>{parseString(selectedCoverCategory?.description ?? '')}</p>
                        {mainMenuLinks?.map((link: { target: string; label: string }) => (
                            <div key={link.target}>
                                <Button
                                    key={link.target}
                                    type="button"
                                    variant="drawer-link"
                                    width="full"
                                    label={link.label}
                                    className="slide-in-coverterms__main__button"
                                    onClick={() => setMultiContentTarget(link.target as keyof CoverInfoV3)}
                                />
                                <div className="slide-in-coverterms__main__button__divider" />
                            </div>
                        ))}
                        {canCancel && (
                            <Button
                                type="button"
                                width="full"
                                variant="secondary"
                                className="slide-in-coverterms__cancel"
                                label="Cancel Cover"
                                onClick={() => {
                                    closeModal();
                                    setTimeout(() => {}, 400); // wait until benefit drawer closes
                                    initiateCancellation!();
                                }}
                            />
                        )}
                    </div>
                    <div className="slide-in-coverterms__step">
                        {renderTerms()}
                        <span className="slide-in-coverterms__pds">
                            {coverInformation?.name} {common.formatString(common.pdsVersion, { pdsVersion })}
                        </span>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SlideInCoverTerms;
