import classNames from 'classnames';
import React from 'react';
import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import AnnouncementKeys from '../announcements/AnnouncementKeys';
import { Layout } from '../layout/Layout';

import './Dashboard.scss';

export interface DashboardV2Props {
    children?: React.ReactNode;
    title: string;
    centerTitle?: boolean;
    className?: string;
    loading?: boolean;
}

export const DashboardV2: React.FC<DashboardV2Props> = ({
    children,
    className,
    title,
    loading,
    centerTitle = false,
}) => {
    const { loading: userLoading } = useUser();
    const { openCoversLoading: coverLoading } = useOpenCovers();

    const isLoading = userLoading || coverLoading || loading;

    return (
        <div className="dashboardV2">
            <Layout
                className={classNames('dashboard__layout', 'dashboard__layout--v2', className)}
                nav
                bgPattern
                showLoading={isLoading}
                showAnnouncement={AnnouncementKeys.MOBILE_APP_LAUNCH}
                extraWide
            >
                {!isLoading && (
                    <>
                        <h1 className={classNames('dashboardV2__title', centerTitle && 'dashboardV2__title--centered')}>
                            {title}
                        </h1>
                        <div className="dashboardV2__main">{children}</div>
                    </>
                )}
            </Layout>
        </div>
    );
};

export default DashboardV2;
