enum CartStep {
    PROFILE,
    ACTIVITIES,
    WHOS_COVERED,
    REVIEW_COVER,
    PAYMENT,
}

export const CartStepsWithoutKids = [CartStep.PROFILE, CartStep.ACTIVITIES, CartStep.REVIEW_COVER, CartStep.PAYMENT];

export const CartStepsWithKids = [
    CartStep.PROFILE,
    CartStep.ACTIVITIES,
    CartStep.WHOS_COVERED,
    CartStep.REVIEW_COVER,
    CartStep.PAYMENT,
];

export default CartStep;
