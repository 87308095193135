import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Analytics from '../../analytics/Analytics';
import ActivitiesSelection from '../../components/activities-selection/ActivitiesSelection';
import { Layout } from '../../components/layout/Layout';
import PartnerEventBanner from '../../components/layout/banners/partner-event-banner/PartnerEventBanner';
import activitiesContent from '../../content/ui/screens/activities/activities';
import requireFlags from '../../hoc/require-flags/requireFlags';
import withContent from '../../hoc/with-content/withContent';
import Routes from '../../utils/Routes';
import { SignUpStep, SignUpStepName } from '../../utils/constants/SignUpStep';

const contentMap = {
    heading: 'ui.heading',
    disclaimer: 'ui.disclaimer',
    firstName: 'ui.firstName',
    lastName: 'ui.lastName',
    seePrices: 'ui.seePrices',
};
interface ActivitiesProps {
    content: Record<keyof typeof contentMap, string>;
}

const Activities: React.FC<ActivitiesProps> = ({ content }) => {
    const history = useHistory();

    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;

    useEffect(() => {
        Analytics.trackSignUpStepViewed(SignUpStep.ACTIVITIES + 1, SignUpStepName[SignUpStep.ACTIVITIES]);
    }, []);

    return (
        <Layout
            title={content.heading}
            showBackButton={!isOnboardingFlow}
            description={content.disclaimer}
            banner={<PartnerEventBanner />}
        >
            <ActivitiesSelection
                updateProfile
                onActivitiesComplete={() => {
                    Analytics.trackSignUpStepCompleted(
                        SignUpStep.ACTIVITIES + 1,
                        SignUpStepName[SignUpStep.ACTIVITIES],
                    );
                    history.push({
                        pathname: Routes.HOME,
                        state: { isOnboarding: isOnboardingFlow },
                    });
                }}
            />
        </Layout>
    );
};

export default requireFlags(withContent(Activities, contentMap, activitiesContent));
