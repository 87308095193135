import { datadogRum } from '@datadog/browser-rum';
import { LDClient } from 'launchdarkly-js-client-sdk';

/**
 * Returns a promise that'll resolve when LD is initialised.
 * Also handles the case when LD initialisation errors out
 * and reports back to us through Datadog.
 *
 * @param ldClient the LD client - get from useLDClient
 * @returns Promise<void>
 */
const ldWaitForInitialization = (ldClient: LDClient): Promise<void> =>
    new Promise<void>((resolve) =>
        ldClient
            .waitForInitialization()
            .then(() => resolve())
            .catch((e: any) => {
                // If LD fails to initialise don't block reject.
                // All flag evaluations will default to false.
                // Then trigger a report to Slack through datadog so we know LD is down.
                datadogRum.addError(typeof e === 'object' ? e : new Error(e), {
                    message: 'launchdarkly failed to initialise',
                });
                resolve();
            }),
    );

export default ldWaitForInitialization;
