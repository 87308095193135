import { PaymentStatus, PolicyItem } from '../../../business-logic/models/PoliciesResponse';
import CoverPaymentStatus from '../../../utils/constants/CoverPaymentStatus';

export function getPaymentStatus(policy: PolicyItem): CoverPaymentStatus {
    if (policy.mainCover !== null) {
        if (
            policy.mainCover.paymentStatus !== PaymentStatus.SUCCEEDED &&
            policy.mainCover.paymentAttempts !== null &&
            policy.mainCover.paymentAttempts! > 1
        ) {
            return CoverPaymentStatus.FAILED;
        }
    }
    return CoverPaymentStatus.PAID;
}

export default getPaymentStatus;
