import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import chevron from '../../assets/images/chevron-right-harbour.svg';
import chevronDown from '../../assets/images/ic-chevron-down.svg';
import chevronUp from '../../assets/images/ic-chevron-up.svg';
import './ListItem.scss';

export interface ListItemProps {
    id?: string;
    header?: string;
    icon?: string;
    data?: string | ReactNode;
    customDataElement?: React.ReactElement;
    rightIcon?: string;
    onClick?: () => void;
    dataExpanded?: React.ReactNode;
    className?: string;
}

export const ListItem: React.FC<ListItemProps> = ({
    id,
    icon,
    header,
    data,
    customDataElement,
    rightIcon = chevron,
    dataExpanded,
    onClick,
    className,
}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleExpand = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <li
            id={id}
            className={classNames('list-item', { clickable: onClick || dataExpanded }, className)}
            onClick={dataExpanded ? handleExpand : onClick}
            onKeyDown={onClick}
            role="menuitem"
        >
            {header && <p className="list-item__header">{header}</p>}
            <div className="list-item__content">
                {data && (
                    <div className="list-item__content--data">
                        {icon && <img src={icon} alt="" className="list-item__content--icon" />}
                        <p className="list-item__content-data">{data}</p>
                    </div>
                )}
                {customDataElement && customDataElement}
                {onClick && <img src={rightIcon} alt="enter" className="list-item__content-chevron" />}
                {dataExpanded && (
                    <img
                        src={isCollapsed ? chevronDown : chevronUp}
                        alt="expand"
                        className="list-item__content-chevron"
                    />
                )}
            </div>
            {!isCollapsed && <div className="list-item__content--expanded">{dataExpanded}</div>}
        </li>
    );
};

export default ListItem;
