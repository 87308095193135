import LocalizedStrings from 'react-localization';

const manageCoverContent = new LocalizedStrings({
    en: {
        heading: 'Manage cover',
        scheduledCoverLabel: 'Scheduled Cover',
        coverHistoryLabel: 'Cover History',
        unableToLoadCovers: "Sorry, we're unable to retrieve your covers. Please retry again later or contact us.",
    },
});

export default manageCoverContent;
