import { useParams } from 'react-router';

interface PathParams {
    selectedProductGroup?: string;
}

interface IUsePathParams {
    selectedProductGroup?: string;
    setSelectedProductGroupParam: (route: string, selectedProductGroupValue: string) => string;
}

const usePathParams = (): IUsePathParams => {
    const { selectedProductGroup } = useParams<PathParams>();

    const setSelectedProductGroupParam = (route: string, selectedProductGroupValue: string): string =>
        route.replace(':selectedProductGroup', selectedProductGroupValue);

    return { selectedProductGroup, setSelectedProductGroupParam };
};

export default usePathParams;
