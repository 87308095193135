import { CartError, CartErrorDisplay } from '../../../business-logic/models/Cart';
import { cartErrorCodesToActions } from '../constants/CartErrorMessages';

const isCartError = (value: any) => {
    return (
        !!value.message &&
        !!value.errorCode &&
        (value as CartError).message !== undefined &&
        (value as CartError).errorCode !== undefined
    );
};

async function withCartErrorHandling<T>(serviceResponse: Promise<T>): Promise<T> {
    const response = await serviceResponse;

    if (isCartError(response) || !!response === false) {
        const cartError = cartErrorCodesToActions.find(
            (item: CartErrorDisplay) =>
                item.errorCode === (response as CartError).errorCode || item.errorCode === 'CATCH_ALL',
        );
        throw new Error('Throwing Cart Error', { cause: cartError });
    }
    return response;
}

export default withCartErrorHandling;
