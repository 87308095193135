import differenceInYears from 'date-fns/differenceInYears';
import { KidDetailsFormMachineContext } from '../context/kidDetailsFormMachineContext';
import isValidDate from '../../../../validation/isValidDate';
import getDate from '../../../../validation/getDate';

export const isFirstNameValid = (ctx: KidDetailsFormMachineContext): boolean =>
    ctx.firstName.length >= ctx.config.nameMinLength;

export const isLastNameValid = (ctx: KidDetailsFormMachineContext): boolean =>
    ctx.lastName.length >= ctx.config.nameMinLength;

export const isDateFilledIn = (ctx: KidDetailsFormMachineContext): boolean =>
    ctx.dob.day.length > 0 && ctx.dob.month.length > 0 && ctx.dob.year.length === 4;

export const isValidDateFormat = (ctx: KidDetailsFormMachineContext): boolean =>
    ctx.dob.year.length === 4 && isValidDate(ctx.dob.day, ctx.dob.month, ctx.dob.year);

export const isBelowMinAge = (ctx: KidDetailsFormMachineContext): boolean =>
    differenceInYears(new Date(), getDate(ctx.dob.day, ctx.dob.month, ctx.dob.year)) < ctx.config.minAge;

export const isAboveMaxAge = (ctx: KidDetailsFormMachineContext): boolean =>
    differenceInYears(new Date(), getDate(ctx.dob.day, ctx.dob.month, ctx.dob.year)) > ctx.config.maxAge;

export const isGenderSelected = (ctx: KidDetailsFormMachineContext): boolean => {
    return ctx.gender !== null;
};
