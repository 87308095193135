import { Children, cloneElement, FC, isValidElement, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './FadeInUpTransitionGroup.scss';

/**
 * Any children provided to this component will have a "fade in up" transition
 * on mount, and a matching "fade out down" transition on unmount. Transition time of 500ms
 *
 * @param param0
 * @returns
 */
const FadeInUpTransitionGroup: FC = ({ children }) => {
    const elRefs = useRef([]);
    return (
        <TransitionGroup>
            {Children.map(children, (child, index) => (
                <CSSTransition
                    nodeRef={elRefs.current[index]}
                    key={isValidElement(child) ? child.key : undefined}
                    timeout={500}
                    classNames="fade-in-up"
                >
                    {isValidElement(child) ? cloneElement(child) : child}
                </CSSTransition>
            ))}
        </TransitionGroup>
    );
};

export default FadeInUpTransitionGroup;
