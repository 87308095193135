import CustomerService from '../../../../../../services/customer-service/CustomerService';
import ResidencyStatusTypes from '../../../../../../utils/constants/ResidencyStatusTypes';
import formatDateToString from '../../../../../../utils/formatDateToString';
import { ProfileMachineContext } from '../context/profileMachineContext';

export type ProfileMachineServices = {
    updateCustomerProfile: {
        data: void;
    };
};

export const updateCustomerProfile = async (ctx: ProfileMachineContext): Promise<void> => {
    await CustomerService.updateCustomerProfile({
        accessToken: ctx.accessToken,
        profile: {
            firstName: ctx.firstName,
            lastName: ctx.lastName,
            dateOfBirth: formatDateToString(new Date(`${ctx.dob.year}/${ctx.dob.month}/${ctx.dob.day}`)),
            state: ctx.state,
            residencyStatusType: [ctx.residencyStatus as ResidencyStatusTypes],
            gender: ctx.gender,
        },
    });
};
