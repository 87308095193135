import systemBlock from '../../../assets/images/icon-block-yellow-40x40.svg';
import success from '../../../assets/images/icon-check-green-40x40.svg';
import error from '../../../assets/images/icon-danger-red-40x40.svg';
import info from '../../../assets/images/icon-info-blue-40x40.svg';
import alert from '../../../assets/images/icon-warning-red-40x40.svg';

export enum ModalAlert {
    ALERT = 'ALERT',
    INFO = 'INFO',
    ERROR = 'ERROR',
    SYSTEM = 'SYSTEM',
    SUCCESS = 'SUCCESS',
}

export type ModalWithCTAAlertType = {
    [key in ModalAlert]: string;
};

export const ModalWithCTAAlert: ModalWithCTAAlertType = {
    ALERT: alert,
    INFO: info,
    ERROR: error,
    SYSTEM: systemBlock,
    SUCCESS: success,
};
