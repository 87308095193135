import { FC, MouseEventHandler } from 'react';

import LoadingButton, { LoadingButtonProps } from '../../../loading-button/LoadingButton';
import paymentStrings from '../../../../strings/payments';
import formatPrice from '../../../../utils/formatPrice';
import common from '../../../../strings/common';

import './BasePaymentButton.scss';

interface BasePaymentButtonProps {
    amountDue: number;
    checkoutStatus: LoadingButtonProps['status'];
    disabled: boolean;
    onClick: MouseEventHandler<HTMLButtonElement>;
}

const BasePaymentButton: FC<BasePaymentButtonProps> = ({ amountDue, checkoutStatus, disabled, onClick }) => (
    <LoadingButton
        status={checkoutStatus}
        label={
            amountDue > 0
                ? paymentStrings.formatString(paymentStrings.payButtonLabel, {
                      totalDue: formatPrice(amountDue),
                  })
                : common.confirm
        }
        width="full"
        onClick={onClick}
        className="base-payment-button"
        disabled={disabled}
        type="submit"
    />
);

export default BasePaymentButton;
