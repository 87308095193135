import LocalizedStrings from 'react-localization';

const cartSuccessContent = new LocalizedStrings({
    en: {
        heading: 'Thank you {firstName}!',
        description:
            'Your Flip cover is purchased.\nCheck your inbox for your confirmation email and Certificate of Insurance.',
        accountCreation:
            '<p>To manage your cover go to your dashboard. To make a claim, you can do so by downloading the mobile App.</p>',
        accessAccountCTA: ' Go to dashboard',
        downloadTheAppCta: 'Download the app',
        productsCta: 'Continue browsing',
        appStoreActionText: 'Download on the',
        appStoreStoreName: 'App Store',
        playStoreActionText: 'Get it on',
        playStoreStoreName: 'Google Play',
    },
});

export default cartSuccessContent;
