import { useActor } from '@xstate/react';
import { FC } from 'react';
import { ActorRefFrom } from 'xstate';
import kidDetailsFormContent from '../../content/ui/components/kid-details-form/kidDetailsForm';
import withContent from '../../hoc/with-content/withContent';
import onboardingFlow from '../../strings/onboardingFlow';
import Limits from '../../utils/constants/Limits';
import DateOfBirthInput from '../date-of-birth-input/DateOfBirthInput';
import Dropdown from '../dropdown/Dropdown';
import TextField from '../text-field/TextField';
import kidDetailsFormMachine from './kid-details-form-machine/kidDetailsFormMachine';

const contentMap = {
    firstNameLabel: 'ui.firstName.label',
    lastNameLabel: 'ui.lastName.label',
    genderLabel: 'ui.gender.label',
    dropdownPlaceholder: 'ui.dropdownPlaceholder',
};

interface KidDetailsFormProps {
    content: Record<keyof typeof contentMap, string>;
    kidDetailsFormMachineRef: ActorRefFrom<typeof kidDetailsFormMachine>;
    form?: string;
}

const KidDetailsForm: FC<KidDetailsFormProps> = ({ content, kidDetailsFormMachineRef, form }) => {
    const [state, send] = useActor(kidDetailsFormMachineRef);

    const {
        gender: { options: genderOptions },
    } = onboardingFlow;

    const genderDropdownOptions = Object.values(genderOptions).map((g) => ({ value: g.id, label: g.label }));

    const hasDobError = state.matches('dob.displayDobError');

    return (
        <>
            <TextField
                id="firstName"
                name="firstName"
                className="profile-step-guest__input-row"
                label={content.firstNameLabel}
                value={state.context.firstName}
                minLength={Limits.NAME_MIN_LENGTH}
                maxLength={Limits.NAME_MAX_LENGTH}
                onChange={(e) => send({ type: 'UPDATE_FIRST_NAME', data: e.target.value })}
                form={form}
            />
            <TextField
                id="lastName"
                name="lastName"
                label={content.lastNameLabel}
                className="profile-step-guest__input-row"
                value={state.context.lastName}
                minLength={Limits.NAME_MIN_LENGTH}
                maxLength={Limits.NAME_MAX_LENGTH}
                onChange={(e) => send({ type: 'UPDATE_LAST_NAME', data: e.target.value })}
                form={form}
            />
            <div className="profile-step-guest__input-row">
                <DateOfBirthInput
                    day={state.context.dob.day}
                    month={state.context.dob.month}
                    year={state.context.dob.year}
                    hasError={hasDobError}
                    onDayChange={(e) => {
                        send({ type: 'UPDATE_DATE', data: e.target.value });
                    }}
                    onMonthChange={(e) => send({ type: 'UPDATE_MONTH', data: e.target.value })}
                    onYearChange={(e) => send({ type: 'UPDATE_YEAR', data: e.target.value })}
                    onBlur={() => send('BLUR_YEAR')}
                    form={form}
                />
                {hasDobError && <span className="profile-step-guest__dob-error">{state.context.dob.error}</span>}
            </div>
            <Dropdown
                options={genderDropdownOptions}
                label={content.genderLabel}
                placeholder={content.dropdownPlaceholder}
                value={genderDropdownOptions.find((g) => g.value === state.context.gender) || null}
                onChange={(selectedGender) => send({ type: 'UPDATE_GENDER', data: selectedGender.value })}
                searchable={false}
                className="profile-step-guest__input-row"
                form={form}
            />
        </>
    );
};

export default withContent(KidDetailsForm, contentMap, kidDetailsFormContent);
