import LocalizedStrings from 'react-localization';
import yellowAlert from '../../../../assets/images/alert-yellow.svg';
import redClose from '../../../../assets/images/close-red.svg';
import greenTick from '../../../../assets/images/deep-green-check.svg';
import ExternalLinks from '../../../../utils/constants/ExternalLinks';

const roamingProductModal = new LocalizedStrings({
    en: {
        slide1: [
            { title: 'Remember, Flip Roaming doesn&apos;t provide cover for:' },
            {
                iconList: {
                    icon: redClose,
                    list: [
                        'Illness',
                        'Travel related expenses',
                        'The cost of repatriation or transport back to Australia',
                        'Accidental injuries from motorcycling',
                        'More than A$20,000 for Overseas Medical Cash and A$50,000 for Extra Recovery Cash',
                    ],
                },
            },
            {
                description: '<b>You will be required to</b>',
            },
            {
                iconList: {
                    icon: yellowAlert,
                    list: ['Pay your overseas medical bills before claiming with Flip'],
                },
            },
        ],
        slide2: [
            { title: `If you are also buying \n travel insurance` },
            { description: 'Flip Roaming may be a valuable addition to travel insurance by providing:' },
            {
                iconList: {
                    icon: greenTick,
                    list: [
                        'Cover while you are doing a sport or activity that your travel insurance doesn&apos;t cover.',
                        'The Extra Recovery Cash benefit to help with your recovery back in Australia.',
                    ],
                },
            },
            {
                description: `However, it may also be that your travel insurance is adequate for your needs. Check your travel insurance policy details to see what it covers and read our <a href="${ExternalLinks.productDisclosureStatement}" target="_blank" rel="noopener noreferrer">PDS</a> to see if Flip Roaming is right for you.`,
            },
        ],
    },
});

export default roamingProductModal;
