import { useProduct } from '../business-logic/context-provider/ProductContext';
import { ProductResponseCoverSpec } from '../business-logic/models/ProductResponse';

/**
 * Returns product spec for a given cover code.
 * Only works for main cover for now.
 * Will return version available for purchase if found, otherwise null.
 *
 * @param coverCode Cover code to get product spec for
 *
 * @returns ProductResponseCoverSpec if spec exists, null if spec not found
 */
const useProductSpec = (coverCode: string): ProductResponseCoverSpec | null => {
    const { products } = useProduct();

    const matchingProduct = products.find((product) => product.productSpec.mainCoverType.coverCode === coverCode);

    // No corresponding product on sale
    if (matchingProduct === undefined) {
        return null;
    }

    return matchingProduct.productSpec.mainCoverType;
};

export default useProductSpec;
