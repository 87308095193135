import { createMachine } from 'xstate';
import Gender from '../../../utils/constants/Gender';
import {
    clearDobError,
    setDate,
    setFirstName,
    setGender,
    setInvalidDateFormatError,
    setLastName,
    setMaxAgeError,
    setMinAgeError,
    setMonth,
    setYear,
} from './actions/kidDetailsFormMachineActions';
import { KidDetailsFormMachineContext, initialContext } from './context/kidDetailsFormMachineContext';
import {
    isAboveMaxAge,
    isBelowMinAge,
    isDateFilledIn,
    isFirstNameValid,
    isGenderSelected,
    isLastNameValid,
    isValidDateFormat,
} from './guards/kidDetailsFormMachineGuards';

const kidDetailsFormMachine = createMachine(
    {
        id: 'KidDetailsFormMachine',
        type: 'parallel',
        tsTypes: {} as import('./kidDetailsFormMachine.typegen').Typegen0,
        schema: {
            context: {} as KidDetailsFormMachineContext,
            events: {} as
                | { type: 'UPDATE_FIRST_NAME'; data: string }
                | { type: 'UPDATE_LAST_NAME'; data: string }
                | { type: 'UPDATE_DATE'; data: string }
                | { type: 'UPDATE_MONTH'; data: string }
                | { type: 'UPDATE_YEAR'; data: string }
                | { type: 'BLUR_YEAR' }
                | { type: 'UPDATE_GENDER'; data: Gender },
        },
        context: initialContext,
        states: {
            firstName: {
                initial: 'validateFirstName',
                states: {
                    idle: {
                        tags: ['INVALID'],
                        on: {
                            UPDATE_FIRST_NAME: {
                                actions: 'setFirstName',
                                target: 'validateFirstName',
                            },
                        },
                    },
                    valid: {
                        on: {
                            UPDATE_FIRST_NAME: {
                                actions: 'setFirstName',
                                target: 'validateFirstName',
                            },
                        },
                    },
                    validateFirstName: {
                        tags: ['INVALID'],
                        always: [
                            {
                                cond: 'isFirstNameValid',
                                target: 'valid',
                            },
                            {
                                target: 'idle',
                            },
                        ],
                    },
                },
            },
            lastName: {
                initial: 'validateLastName',
                states: {
                    idle: {
                        tags: ['INVALID'],
                        on: {
                            UPDATE_LAST_NAME: {
                                actions: 'setLastName',
                                target: 'validateLastName',
                            },
                        },
                    },
                    validateLastName: {
                        tags: ['INVALID'],
                        always: [
                            {
                                cond: 'isLastNameValid',
                                target: 'valid',
                            },
                            {
                                target: 'idle',
                            },
                        ],
                    },
                    valid: {
                        on: {
                            UPDATE_LAST_NAME: {
                                actions: 'setLastName',
                                target: 'validateLastName',
                            },
                        },
                    },
                },
            },
            dob: {
                initial: 'validateIfDateFilledIn',
                states: {
                    idle: {
                        tags: ['INVALID'],
                        on: {
                            UPDATE_DATE: {
                                actions: 'setDate',
                                target: 'validateIfDateFilledIn',
                            },
                            UPDATE_MONTH: {
                                actions: 'setMonth',
                                target: 'validateIfDateFilledIn',
                            },
                            UPDATE_YEAR: {
                                actions: 'setYear',
                                target: 'validateIfDateFilledIn',
                            },
                            BLUR_YEAR: {
                                target: 'validateIfDateFilledIn',
                            },
                        },
                    },
                    validateIfDateFilledIn: {
                        tags: ['INVALID'],
                        always: [
                            {
                                cond: 'isDateFilledIn',
                                target: 'validateDateFormat',
                            },
                            {
                                target: 'idle',
                            },
                        ],
                    },
                    validateDateFormat: {
                        tags: ['INVALID'],
                        always: [
                            {
                                cond: 'isValidDateFormat',
                                target: 'validateAge',
                            },
                            {
                                actions: 'setInvalidDateFormatError',
                                target: 'displayDobError',
                            },
                        ],
                    },
                    displayDobError: {
                        tags: ['INVALID'],
                        exit: 'clearDobError',
                        on: {
                            UPDATE_DATE: {
                                actions: 'setDate',
                                target: 'validateIfDateFilledIn',
                            },
                            UPDATE_MONTH: {
                                actions: 'setMonth',
                                target: 'validateIfDateFilledIn',
                            },
                            UPDATE_YEAR: {
                                actions: 'setYear',
                                target: 'validateIfDateFilledIn',
                            },
                            BLUR_YEAR: {
                                target: 'validateIfDateFilledIn',
                            },
                        },
                    },
                    validateAge: {
                        tags: ['INVALID'],
                        always: [
                            {
                                actions: 'setMinAgeError',
                                cond: 'isBelowMinAge',
                                target: 'displayDobError',
                            },
                            {
                                actions: 'setMaxAgeError',
                                cond: 'isAboveMaxAge',
                                target: 'displayDobError',
                            },
                            {
                                target: 'valid',
                            },
                        ],
                    },
                    valid: {
                        on: {
                            UPDATE_DATE: {
                                actions: 'setDate',
                                target: 'validateIfDateFilledIn',
                            },
                            UPDATE_MONTH: {
                                actions: 'setMonth',
                                target: 'validateIfDateFilledIn',
                            },
                            UPDATE_YEAR: {
                                actions: 'setYear',
                                target: 'validateIfDateFilledIn',
                            },
                            BLUR_YEAR: {
                                target: 'validateIfDateFilledIn',
                            },
                        },
                    },
                },
            },
            gender: {
                initial: 'validateGender',
                states: {
                    validateGender: {
                        tags: ['INVALID'],
                        always: [
                            {
                                cond: 'isGenderSelected',
                                target: 'valid',
                            },
                            {
                                target: 'idle',
                            },
                        ],
                    },
                    valid: {
                        on: {
                            UPDATE_GENDER: {
                                actions: 'setGender',
                                target: 'validateGender',
                            },
                        },
                    },
                    idle: {
                        tags: ['INVALID'],
                        on: {
                            UPDATE_GENDER: {
                                actions: 'setGender',
                                target: 'validateGender',
                            },
                        },
                    },
                },
            },
        },
    },
    {
        guards: {
            isFirstNameValid,
            isLastNameValid,
            isDateFilledIn,
            isValidDateFormat,
            isGenderSelected,
            isAboveMaxAge,
            isBelowMinAge,
        },
        actions: {
            setFirstName,
            setLastName,
            setDate,
            setMonth,
            setYear,
            setMinAgeError,
            setMaxAgeError,
            setInvalidDateFormatError,
            setGender,
            clearDobError,
        },
    },
);

export default kidDetailsFormMachine;
