import { assign } from 'xstate';
import Analytics from '../../../../analytics/Analytics';
import { Destination, Region } from '../../../../business-logic/models/CoverConfig';
import { SelectStartingRegionMachineContextTypes } from '../context/selectStartingRegionMachineContext';
import { Typegen0 } from '../selectStartingRegionMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setChosenRegion = assign<
    SelectStartingRegionMachineContextTypes,
    { type: EventsCausingActions['setChosenRegion']; data: Region | null }
>({
    chosenRegion: (_, event) => event.data,
}) as any;

export const setAllRegions = assign<
    SelectStartingRegionMachineContextTypes,
    {
        type: EventsCausingActions['setAllRegions'];
        data: {
            fetchedDestinations: Destination[];
        };
    }
>({
    availableRegions: (ctx, event) =>
        event.data.fetchedDestinations.find((x: Destination) => x.destination === ctx.selectedDestination)?.regions ??
        [],
}) as any;

export const trackStartRegionSelected = (ctx: SelectStartingRegionMachineContextTypes) => {
    if (!ctx.chosenRegion) return;
    Analytics.trackStartRegionSelected(ctx.chosenRegion.region!);
};
