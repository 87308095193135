import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import logo from '../../../logo.svg';
import onBoardingPattern from '../../../assets/images/onboarding-pattern.svg';
import { Button } from '../../../components/button/Button';
import { Layout } from '../../../components/layout/Layout';
import Routes from '../../../utils/Routes';
import onBoardingStrings from '../../../strings/onboardingFlow';
import commonStrings from '../../../strings/common';
import Loading from '../../loading/Loading';
import './OnboardingStart.scss';

const OnboardingStart: React.FC = () => {
    const history = useHistory();
    const { authState } = useOktaAuth();

    const handleSignUpClick = () => {
        if (authState && authState.isAuthenticated) {
            history.push(Routes.HOME);
            return;
        }

        history.push(Routes.REGISTER);
    };

    const handleLogInClick = () => {
        history.push(Routes.LOGIN);
    };

    useEffect(() => {
        if (authState && authState.isAuthenticated) {
            history.push(Routes.HOME);
        }
    }, [authState]);

    return authState && authState.isAuthenticated ? (
        <Loading />
    ) : (
        <Layout isContentCentered={false} className="onboarding-start__layout" bgPattern={false} showPdsVersion={false}>
            <div className="onboarding-start__pattern-bg" />
            <img src={onBoardingPattern} className="onboarding-start__pattern" alt="pattern" />
            <img src={logo} className="onboarding-start__logo" alt="logo" />
            <div className="onboarding-start__container">
                <h1>{onBoardingStrings.onboardingStart.title}</h1>
                <div className="onboarding-start__container--btn-group">
                    <Button variant="primary" label={commonStrings.signup} width="full" onClick={handleSignUpClick} />
                    <Button variant="secondary" label={commonStrings.login} width="full" onClick={handleLogInClick} />
                </div>
            </div>
        </Layout>
    );
};

export default OnboardingStart;
