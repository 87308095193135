import LocalizedStrings from 'react-localization';

const disclaimers = new LocalizedStrings({
    en: {
        generalDisclaimer:
            "Flip advice is general in nature. We don't take into account your personal circumstances, so please consider how appropriate it is for you before taking action. Flip is issued by HCF Life Insurance Pty Ltd, AFSL 236 806. ",
        pdsTmdDisclaimer: 'For full details of your insurance refer to the {pdsFsg} and {tmd} docs.',
        pdsDisclaimer: 'Full details of your insurance including definitions are found in the {pdsFsg}.',
        pdsFsg: 'Product Disclosure Statement (PDS) and Financial Services Guide (FSG)',
        tmd: 'Target Market Determination (TMD)',
        gsf: 'Group Sport factsheet',
        benefitsPdsFsg: 'Product Disclosure Statement (PDS)',
        benefitsDisclaimer:
            'We do our best to capture the important points, but we recommend you {extra} and read the full details here in our {pdsFsg}',
        extraMinutes: 'take an extra few minutes',
    },
});

export default disclaimers;
