import { useOktaAuth } from '@okta/okta-react';
import { getUnixTime } from 'date-fns';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import CancelCoverService from '../../services/CancelCoverService';
import UserService from '../../services/UserService';
import PartnerEventService from '../../services/partner-event-service/PartnerEventService';
import withRetriesAsync from '../../services/utils/withRetriesAsync';
import StorageKeys from '../../utils/constants/StorageKeys';
import ldWaitForInitialization from '../../utils/third-party-dependencies/ldWaitForInitialization';
import CoverSelection from '../models/CoverSelection';
import { PartnerEvent, Policy } from '../models/open-covers-models/OpenCoverPolicies';
import { CoverItem } from '../models/PoliciesResponse';

const env = import.meta.env.VITE_NETLIFY_ENV;

// Interfaces
interface OpenCoversState {
    openCoversLoading: boolean;
    openCoversInitialised: boolean;
    policies: Policy[];
    openCoverSelections: CoverSelection[];
    partnerEvents: PartnerEvent[];
}

interface OpenCoverActions {
    setOpenCoversSelections: (openCoverSelections: CoverSelection[]) => void;
    clearOpenCoversSelections: () => void;
    refreshCoverAfterCancel: (coverId: string) => void;
    cancelSingleOpenCover: (coverId: string, cancelCustomerInsights: string[]) => Promise<void>;
    cancelSubscriptionOpenCover: (coverId: string, cancelCustomerInsights: string[]) => Promise<void>;
    fetchOpenCovers: () => Promise<void>;
}

const initialState: OpenCoversState = {
    openCoversLoading: false,
    openCoversInitialised: false,
    policies: [],
    openCoverSelections: [],
    partnerEvents: [],
};

export type IOpenCoversContext = OpenCoversState & OpenCoverActions;

export const OpenCoverContext = createContext<any>(initialState);

export const useOpenCovers = (): IOpenCoversContext => {
    const context: IOpenCoversContext = useContext(OpenCoverContext);
    if (typeof context === 'undefined') {
        throw new Error('Cover Context must be used within the OpenCoversProvider');
    }
    return context;
};

export type OpenCoversDispatchAction =
    | { type: 'SET_LOADING'; payload: boolean }
    | { type: 'SET_INITIALISED'; payload: boolean }
    | { type: 'SET_COVER_SELECTIONS'; payload: CoverSelection[] }
    | { type: 'CLEAR_COVER_SELECTIONS' }
    | { type: 'SET_COVER_POLICIES'; payload: Policy[] }
    | { type: 'SET_PARTNER_EVENTS'; payload: PartnerEvent[] };

const reducer = (state: OpenCoversState, action: OpenCoversDispatchAction): OpenCoversState => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, openCoversLoading: action.payload };
        case 'SET_INITIALISED':
            return { ...state, openCoversInitialised: action.payload };
        case 'SET_COVER_POLICIES':
            return { ...state, policies: action.payload };
        case 'SET_COVER_SELECTIONS':
            return { ...state, openCoverSelections: action.payload };
        case 'CLEAR_COVER_SELECTIONS':
            return { ...state, openCoverSelections: [] };
        case 'SET_PARTNER_EVENTS':
            return { ...state, partnerEvents: action.payload };
        default:
            throw new Error();
    }
};

export const OpenCoversProvider: React.FC = (props) => {
    const { authState } = useOktaAuth();
    const ldClient = useLDClient();

    const [state, dispatch] = useReducer(reducer, initialState);

    // ***************** Setters *****************

    const setOpenCoversLoading = (openCoversLoading: boolean) => {
        dispatch({ type: 'SET_LOADING', payload: openCoversLoading });
    };

    const setOpenCoversInitialised = (openCoversInitialised: boolean) => {
        dispatch({ type: 'SET_INITIALISED', payload: openCoversInitialised });
    };

    const setCoverPolicies = (policies: Policy[]) => {
        dispatch({ type: 'SET_COVER_POLICIES', payload: policies });
    };

    const setOpenCoversSelections = (openCoverSelections: CoverSelection[]) => {
        dispatch({ type: 'SET_COVER_SELECTIONS', payload: openCoverSelections });
    };

    const clearOpenCoversSelections = () => {
        dispatch({ type: 'CLEAR_COVER_SELECTIONS' });
    };

    const setPartnerEvents = (partnerEvents: PartnerEvent[]) => {
        dispatch({ type: 'SET_PARTNER_EVENTS', payload: partnerEvents });
    };

    // ***************** Fetch and set *****************

    const fetchAndSetV2UserPolicies = useCallback(async (accessToken: string) => {
        const { policies, partnerEventPolicies: partnerEvents } = await UserService.getUserAndPartnerEventPolicies(
            accessToken,
        );

        const toCover = (cover: CoverItem) => ({
            insuranceCoverId: cover.insuranceCoverId,
            coverTypeId: cover.coverTypeId,
            coverCode: cover.coverCode,
            status: cover.status,
            currentCycleStatus: cover.currentCycleStatus,
            currentCycleEndTime: cover.currentCycleEndTime ? getUnixTime(new Date(cover.currentCycleEndTime)) : null,
            nextCycleAt: cover.nextCycleAt ? getUnixTime(new Date(cover.nextCycleAt)) : null,
            coverTimezone: cover.coverTimezone,
            paymentFailed: !!cover.paymentFailedReason && cover.currentCycleStatus !== 'Paid',
            partnerEventId: cover.partnerEventId,
            insuredPersonId: cover.insuredPersonId,
            destination: cover.destination !== null ? cover.destination : undefined,
            activeFrom: getUnixTime(new Date(cover.startTime)),
            activeTo: getUnixTime(new Date(cover.currentCycleEndTime || cover.endTime)),
        });

        const parsedPolicies = policies
            .map((policy) => {
                return {
                    insurancePolicyId: policy.insurancePolicyId,
                    policyNumber: policy.policyNumber,
                    identityId: policy.identityId,
                    customerNumber: policy.customerNumber,
                    productId: policy.productId,
                    PDSVersion: policy.PDSVersion,
                    status: policy.status,
                    policyTimezone: policy.policyTimezone,
                    mainCover: toCover(policy.mainCover),
                    extraCovers: policy.extraCovers.map(toCover),
                };
            })
            .sort((a, b) => a.mainCover.activeFrom - b.mainCover.activeFrom);

        const parsedPartnerEvents = partnerEvents
            ?.map((event) => {
                return {
                    ...event,
                    partnerEvent: {
                        partnerEventId: event.partnerEvent.partnerEventId,
                        eventName: event.partnerEvent.eventName,
                        activeFrom: getUnixTime(new Date(event.partnerEvent.startTime)),
                        activeTo: getUnixTime(new Date(event.partnerEvent.endTime)),
                    },
                };
            })
            .sort((a, b) => a.partnerEvent.activeFrom - b.partnerEvent.activeFrom);

        setCoverPolicies(parsedPolicies);
        setPartnerEvents(parsedPartnerEvents ?? []);

        return {
            policies,
            partnerEvents,
        };
    }, []);

    // ***************** Refreshers *****************

    const refreshCoverAfterCancel = async (coverId: string) => {
        try {
            const accessToken = authState?.accessToken?.accessToken;
            if (typeof accessToken === 'undefined') {
                throw new Error('refreshCoverAfterCancel: Access token required');
            }
            await withRetriesAsync(
                () => fetchAndSetV2UserPolicies(accessToken),
                (data) =>
                    data !== undefined &&
                    data !== null &&
                    !!data.policies.find((policy) => policy.mainCover.insuranceCoverId === coverId),
            );
        } catch (e) {
            // TODO - handle error
            console.error(e);
        }
    };

    // ***************** Custom handler for V2 *****************

    /**
     * Handle cancelling of one instance of single cover from dashboard, and refreshes the user policies.
     * Will retry call to BE to get updated policies if cancelled cover is returned in bff-policy call
     * (this can happen if the BE events haven't caught up with the cancellation by the time we make
     * the call to get an updated list of active policies)
     *
     */
    const cancelSingleOpenCover = async (coverId: string, cancelCustomerInsights: string[] = []) => {
        try {
            const accessToken = authState?.accessToken?.accessToken;
            if (typeof accessToken === 'undefined') throw new Error('cancelSingleOpenCover: Access token required');

            await CancelCoverService.cancelSingleCover(accessToken, coverId, cancelCustomerInsights);

            await refreshCoverAfterCancel(coverId);
        } catch (e) {
            // TODO - handle error
            console.error(e);
        }
    };

    const cancelSubscriptionOpenCover = async (coverId: string, cancelCustomerInsights: string[] = []) => {
        try {
            const accessToken = authState?.accessToken?.accessToken;
            if (typeof accessToken === 'undefined') {
                throw new Error('cancelSubscriptionOpenCover: Access token required');
            }

            await CancelCoverService.cancelSubscriptionCover(accessToken, coverId, cancelCustomerInsights);

            await refreshCoverAfterCancel(coverId);
        } catch (e) {
            // TODO - handle error
            console.error(e);
        }
    };

    const linkPartnerEventCover = useCallback(async () => {
        const signUpCode = sessionStorage.getItem(StorageKeys.PARTNER_EVENT_SIGN_UP_CODE);

        if (signUpCode) {
            try {
                const accessToken = authState?.accessToken?.accessToken;
                if (typeof accessToken === 'undefined') throw new Error('linkPartnerEventCover: Access token required');

                await PartnerEventService.partnerEventSignUp({ accessToken, partnerEventSignUpCode: signUpCode });

                setTimeout(async () => {
                    // Force refetch after 1.5 second of linking user to event
                    // Platform needs time to return this in BFF
                    await fetchAndSetV2UserPolicies(accessToken);
                }, 1500);

                sessionStorage.removeItem(StorageKeys.PARTNER_EVENT_SIGN_UP_CODE);
            } catch (e) {
                // TODO - handle error
                console.error(e);
            }
        }
    }, [authState?.accessToken?.accessToken]);

    // ***************** Initial fetch for cover data *****************

    const fetchOpenCovers = useCallback(async () => {
        try {
            const accessToken = authState?.accessToken?.accessToken;
            if (typeof accessToken === 'undefined') throw new Error('fetchOpenCovers: Access token required');
            // Display loader
            setOpenCoversLoading(true);

            await fetchAndSetV2UserPolicies(accessToken);

            clearOpenCoversSelections();
        } catch (e) {
            // TODO - handle error
            console.error(e);
        } finally {
            setOpenCoversLoading(false);
            setOpenCoversInitialised(true);
        }
    }, [authState?.accessToken?.accessToken, fetchAndSetV2UserPolicies]);

    // ***************** Side effects *****************

    // Purely for debugging
    useEffect(() => {
        if (env === 'dev' || env === 'test') {
            console.log('STATE', state);
        }
    }, [state]);

    // When flags are ready go and fetch the cover details
    useEffect(() => {
        if (ldClient && !state.openCoversInitialised) {
            ldWaitForInitialization(ldClient).then(() => {
                if (authState?.isAuthenticated) {
                    linkPartnerEventCover();
                    fetchOpenCovers();
                }
            });
        }
    }, [authState?.isAuthenticated, fetchOpenCovers, ldClient, linkPartnerEventCover, state.openCoversInitialised]);

    // ***************** Render *****************

    const value: IOpenCoversContext = {
        ...state,
        setOpenCoversSelections,
        clearOpenCoversSelections,
        cancelSingleOpenCover,
        cancelSubscriptionOpenCover,
        refreshCoverAfterCancel,
        fetchOpenCovers,
    };

    const { children, ...passThroughProps } = props;

    return (
        <OpenCoverContext.Provider value={value} {...passThroughProps}>
            {state.openCoversLoading && <LoadingSpinnerOverlay />}
            {children}
        </OpenCoverContext.Provider>
    );
};
