import LocalizedStrings from 'react-localization';

const personsInsuredContent = new LocalizedStrings({
    en: {
        heading: 'Who do you want cover for?',
        myselfLabel: 'Myself',
        kidsLabel: 'For kids',
        kidsInfo:
            'You will be the account holder on behalf of the Kids’ policies. You must be over 18 and a parent or legal guardian of the children you’re buying cover for.',
        addAKidCta: 'Add a kid',
        kidDetailsFormHeading: 'Kid’s detail',
        kidDetailsFormCta: 'Save',
        kidDetailsFormCancel: 'cancel',
        cta: 'Continue to cover details',
        kidOver18: 'This dependant is over 18 and need to purchase its own cover.',
        kidHasSubscription: 'This dependant already has cover.',
        kidHasSubscriptionDescription: 'Please cancel any existing cover to purchase new cover.',
        maxGuestKidsError:
            'You have reached the maximum {maxGuestKids} allowable kids for checking out. Please adjust your selection.',
        minGuestKidsError:
            'You have exceeded the minimum {minGuestKids} allowable kid for checking out. Please adjust your selection.',
        ageEligibilityTitle:
            'The cover selected is only eligible for customers between {minAge} and {maxAge} years of age',
    },
});

export default personsInsuredContent;
