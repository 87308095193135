enum StorageKeys {
    LOCATION_STATE = 'locationState',
    VALIDATED_REFERRAL = 'validated_referral',
    VALIDATED_REFERRAL_CODE = 'validated_referral_code',
    AUTHENTICATION_METHOD = 'authenticationMethod',
    IS_QUIZ_COMPLETED = 'is_quiz_completed',
    FROM_NO_COVER = 'from_no_cover',
    // For selecting product on webflow
    SELECTED_PRODUCT = 'selected_product',
    CART_COVER_SELECTION = 'cart_cover_selection',
    PARTNER_EVENT_SIGN_UP_CODE = 'partner_event_sign_up_code',
}

export default StorageKeys;
