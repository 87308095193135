import { useMachine } from '@xstate/react';
import { format } from 'date-fns';
import { FC, FormEvent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Destination, Region } from '../../business-logic/models/CoverConfig';
import Alert, { AlertSizes, AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import Button from '../../components/button/Button';
import Fieldset from '../../components/form/fieldset/Fieldset';
import RadioButton from '../../components/form/radio-button/RadioButton';
import Layout from '../../components/layout/Layout';
import Sticky from '../../components/sticky/Sticky';
import roamingStartingRegionContent from '../../content/ui/screens/select-starting-region/selectStartingRegion';
import withContent from '../../hoc/with-content/withContent';
import useCoverConfig from '../../hooks/useCoverConfig';
import usePathParams from '../../hooks/usePathParams';
import common from '../../strings/common';
import { PurchaseState } from '../../types/PurchaseState';
import Routes from '../../utils/Routes';
import DateFormat from '../../utils/constants/DateFormat';
import getTimezone from '../../utils/getTimezone';
import SelectStartingRegionMachine from './select-starting-region-machine/selectStartingRegionMachine';

import './SelectStartingRegion.scss';

const contentMap = {
    heading: 'ui.heading',
    description: 'ui.description',
};

interface SelectStartingRegionProps {
    content: Record<keyof typeof contentMap, string>;
}

const SelectStartingRegion: FC<SelectStartingRegionProps> = ({ content }) => {
    const location = useLocation<PurchaseState>();
    const history = useHistory();
    const { selectedProductGroup: selectedProductGroupId, setSelectedProductGroupParam } = usePathParams();
    const chosenProductGroupId = selectedProductGroupId as string;
    const destinations = useCoverConfig(location.state.selectedProductOption!.representedByCoverCode)?.destinations;

    const [state, send] = useMachine(SelectStartingRegionMachine, {
        context: {
            selectedProductGrouping: chosenProductGroupId,
            selectedProductOption: location.state.selectedProductOption,
            selectedDestination: location?.state?.destination?.startingDestination,
        },
        actions: {
            redirectToPurchase: () => {
                history.push({
                    pathname: Routes.SELECT_COVER,
                    state: {
                        selectedProductGrouping: null,
                        selectedProductOption: null,
                        coverStartDates: [],
                        destination: null,
                    },
                });
            },
            redirectToSelectDates: (ctx) => {
                history.push({
                    pathname: setSelectedProductGroupParam(Routes.SELECT_DATES, chosenProductGroupId),
                    state: {
                        selectedProductGrouping: chosenProductGroupId,
                        selectedProductOption: ctx.selectedProductOption,
                        coverStartDates: [],
                        destination: {
                            destinations: location.state.destination?.destinations,
                            startingDestination: location.state.destination?.startingDestination,
                            startingRegion: ctx.chosenRegion?.region,
                            timezone: ctx.chosenRegion?.timezone,
                        },
                    },
                });
            },
        },
    });

    const selectRegion = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        send({ type: 'CONTINUE' });
    };

    useEffect(() => {
        if (!!destinations && destinations.length > 0) {
            send({
                type: 'LOAD_REGIONS',
                data: {
                    fetchedDestinations: destinations as Destination[],
                },
            });
        }
    }, [send, destinations, chosenProductGroupId]);

    return (
        <Layout
            title={content.heading}
            isContentCentered
            showBackButton
            className="select-start-region__layout"
            showLoading={!destinations}
        >
            {(!destinations || (destinations.length === 0 && !state.hasTag('loading'))) && (
                <Alert
                    type={AlertTypes.ALERT}
                    size={AlertSizes.LARGE}
                    message={ErrorMessages.refreshOrComebackWithApologies}
                />
            )}
            <form onSubmit={selectRegion}>
                <Fieldset legend={content.description} className="select-start-region__list">
                    {!!destinations &&
                        !state.hasTag('loading') &&
                        state.context.availableRegions.map((item: Region) => (
                            <div className="select-start-region-wrapper" key={item.region}>
                                <RadioButton
                                    id={item.region}
                                    name="destination__option"
                                    className="select-start-region"
                                    label={`${item.region} (${getTimezone(
                                        item.timezone,
                                        format(new Date(), DateFormat.DEFAULT),
                                    )})`}
                                    onChange={(e) => {
                                        send({
                                            type: 'SELECTED_REGIONS',
                                            data: e.target.checked ? item : null,
                                        });
                                    }}
                                    checked={state.context.chosenRegion?.region === item.region}
                                />
                                {Object.hasOwn(item, 'description') && (
                                    <p className="select-start-region__description">{item.description}</p>
                                )}
                            </div>
                        ))}
                </Fieldset>
                <Sticky>
                    <Button
                        width="full"
                        type="submit"
                        variant="primary"
                        label={common.continue}
                        disabled={!state.context.chosenRegion}
                        className="select-destinations__cta"
                    />
                </Sticky>
            </form>
        </Layout>
    );
};

export default withContent(SelectStartingRegion, contentMap, roamingStartingRegionContent);
