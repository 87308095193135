import React, { FC } from 'react';
import './CalendarLabel.scss';

type TProps = {
    text: string;
};

const CalendarLabel: FC<TProps> = ({ text }) => {
    return <div className="calendar-label">{text}</div>;
};

export default CalendarLabel;
