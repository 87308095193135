import Gender from '../../../../utils/constants/Gender';

export interface KidDetailsFormMachineContext {
    firstName: string;
    lastName: string;
    dob: {
        day: string;
        month: string;
        year: string;
        error: string;
    };
    gender: Gender | null;
    config: {
        nameMinLength: number;
        minAge: number;
        maxAge: number;
    };
}

export const initialContext: KidDetailsFormMachineContext = {
    firstName: '',
    lastName: '',
    dob: {
        day: '',
        month: '',
        year: '',
        error: '',
    },
    gender: null,
    config: {
        nameMinLength: 1,
        minAge: 5,
        maxAge: 17,
    },
};
