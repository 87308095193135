import { FC } from 'react';
import { useOpenCovers } from '../../../../business-logic/context-provider/OpenCoversContext';
import common from '../../../../strings/common';
import BaseBanner from '../base-banner/BaseBanner';

const PartnerEventBanner: FC = () => {
    const { partnerEvents } = useOpenCovers();

    return (
        <BaseBanner
            text={
                partnerEvents && partnerEvents.length
                    ? common.formatString(common.partnerEventBanner, {
                          eventName: partnerEvents[0].partnerEvent.eventName,
                      })
                    : undefined
            }
        />
    );
};

export default PartnerEventBanner;
