import { useActor } from '@xstate/react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { FC, MouseEventHandler } from 'react';
import { ActorRefFrom } from 'xstate';

import paymentElementMachine from '../payment-element-machine/paymentElementMachine';
import BasePaymentButton from '../../ui-elements/base-payment-button/BasePaymentButton';
import getCheckoutStatus from './getCheckoutStatus';

interface NewCardPaymentButtonProps {
    paymentElementMachineRef: ActorRefFrom<typeof paymentElementMachine>;
    amountDue: number;
}

const NewCardPaymentButton: FC<NewCardPaymentButtonProps> = ({ paymentElementMachineRef, amountDue }) => {
    const [state, send] = useActor(paymentElementMachineRef);

    const stripe = useStripe();
    const elements = useElements();

    const handleClick: MouseEventHandler = (e) => {
        e.preventDefault();
        send({
            type: 'NEW_CARD_CLICK_PAY',
            data: {
                stripe: stripe!,
                elements: elements!,
            },
        });
    };

    return (
        <BasePaymentButton
            amountDue={amountDue}
            checkoutStatus={getCheckoutStatus(state)}
            disabled={!state.hasTag('VALID_CARD')}
            onClick={handleClick}
        />
    );
};

export default NewCardPaymentButton;
