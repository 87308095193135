import { LoadingButtonProps } from '../../../loading-button/LoadingButton';
import { PAYMENT_ELEMENT_MACHINE_TAGS } from '../payment-element-machine/paymentElementMachine';

export default (state: { hasTag: (tag: PAYMENT_ELEMENT_MACHINE_TAGS) => boolean }): LoadingButtonProps['status'] => {
    if (state.hasTag('CHECKOUT_SUCCESS')) {
        return 'success';
    }
    if (state.hasTag('CHECKOUT_LOADING')) {
        return 'loading';
    }
    return 'idle';
};
