import LocalizedStrings from 'react-localization';

const coverDetailsContent = new LocalizedStrings({
    en: {
        heading: 'Your selected cover',
        benefitsCTA: "Check what we pay out for injuries and the injuries we don't cover",
        continueCTA: 'Continue to checkout',
    },
});

export default coverDetailsContent;
