import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const getReferralCode = async ({ accessToken, axios }: { accessToken: string } & ServiceArgs) => {
    const { data } = await axios.post<{ referral_code: string }>(
        `${baseApiPath}/api/v1/referral`,
        undefined,
        toHeaders(accessToken),
    );

    return data.referral_code;
};

export default asService(getReferralCode);
