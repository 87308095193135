import React from 'react';
import Select, { components } from 'react-select';
import chevronDown from '../../assets/images/ic-chevron-down.svg';
import chevronUp from '../../assets/images/ic-chevron-up.svg';
import './Dropdown.scss';

export type OptionType = {
    value: string;
    label: string;
};
export interface DropdownProps {
    options: OptionType[];
    className?: string;
    searchable?: boolean;
    label?: string;
    value: OptionType | null;
    placeholder?: string;
    onChange: (value: any) => void;
    isDisabled?: boolean;
    form?: string;
}

const DropdownIndicator = (props: any) => {
    const { selectProps } = props;
    const { menuIsOpen } = selectProps;
    const icon = menuIsOpen ? chevronUp : chevronDown;
    return (
        <components.DropdownIndicator {...props}>
            <img src={icon} alt="expand-collapse" />
        </components.DropdownIndicator>
    );
};

export const Dropdown: React.FC<DropdownProps> = ({
    label,
    options,
    value,
    placeholder = '',
    onChange,
    searchable,
    className,
    form,
    ...props
}) => {
    return (
        <div className={className}>
            {label != null && <p className="label">{label}</p>}
            <Select
                className="dropdown-container"
                classNamePrefix="dropdown"
                components={{ DropdownIndicator }}
                defaultValue={null}
                value={value}
                isSearchable={searchable}
                onChange={onChange}
                name={label}
                options={options}
                placeholder={placeholder}
                form={form}
                {...props}
            />
        </div>
    );
};

Dropdown.defaultProps = {
    searchable: true,
};

export default Dropdown;
