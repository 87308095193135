import classNames from 'classnames';
import React from 'react';
import './ProgressBar.scss';

export interface ProgressBarProps {
    className?: string;
    completed: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ className, completed }) => {
    return (
        <div className={classNames('progress-bar', className)}>
            <div className="progress-bar--filled" style={{ width: `${completed}%` }} />
        </div>
    );
};

export default ProgressBar;
