import StorageKeys from './constants/StorageKeys';

const getAndClearLocationState = (): unknown => {
    const storedLocationState = sessionStorage.getItem(StorageKeys.LOCATION_STATE);

    if (storedLocationState !== null) {
        return JSON.parse(storedLocationState);
    }

    sessionStorage.removeItem(StorageKeys.LOCATION_STATE);

    return undefined;
};

export default getAndClearLocationState;
