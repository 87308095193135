import classNames from 'classnames';
import { FC } from 'react';
import Analytics from '../../analytics/Analytics';
import disclaimers from '../../content/common/disclaimers';
import ExternalLinks from '../../utils/constants/ExternalLinks';
import ExternalLink from '../external-link/ExternalLink';

import './PdsTmdDisclaimer.scss';

export interface PdsTmdDisclaimerProps {
    preText?: string;
    className?: string;
}

const PdsTmdDisclaimer: FC<PdsTmdDisclaimerProps> = ({ preText, className }) => {
    return (
        <p className={classNames('pds-tmd-disclaimer', className)}>
            {!!preText && `${preText} `}
            {disclaimers.formatString(disclaimers.pdsDisclaimer, {
                pdsFsg: (
                    <ExternalLink
                        to={ExternalLinks.productDisclosureStatement}
                        label={disclaimers.pdsFsg}
                        onClick={Analytics.trackPDSLinkClicked}
                    />
                ),
            })}
        </p>
    );
};

export default PdsTmdDisclaimer;
