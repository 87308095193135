import { useProduct } from '../business-logic/context-provider/ProductContext';
import { CoverConfig } from '../business-logic/models/CoverConfig';

/**
 * Returns CoverConfig content type for a given cover code.
 * Only works for main cover and returns destinations for now
 * If PDS is undefined, nothing will be returned.
 *
 * @param coverCode Cover code to get content for
 *
 * @returns CoverConfig content if content exists, null if content not found
 */
const useCoverConfig = (coverCode: string | undefined): CoverConfig | null => {
    const { coverConfig, products } = useProduct();

    const correspondingProductOnSale = products.find((p) => p.productSpec.mainCoverType.coverCode === coverCode);

    // No corresponding product on sale
    if (correspondingProductOnSale === undefined) {
        return null;
    }

    const pdsVersionOnSale = `${correspondingProductOnSale.pdsVersionMajor}.${correspondingProductOnSale.pdsVersionMinor}`;

    // If PDS version on sale has no corresponding content
    if (
        coverCode === undefined ||
        coverConfig[coverCode] === undefined ||
        coverConfig[coverCode][pdsVersionOnSale] === undefined
    ) {
        return null;
    }

    return coverConfig[coverCode][pdsVersionOnSale];
};

export default useCoverConfig;
