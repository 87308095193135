import alertErrorMessages from '../../../strings/alertErrorMessages';
import common from '../../../strings/common';

import './ErrorMessages.scss';

const TryAgain = () => <strong>{common.tryAgain}</strong>;

const Refresh = () => <strong>{alertErrorMessages.refresh}</strong>;

const ComebackLater = () => <strong>{alertErrorMessages.comebackLater}</strong>;

const ContactUsLink = () => (
    <a href={`mailto:${common.flipSupportEmail}`} className="alert__error-messages__link">
        {alertErrorMessages.contactUs}
    </a>
);

const defaultErrorMessage = () => (
    <span>
        {alertErrorMessages.formatString(alertErrorMessages.tryAgainOrContactUs, {
            tryAgain: <TryAgain />,
            contactUs: <ContactUsLink />,
        })}
    </span>
);

const refreshOrContactUsErrorMessage = () => (
    <span>
        {alertErrorMessages.formatString(alertErrorMessages.refreshOrContactUs, {
            refresh: <Refresh />,
            contactUs: <ContactUsLink />,
        })}
    </span>
);

const refreshOrComebackErrorMessage = () => (
    <span>
        {alertErrorMessages.formatString(alertErrorMessages.refreshOrComeback, {
            refresh: <Refresh />,
            comebackLater: <ComebackLater />,
        })}
    </span>
);

const refreshOrComebackWithApologiesErrorMessage = () => (
    <span>
        {alertErrorMessages.formatString(alertErrorMessages.refreshOrComebackWithApologies, {
            refresh: <Refresh />,
            comebackLater: <ComebackLater />,
        })}
    </span>
);

export default {
    default: defaultErrorMessage(),
    refreshOrContactUs: refreshOrContactUsErrorMessage(),
    refreshOrComeback: refreshOrComebackErrorMessage(),
    refreshOrComebackWithApologies: refreshOrComebackWithApologiesErrorMessage(),
};
