import AnnouncementKeys from '../../AnnouncementKeys';
import MobileAppLaunchAnnouncementConfig from '../MobileAppLaunchAnnouncementConfig';

const shouldShowAnnouncement = (
    variables: Record<string, unknown>,
    showMobileAppLaunchAnnouncementBanner: boolean,
): boolean =>
    // Show if announcementViewCounter object is not defined - first ever announcement
    !!(
        (variables.announcementViewCount === undefined ||
            // Show if mobileAppLaunch has not been defined on announcementViewCounter object
            // first show of this announcement
            (variables.announcementViewCount as Record<string, number>)[AnnouncementKeys.MOBILE_APP_LAUNCH] ===
                undefined ||
            // Show if we have shown this less than max view times
            (variables.announcementViewCount as Record<string, number>)[AnnouncementKeys.MOBILE_APP_LAUNCH] <=
                MobileAppLaunchAnnouncementConfig.MAX_VIEWS) &&
        // and if feature flag is on
        showMobileAppLaunchAnnouncementBanner
    );

export default shouldShowAnnouncement;
