import React, { ReactNode } from 'react';

export interface ExternalLinkProps {
    to: string;
    label: string | ReactNode;
    target?: string;
    className?: string;
    onClick?: () => void;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({ to, label, target = '_blank', className, onClick }) => {
    return (
        <a href={to} target={target} rel="noreferrer noopener" className={className} onClick={onClick}>
            {label}
        </a>
    );
};

export default ExternalLink;
