import { useActor } from '@xstate/react';
import { FC } from 'react';
import { ActorRefFrom } from 'xstate';
import NewCardPaymentButton from '../payment-buttons/NewCardPaymentButton';
import SavedCardPaymentButton from '../payment-buttons/SavedCardPaymentButton';
import paymentElementMachine from '../payment-element-machine/paymentElementMachine';
import SavedCard from '../../ui-elements/saved-card/SavedCard';
import StripeElementProvider from '../stripe-element-provider/StripeElementProvider';
import StripePaymentElement from '../stripe-payment-element/StripePaymentElement';

interface DefaultCardPaymentElementProps {
    paymentElementMachineRef: ActorRefFrom<typeof paymentElementMachine>;
    amountDue: number;
}

const DefaultCardPaymentElement: FC<DefaultCardPaymentElementProps> = (props) => {
    const { paymentElementMachineRef } = props;
    const [state, send] = useActor(paymentElementMachineRef);

    const isChangingCardDetails = state.matches('defaultCard.collectCardDetails');

    return isChangingCardDetails ? (
        <StripeElementProvider paymentElementMachineRef={paymentElementMachineRef}>
            <SavedCard
                brand={state.context.defaultCard!.brand}
                last4={state.context.defaultCard!.last4}
                onChange={() => send('CHANGE_CARD_DETAILS')}
                onCancel={() => send('CANCEL_UPDATE_CARD')}
                isChangingCardDetails={isChangingCardDetails}
                cardDetailsComponent={
                    <StripePaymentElement
                        stripePaymentElementMachineRef={state.context.stripePaymentElementMachine!}
                        isChangingCard
                    />
                }
            />
            <NewCardPaymentButton {...props} />
        </StripeElementProvider>
    ) : (
        <>
            <SavedCard
                brand={state.context.defaultCard!.brand}
                last4={state.context.defaultCard!.last4}
                onChange={() => send('CHANGE_CARD_DETAILS')}
                onCancel={() => send('CANCEL_UPDATE_CARD')}
            />
            <SavedCardPaymentButton {...props} />
        </>
    );
};

export default DefaultCardPaymentElement;
