import Analytics from '../../../analytics/Analytics';
import oktaSignInWidget from '../../../content/ui/components/okta-signin-widget/oktaSigninWidget';
import StorageKeys from '../../../utils/constants/StorageKeys';

const setFormLoadingStyles = () => {
    const usernameField = document.querySelector('form span[data-se="o-form-input-username"]');
    usernameField!.classList.add('o-form-disabled');

    const submitButton = document.querySelector('form input.button[type="submit"]');
    submitButton!.classList.add('link-button-disabled');
    submitButton!.classList.add('btn-disabled');
};

const unsetFormLoadingStyles = () => {
    const usernameField = document.querySelector('form span[data-se="o-form-input-username"]');
    usernameField!.classList.remove('o-form-disabled');

    const submitButton = document.querySelector('form input.button[type="submit"]');
    submitButton!.classList.remove('link-button-disabled');
    submitButton!.classList.remove('btn-disabled');
};

const setupLandingView = (onLoginWithEmailClick: (email: string, onContinueLogin: () => void) => void): void => {
    const authContainer = document.querySelector('div.primary-auth');

    const loginForm = authContainer?.querySelector('form.primary-auth-form');
    const ssoSection = authContainer?.querySelector('form.primary-auth-form + div.primary-auth-container');

    const socialAuthAppleButton = ssoSection?.querySelector('.social-auth-apple-button') as HTMLLinkElement;
    const socialAuthGoogleButton = ssoSection?.querySelector('.social-auth-google-button') as HTMLLinkElement;

    // Replace text for SSO buttons
    socialAuthAppleButton!.innerText = socialAuthAppleButton!.innerText.replace(
        oktaSignInWidget.signIn,
        oktaSignInWidget.continue,
    );
    socialAuthGoogleButton!.innerText = socialAuthGoogleButton!.innerText.replace(
        oktaSignInWidget.signIn,
        oktaSignInWidget.continue,
    );

    // Add analytics handers
    const loginButton = loginForm?.querySelector('input[type="submit"]') as HTMLInputElement;

    loginButton.onclick = async (e) => {
        // Prevent form submission
        e.preventDefault();
        setFormLoadingStyles();

        const email = (loginForm?.querySelector('input#okta-signin-username') as HTMLInputElement).value;

        try {
            await onLoginWithEmailClick(email, () => {
                // On success, set click handler to null, then reinvoke click for form submission
                loginButton.onclick = null;
                Analytics.trackSignInMethodClicked('email');
                sessionStorage.setItem(StorageKeys.AUTHENTICATION_METHOD, 'email');
                unsetFormLoadingStyles();
                loginButton.click();
            });
        } catch {
            unsetFormLoadingStyles();
        }
    };

    socialAuthAppleButton.onclick = () => {
        Analytics.trackSignInMethodClicked('apple');
        sessionStorage.setItem(StorageKeys.AUTHENTICATION_METHOD, 'apple');
    };

    socialAuthGoogleButton.onclick = () => {
        Analytics.trackSignInMethodClicked('google');
        sessionStorage.setItem(StorageKeys.AUTHENTICATION_METHOD, 'google');
    };
};

export default setupLandingView;
