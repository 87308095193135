import { useHistory } from 'react-router-dom';
import blackArrowIcon from '../../assets/images/arrow-left-black.svg';
import whiteShieldIcon from '../../assets/images/icon-shield-white.svg';

import './BrandPurchaseButton.scss';
import Routes from '../../utils/Routes';

const BrandPurchaseButton = () => {
    const history = useHistory();
    return (
        <button
            type="button"
            className="brand-purchase-button"
            onClick={() => {
                // TODO Consider tracking
                history.push(Routes.SELECT_COVER);
            }}
        >
            <div className="brand-purchase-button__wrapper">
                <img src={whiteShieldIcon} alt="" className="brand-purchase-button__icon" />
                <div className="brand-purchase-button__content">
                    <span className="brand-purchase-button__title">Buy cover</span>
                    <span className="brand-purchase-button__description">Discover all Flip products</span>
                </div>
            </div>
            <div className="brand-purchase-button__content__icon__wrapper">
                <img src={blackArrowIcon} alt="" className="brand-purchase-button__content__icon" />
            </div>
        </button>
    );
};

export default BrandPurchaseButton;
