import {
    CycleStatus,
    EventPolicyItem,
    EventStatus,
    PaymentStatus,
    PolicyItem,
    Status,
} from '../../../business-logic/models/PoliciesResponse';
import CoverStatus from '../../../utils/constants/CoverStatus';

export function getCoverStatus(policy: EventPolicyItem | PolicyItem): CoverStatus {
    // Partner events (w/o covers purchased) are
    // determined by event status rather than cover status
    if ('partnerEvent' in policy && policy.partnerEvent != null) {
        if (policy.partnerEvent.status === EventStatus.ENDED) {
            return CoverStatus.INACTIVE;
        }
        if (policy.partnerEvent.status === EventStatus.SCHEDULED) {
            return CoverStatus.SCHEDULED;
        }
        if (policy.partnerEvent.status === EventStatus.STARTED) {
            return CoverStatus.ACTIVE;
        }
    }
    if ('mainCover' in policy && policy.mainCover !== null) {
        if (policy.mainCover.status === Status.ENDED || policy.mainCover.status === Status.CANCELED) {
            return CoverStatus.INACTIVE;
        }
        if (policy.mainCover.status === Status.STARTED) {
            // handle Subscription-v1 & single-v1
            if (
                policy.mainCover.currentCycleStatus === CycleStatus.CANCELED ||
                (policy.mainCover.paymentStatus !== PaymentStatus.SUCCEEDED && policy.mainCover.paymentAttempts! > 1)
            ) {
                return CoverStatus.ENDING;
            }
            return CoverStatus.ACTIVE;
        }
        if (policy.mainCover.status === Status.SCHEDULED) {
            return CoverStatus.SCHEDULED;
        }
    }
    // TODO handle any other cover status (i.e. created)
    return CoverStatus.INACTIVE;
}

export default getCoverStatus;
