import DependantsResponse from '../../business-logic/models/DependantsResponse';
import UserDetails from '../../business-logic/models/UserDetails';
import State from '../../utils/constants/State';

const transformDependantsResponse = (dependantsResponse: DependantsResponse): { dependants: UserDetails[] } => {
    return {
        dependants: dependantsResponse.dependants.map((d) => ({
            personId: d.personId,
            firstName: d.profile.firstName || undefined,
            lastName: d.profile.lastName || undefined,
            dob: d.profile.dateOfBirth || undefined,
            gender: d.profile.gender || undefined,
            state: (d.profile.state?.toUpperCase() as State) || undefined,
            residencyStatusType: d.profile.residencyStatusType || [],
        })),
    };
};

export default transformDependantsResponse;
