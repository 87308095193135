import ProductGroupsResponse, {
    ProductGroup,
    ProductOption,
} from '../../../../business-logic/models/ProductGroupsResponse';

export type PurchaseEntryMachineContextTypes = {
    productGroups: ProductGroupsResponse;
    selectedProductGroupingParam: string | undefined;
    selectedProductGrouping: ProductGroup | null;
    selectedProductOption: ProductOption | null;
};

export const initialContext: PurchaseEntryMachineContextTypes = {
    productGroups: [],
    selectedProductGroupingParam: undefined,
    selectedProductGrouping: null,
    selectedProductOption: null,
};
