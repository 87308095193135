import PriceLineItem from '../price-line-item/PriceLineItem';
import paymentsStrings from '../../../../strings/payments';
import formatPrice from '../../../../utils/formatPrice';
import { CheckoutDetailsResponse } from '../../../../business-logic/models/CheckoutDetails';
import { ListItemProps } from '../../../../components/list-item/ListItem';

export default (checkoutDetails: CheckoutDetailsResponse): ListItemProps => ({
    header: paymentsStrings.discountCode,
    customDataElement: (
        <PriceLineItem
            description={checkoutDetails.coupon?.promoCode}
            amount={`-${formatPrice(checkoutDetails.invoice.couponAmountOff)}`}
            withTopSpacing
        />
    ),
});
