export const CartErrorResolveActions: Record<string, string> = {
    TRY_AGAIN: 'TRY_AGAIN', // refresh page or go to a specific state
    PREV_STEP: 'PREV_STEP', // go to a specific state or route
    CUSTOMER_SUPPORT: 'CUSTOMER_SUPPORT', // show email link
    GO_TO_FLIP: 'GO_TO_FLIP', // go to http://www.getflip.com.au
    DASHBOARD: 'DASHBOARD', // go to http://www.app-getflip.com.au/
};

export const CartErrorResolveActionLabel: Record<string, string> = {
    TRY_AGAIN: 'Try again',
    PREV_STEP: 'Go to previous step',
    CUSTOMER_SUPPORT: 'Contact us',
    GO_TO_FLIP: 'Continue shopping',
    DASHBOARD: 'Go to dashboard',
};

// Handling specific cart api serve response error objects + catchall
export const cartErrorCodesToActions = [
    {
        errorCode: 'cart-10',
        errorMessage: 'You have reached the maximum 15 allowable cart items for checking out. Please adjust your cart.',
        resolveAction: CartErrorResolveActions.PREV_STEP,
    },
    {
        errorCode: 'bill-10',
        errorMessage:
            'There is already an existing coupon attached to the payment for your cart. Please remove them before proceeding.',
        resolveAction: CartErrorResolveActions.PREV_STEP,
    },
    {
        errorCode: 'bill-1',
        errorMessage:
            'The promo code has already been used. Please remove it or use a different promo code before proceeding.',
        resolveAction: CartErrorResolveActions.PREV_STEP,
    },
    {
        errorCode: 'bill-15',
        errorMessage: 'This promo code has expired. Please remove it or use a different promo code before proceeding.',
        resolveAction: CartErrorResolveActions.TRY_AGAIN,
    },
    {
        errorCode: 'single-4',
        errorMessage:
            'Sorry you are not eligible for this insurance cover. Please contact us if you believe there has been a mistake.',
        resolveAction: CartErrorResolveActions.DASHBOARD,
    },
    {
        errorCode: 'sub-16',
        errorMessage:
            'Sorry you are not eligible for this insurance cover. Please contact us if you believe there has been a mistake.',
        resolveAction: CartErrorResolveActions.DASHBOARD,
    },
    {
        errorCode: 'CATCH_ALL',
        errorMessage: 'Sorry, something went wrong at our end. Please refresh or try again later.',
        resolveAction: CartErrorResolveActions.TRY_AGAIN, // TODO: is Trying Again the correct generic/default action?
    },
];

export default { cartErrorCodesToActions, CartErrorResolveActions, CartErrorResolveActionLabel };
