import { useMachine } from '@xstate/react';
import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import usePathParams from '../../hooks/usePathParams';
import { PurchaseState } from '../../types/PurchaseState';
import Routes from '../../utils/Routes';
import { PurchaseEntryMachineContextTypes } from './purchase-entry-machine/context/purchaseEntryMachineContext';
import purchaseEntryMachine from './purchase-entry-machine/purchaseEntryMachine';

const PurchaseEntry: FC = () => {
    const { replace } = useHistory<PurchaseState>();
    const { search } = useLocation();
    const { selectedProductGroup: selectedProductGroupingParam, setSelectedProductGroupParam } = usePathParams();
    const products = useProduct();

    const redirectWithState = (route: string, ctx: PurchaseEntryMachineContextTypes) => {
        replace(
            {
                pathname: setSelectedProductGroupParam(route, selectedProductGroupingParam!),
                search,
            },
            {
                selectedProductGrouping: ctx.selectedProductGrouping ? ctx.selectedProductGrouping.id : null,
                selectedProductOption: ctx.selectedProductOption,
                coverStartDates: [],
                destination: null,
            },
        );
    };

    const [, send] = useMachine(purchaseEntryMachine, {
        actions: {
            redirectToProductGroupSelection: () => replace(Routes.SELECT_COVER),
            redirectToProductOptionSelection: (ctx) => redirectWithState(Routes.SELECT_PRODUCT_OPTIONS, ctx),
            redirectToDestinationSelection: (ctx) => redirectWithState(Routes.SELECT_DESTINATIONS, ctx),
            redirectToDatesSelection: (ctx) => redirectWithState(Routes.SELECT_DATES, ctx),
        },
    });

    useEffect(() => {
        if (products.initialised) {
            send({
                type: 'CONTEXT_INITIALISED',
                data: { productGroups: products.productGroups, selectedProductGroupingParam },
            });
        }
    }, [products.initialised, products.productGroups, selectedProductGroupingParam, send]);

    return <LoadingSpinnerOverlay />;
};

export default PurchaseEntry;
