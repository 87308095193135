import { FC, useState } from 'react';
import infoIcon from '../../assets/images/info.svg';
import common from '../../strings/common';
import ModalWithCTA, { ModalWithCTAProps } from '../modal-with-cta/ModalWithCTA';

import './Tooltip.scss';

interface TooltipProps {
    modalProps: Omit<ModalWithCTAProps, 'isOpen' | 'onClose' | 'primaryCTA'>;
}

const Tooltip: FC<TooltipProps> = ({ modalProps }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button type="submit" onClick={() => setIsOpen(true)} aria-label="More info" className="tooltip">
                <img src={infoIcon} alt="" />
            </button>
            <ModalWithCTA
                {...modalProps}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                primaryCTA={{ label: common.ok, onClick: () => setIsOpen(false), variant: 'secondary' }}
            />
        </>
    );
};

export default Tooltip;
