import LocalizedStrings from 'react-localization';

const coverSummaryCardContent = new LocalizedStrings({
    en: {
        unitPrice: 'Unit Price',
        singleLineItemLabel: `{priceTimePeriod} {index}: `,
        singleLineItemNoCountLabel: `{priceTimePeriod}: `,
        singleLineItemDate: 'Starting {date}',
        subtotal: 'Subtotal: ',
    },
});

export default coverSummaryCardContent;
