import format from 'date-fns-tz/format';
import parse from 'date-fns/parse';
import DateFormat from './constants/DateFormat';

export default (
    date?: Date | string,
    toFormat: DateFormat = DateFormat.DEFAULT,
    fromFormat: DateFormat = DateFormat.DEFAULT,
): string => {
    if (!!date && typeof date === 'string') {
        return format(parse(date, fromFormat, new Date()), toFormat);
    }
    return format((date as Date) || new Date(), toFormat);
};
