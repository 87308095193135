import LocalizedStrings from 'react-localization';

const purchaseOptionsContent = new LocalizedStrings({
    en: {
        heading: 'Purchase options',
        description:
            'Get cover as you need it, or save up to 80% from our daily rate with a Flip subscription. You can also choose longer prepaid options to unlock cheaper daily rates.',
        singleCoverTabLabel: 'Prepaid Options',
        subscriptionCoverTabLabel: 'Subscribe & Save',
        cta: 'Select dates',
    },
});

export default purchaseOptionsContent;
