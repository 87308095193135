import LocalizedStrings from 'react-localization';

const cancelCoverDrawer = new LocalizedStrings({
    en: {
        feedbackHeading: 'A quick favour?',
        feedbackDescription: "Let us know why you're cancelling helps us make Flip even better.",
        feedbackSubheading: 'Select all that applies',
        feedbackFreeTextLabel: 'Enter reason',
        feedbackCTA: 'Send feedback',
        noFeedbackCTA: 'Cancel without feedback',
    },
});

export default cancelCoverDrawer;
