import { assign } from 'xstate';
import ProductGroupsResponse from '../../../../business-logic/models/ProductGroupsResponse';
import { Typegen0 } from '../purchaseEntryMachine.typegen';
import { PurchaseEntryMachineContextTypes } from '../context/purchaseEntryMachineContext';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const initialiseContext = assign<
    PurchaseEntryMachineContextTypes,
    {
        type: EventsCausingActions['initialiseContext'];
        data: {
            productGroups: ProductGroupsResponse;
            selectedProductGroupingParam: string;
        };
    }
>({
    productGroups: (ctx, event) => {
        return event.data.productGroups;
    },
    selectedProductGroupingParam: (ctx, event) => event.data.selectedProductGroupingParam,
}) as any;

export const setSelectedProductGrouping = assign<
    PurchaseEntryMachineContextTypes,
    {
        type: EventsCausingActions['setSelectedProductGrouping'];
        data: {
            productGroups: ProductGroupsResponse;
            selectedProductGroupingParam: string;
        };
    }
>({
    selectedProductGrouping: (ctx) => {
        // Asserted to be not undefined via guard attached to the state this is invoked in
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return ctx.productGroups.find(
            (group) => group.id.toLowerCase() === ctx.selectedProductGroupingParam!.toLowerCase(),
        )!;
    },
}) as any;

export const setOnlyProductOptionAsSelectedProductOption = assign<
    PurchaseEntryMachineContextTypes,
    {
        type: EventsCausingActions['setOnlyProductOptionAsSelectedProductOption'];
    }
>({
    // Asserted to be not undefined via state this is invoked in
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    selectedProductOption: (ctx) => ctx.selectedProductGrouping!.options[0],
}) as any;
