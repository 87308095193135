import request from 'axios';
import { useState } from 'react';

import toHeaders from '../services/utils/toHeaders';

type UseTypeReturn = {
    loading: boolean;
    // TODO: check if we need a error bolean and error message
    // We can just have error message if we're always looking at stripes error
    error: boolean;
    manageProtection: () => void;
};

interface UseStripe {
    basePath: string;
    stripeKey?: string;
    accessToken: string | undefined;
}

const usePaymentStripe = (params: UseStripe): UseTypeReturn => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    // Trigger customer portal
    const manageProtection = async () => {
        const { basePath, accessToken } = params;
        // Check
        if (!basePath) throw new Error('basePath or stripeKey not defined');

        // Initiate
        const path = `${basePath}/api/payment/portal`;

        // states
        setError(false);
        setLoading(true);

        try {
            const { data } = await request.post(path, {}, toHeaders(accessToken));
            window.location.href = data.url;
            // takes a second for the redirect to happen after it's
            // actually called, so delay turning off the loading
            setTimeout(() => setLoading(false), 1000);
        } catch (e) {
            setLoading(false);
            setError(true);
        }
    };

    return {
        loading,
        error,
        manageProtection,
    };
};

export default usePaymentStripe;
