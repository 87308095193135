import classNames from 'classnames';
import React, { ButtonHTMLAttributes, InputHTMLAttributes } from 'react';
import LoadingButton, { LoadingButtonProps } from '../loading-button/LoadingButton';
import TextField, { TextFieldProps } from '../text-field/TextField';

import './TextFieldWithButton.scss';

export interface TextFieldWithButtonProps {
    textFieldProps: TextFieldProps & InputHTMLAttributes<HTMLInputElement>;
    buttonProps: LoadingButtonProps & ButtonHTMLAttributes<HTMLButtonElement>;
}

export const TextFieldWithButton: React.FC<TextFieldWithButtonProps> = ({ textFieldProps, buttonProps }) => {
    const { disabled, className, id, isError, error, label: textfieldLabel } = textFieldProps;

    return (
        <div className={classNames('text-field-with-button', className)}>
            {!!textfieldLabel && (
                <label className={classNames('text-field-with-button__label', disabled && 'disabled')} htmlFor={id}>
                    {textfieldLabel}
                </label>
            )}
            <div
                className={classNames(
                    'text-field-with-button__wrapper',
                    disabled && 'text-field-with-button__input--disabled',
                    isError && 'text-field-with-button__input--error',
                )}
            >
                <TextField className="text-field-with-button__input" {...textFieldProps} />
                <LoadingButton className={classNames('text-field-with-button__button')} {...buttonProps} />
            </div>
            {isError && error && <span className="text-field-with-button__error">{error}</span>}
        </div>
    );
};

export default TextFieldWithButton;
