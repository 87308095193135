import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { assign, createMachine, sendParent } from 'xstate';

const stripePaymentElementMachine = createMachine(
    {
        id: 'StripePaymentElementMachine',
        tsTypes: {} as import('./stripePaymentElementMachine.typegen').Typegen0,
        type: 'parallel',
        schema: {
            context: {} as {
                hasSubscriptionInCheckout: boolean;
                setAsDefault: boolean;
                selectedPaymentMethod: string;
            },
            events: {} as
                | { type: 'TOGGLE_DEFAULT_CARD' }
                | { type: 'PAYMENT_ELEMENT_READY' }
                | { type: 'PAYMENT_ELEMENT_LOADING' }
                | { type: 'PAYMENT_ELEMENT_CHANGE'; data: StripePaymentElementChangeEvent },
        },
        states: {
            paymentElement: {
                initial: 'init',
                states: {
                    init: {
                        on: {
                            PAYMENT_ELEMENT_READY: {
                                target: 'idle',
                            },
                        },
                    },
                    idle: {
                        on: {
                            PAYMENT_ELEMENT_CHANGE: [
                                {
                                    cond: 'isPaymentDetailsComplete',
                                    actions: ['setSelectedPaymentMethod', 'sendPaymentDetailsCompleteEvent'],
                                    target: 'readyToPay',
                                },
                                {
                                    actions: 'setSelectedPaymentMethod',
                                    target: 'idle',
                                },
                            ],
                            PAYMENT_ELEMENT_LOADING: {
                                target: 'init',
                            },
                        },
                    },
                    readyToPay: {
                        on: {
                            PAYMENT_ELEMENT_CHANGE: [
                                {
                                    actions: 'setSelectedPaymentMethod',
                                    cond: 'isPaymentDetailsComplete',
                                    target: 'readyToPay',
                                },
                                {
                                    actions: ['setSelectedPaymentMethod', 'sendPaymentDetailsIncompleteEvent'],
                                    target: 'idle',
                                },
                            ],
                            PAYMENT_ELEMENT_LOADING: {
                                target: 'init',
                            },
                        },
                    },
                },
            },
            defaultCardToggle: {
                initial: 'init',
                states: {
                    init: {
                        always: [
                            {
                                cond: 'shouldShowDefaultCardToggle',
                                target: 'showToggle',
                            },
                            {
                                target: 'hideToggle',
                            },
                        ],
                    },
                    showToggle: {
                        on: {
                            TOGGLE_DEFAULT_CARD: {
                                actions: 'toggleDefaultCard',
                            },
                        },
                    },
                    hideToggle: {
                        type: 'final',
                    },
                },
            },
        },
    },
    {
        guards: {
            isPaymentDetailsComplete: (ctx, event) => event.data.complete,
            shouldShowDefaultCardToggle: (ctx) => !ctx.hasSubscriptionInCheckout,
        },
        actions: {
            setSelectedPaymentMethod: assign({
                selectedPaymentMethod: (ctx, event) => event.data.value.type,
            }),
            sendPaymentDetailsCompleteEvent: sendParent('PAYMENT_DETAILS_COMPLETE'),
            sendPaymentDetailsIncompleteEvent: sendParent('PAYMENT_DETAILS_INCOMPLETE'),
            toggleDefaultCard: assign({
                setAsDefault: (ctx) => !ctx.setAsDefault,
            }),
        },
    },
);

export default stripePaymentElementMachine;
