import LocalizedStrings from 'react-localization';

const errors = new LocalizedStrings({
    en: {
        errorSomethingWentWrong: 'Sorry, something went wrong.',
        errorSomethingWentWrongTryAgain: 'Sorry, something went wrong at our end. Please try again later.',
    },
});

export default errors;
