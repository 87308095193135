import classNames from 'classnames';
import React, { useState } from 'react';
import roamingProductModal from '../../content/ui/components/roaming-product-modal/roamingProductModal';
import withContent from '../../hoc/with-content/withContent';
import commonStrings from '../../strings/common';
import parseString from '../../utils/parseString';
import ModalWithCTA from '../modal-with-cta/ModalWithCTA';
import { ModalAlert } from '../modal-with-cta/utils/ModalWithCTAAlertTypes';

import './RoamingProductModal.scss';

interface RoamingProductModalProps {
    isOpen: boolean;
    onCancelYesClick: () => void;
    onExitClick: () => void;
    content: Record<keyof typeof contentMap, string | string[]>;
    trackCancelClick: (nextSlide: boolean) => void;
}

const contentMap = {
    slide1: 'ui.slide1',
    slide2: 'ui.slide2',
};

const RoamingProductModal: React.FC<RoamingProductModalProps> = ({
    isOpen,
    content,
    onCancelYesClick,
    onExitClick,
    trackCancelClick,
}) => {
    const [nextSlide, setNextSlide] = useState<boolean>(false);

    const handleMultiSlideClick = () => {
        if (!nextSlide) {
            setNextSlide(true);
        } else {
            onCancelYesClick();
        }
    };

    const renderModalContent = (item: any, index: number) => {
        const type = Object.keys(item)[0];
        const value = item[type];
        switch (type) {
            case 'title':
                return (
                    <h2 key={index} className="roaming-product-modal__title">
                        {parseString(value)}
                    </h2>
                );
            case 'iconList':
                return (
                    <div key={index} className="roaming-product-modal__list">
                        {value.list.map((note: string) => (
                            <div key={note} className="roaming-product-modal__list__item">
                                <img src={value.icon} alt="" className="roaming-product-modal__list__item__icon" />
                                <span>{parseString(note)}</span>
                            </div>
                        ))}
                    </div>
                );
            case 'description':
                return (
                    <div key={index} className="roaming-product-modal__description">
                        {parseString(value)}
                    </div>
                );
            default:
                return '';
        }
    };
    const renderContent = () => {
        return (
            <div className={classNames('roaming-product-modal__slide-wrapper', nextSlide && 'active')}>
                {Object.entries(content).map(([key, value]) => (
                    <div key={key} className={`roaming-product-modal__${key}`}>
                        {Array.from(value).map((item, index) => renderModalContent(item, index))}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <ModalWithCTA
            className="roaming-product-modal"
            isOpen={isOpen}
            icon={ModalAlert.INFO}
            textContent={renderContent()}
            variant="slide-to-center"
            primaryCTA={{
                label: nextSlide ? commonStrings.understandContinue : commonStrings.understand,
                onClick: handleMultiSlideClick,
            }}
            tertiaryCTA={{
                label: `<span className="roaming-product__link">This isn&apos;t right for me <span className="roaming-product__link--exit">Exit</span></span>`,
                onClick: () => {
                    trackCancelClick(nextSlide);
                    onExitClick();
                },
            }}
        />
    );
};

export default withContent(RoamingProductModal, contentMap, roamingProductModal);
