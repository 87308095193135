import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import { ChangeEvent, ChangeEventHandler, FC, FormEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import close from '../../assets/images/close.svg';
import alert from '../../assets/images/icon-warning-red-40x40.svg';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import { CycleStatus, PaymentStatus, PolicyItem, Status } from '../../business-logic/models/PoliciesResponse';
import cancelCoverDrawer from '../../content/ui/components/cancel-cover-drawer/cancelCoverDrawer';
import withContent from '../../hoc/with-content/withContent';
import CoverTypeId from '../../utils/constants/CoverTypeId';
import DateFormat from '../../utils/constants/DateFormat';
import formatISO8601DateTime from '../../utils/formatISO8601DateTime';
import getTimezone from '../../utils/getTimezone';
import CancelCoverFeedbackDrawer from '../cancel-cover-feedback-drawer/CancelCoverFeedbackDrawer';
import Checkbox from '../form/checkbox/Checkbox';
import Fieldset from '../form/fieldset/Fieldset';
import LoadingButton from '../loading-button/LoadingButton';
import Modal from '../modal/Modal';

import './CancelCoverDrawer.scss';

const contentMap = {
    cancelYourCover: 'ui.cancelYourCover',
    cancelFirstNameCover: 'ui.cancelFirstNameCover',
    starting: 'ui.starting',
    destination: 'ui.destination',
    renews: 'ui.renews',
    cancelSubscriptionInfo: 'ui.cancelSubscriptionInfo',
    cancelInfo: 'ui.cancelInfo',
    cancelCoverCTA: 'ui.cancelCoverCTA',
};

interface CancelCoverDrawerProps {
    content: Record<keyof typeof contentMap, string>;
    policies: PolicyItem[];
    firstName?: string;
    isOpen: boolean;
    preselectedCover: string | null;
    onClose: () => void;
    refreshCovers?: () => void;
}

const CancelCoverDrawerV2: FC<CancelCoverDrawerProps> = ({
    content,
    policies,
    firstName,
    isOpen,
    preselectedCover,
    onClose,
    refreshCovers,
}) => {
    const { userTimeZone } = useUser();

    const [subscriptionsToCancel, setSubscriptionsToCancel] = useState<string[]>([]);
    const [singleCoversToCancel, setSingleCoversToCancel] = useState<string[]>([]);
    const [isCancelFeedback, setIsCancelFeedback] = useState(false);
    const { coverInformation } = useProduct();

    const hasSelectedCoverToCancel = subscriptionsToCancel.length > 0 || singleCoversToCancel.length > 0;

    const handleCancel = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onClose();
        setIsCancelFeedback(true);
    };

    const coversAvailableForCancellation = useMemo(() => {
        const subscriptionsNotEnding = policies.filter(
            (x) =>
                !!x.mainCover &&
                !!x.mainCover.coverTypeId &&
                x.mainCover.coverTypeId === CoverTypeId.SUBSCRIPTION_V1 &&
                x.mainCover.paymentStatus !== PaymentStatus.FAILED &&
                x.mainCover.status !== Status.CANCELED &&
                x.mainCover.currentCycleStatus !== CycleStatus.CANCELED,
        );

        const singlePolicies = policies.filter(
            (x) =>
                !!x.mainCover &&
                !!x.mainCover.coverTypeId &&
                x.mainCover.coverTypeId === CoverTypeId.SINGLE_V1 &&
                x.mainCover.status !== Status.CANCELED,
        );

        const filterActiveCover = (covers: PolicyItem[]) =>
            covers.filter(
                (c) =>
                    !isBefore(
                        utcToZonedTime(parseISO(c.mainCover.startTime), c.policyTimezone ?? userTimeZone),
                        new Date(),
                    ),
            );

        return {
            subscriptionCovers: subscriptionsNotEnding,
            singleCovers: [...filterActiveCover(singlePolicies)],
        };
    }, [policies]);

    const renderCheckbox = (
        id: string,
        coverName: ReactNode,
        subText: ReactNode,
        onChange: ChangeEventHandler<HTMLInputElement>,
        checked: boolean,
        destination?: ReactNode,
    ) => {
        return (
            <Checkbox
                id={id}
                key={id}
                name="covers"
                label={
                    <div className="cancel-cover-drawer__checkbox-label">
                        <span className="cancel-cover-drawer__checkbox-labe-cover-name">{coverName}</span>
                        <span>{subText}</span>
                        <div>{destination}</div>
                    </div>
                }
                onChange={onChange}
                checked={checked}
            />
        );
    };

    const renderSubscriptionCheckbox = (subscription: PolicyItem) => {
        const coverName = coverInformation[subscription.mainCover.coverCode][subscription.PDSVersion].name;
        const defaultStartDate = formatISO8601DateTime(
            subscription.mainCover.startTime,
            subscription.policyTimezone ?? userTimeZone,
            DateFormat.DEFAULT,
        );
        const subText =
            subscription.mainCover.status === 'Scheduled'
                ? cancelCoverDrawer.formatString(content.starting, {
                      startDate: formatISO8601DateTime(
                          subscription.mainCover.startTime,
                          userTimeZone,
                          DateFormat.VERBOSE,
                      ),
                      timezone: getTimezone(subscription.policyTimezone ?? userTimeZone, defaultStartDate),
                  })
                : cancelCoverDrawer.formatString(content.renews, {
                      renewDate: formatISO8601DateTime(
                          subscription.mainCover.nextCycleAt!,
                          userTimeZone,
                          DateFormat.VERBOSE,
                      ),
                      timezone: getTimezone(subscription.policyTimezone ?? userTimeZone, defaultStartDate),
                  });

        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                setSubscriptionsToCancel((prev) => [...prev, subscription.mainCover.insuranceCoverId]);
            } else {
                setSubscriptionsToCancel((prev) => prev.filter((i) => i !== subscription.mainCover.insuranceCoverId));
            }
        };
        const checked = subscriptionsToCancel.some((i) => i === subscription.mainCover.insuranceCoverId);

        return renderCheckbox(subscription.mainCover.insuranceCoverId, coverName, subText, onChange, checked);
    };

    const renderSingleCoverCheckbox = (cover: PolicyItem) => {
        const coverName = coverInformation[cover.mainCover.coverCode][cover.PDSVersion].name;
        const defaultStartDate = formatISO8601DateTime(
            cover.mainCover.startTime,
            cover.policyTimezone ?? userTimeZone,
            DateFormat.DEFAULT,
        );
        const subText = cancelCoverDrawer.formatString(content.starting, {
            startDate: formatISO8601DateTime(
                cover.mainCover.startTime,
                cover.policyTimezone ?? userTimeZone,
                DateFormat.VERBOSE,
            ),
            timezone: getTimezone(cover.policyTimezone ?? userTimeZone, defaultStartDate),
        });

        const destination = cover.mainCover.destination ? (
            <>{cover.mainCover.destination?.destinations.sort((a, b) => a.localeCompare(b)).join(', ')}</>
        ) : (
            ''
        );

        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                setSingleCoversToCancel((prev) => [...prev, cover.mainCover.insuranceCoverId]);
            } else {
                setSingleCoversToCancel((prev) => prev.filter((i) => i !== cover.mainCover.insuranceCoverId));
            }
        };
        const checked = singleCoversToCancel.some((i) => i === cover.mainCover.insuranceCoverId);

        return renderCheckbox(cover.mainCover.insuranceCoverId, coverName, subText, onChange, checked, destination);
    };

    const heading = firstName
        ? cancelCoverDrawer.formatString(content.cancelFirstNameCover, { firstName })
        : content.cancelYourCover;

    const handleClose = () => {
        setSingleCoversToCancel([]); // reset selection
        setSubscriptionsToCancel([]);
        onClose();
    };

    const resetAnyCoversToCancel = () => {
        setSingleCoversToCancel([]); // reset selection
        setSubscriptionsToCancel([]);
    };

    useEffect(() => {
        if (!preselectedCover) return;
        if (
            coversAvailableForCancellation.singleCovers.find((x) => x.mainCover.insuranceCoverId === preselectedCover)
        ) {
            setSingleCoversToCancel((prev) => [...prev, preselectedCover]);
        }
        if (
            coversAvailableForCancellation.subscriptionCovers?.find(
                (x) => x.mainCover.insuranceCoverId === preselectedCover,
            )
        ) {
            setSubscriptionsToCancel((prev) => [...prev, preselectedCover]);
        }
    }, [coversAvailableForCancellation, preselectedCover]);

    return (
        <>
            <Modal isOpen={isOpen} parentClass="#root" onClose={handleClose} variant="slide-in">
                <div className="cancel-cover-drawer__modal-close-button-wrapper">
                    <button
                        type="button"
                        className="cancel-cover-drawer__modal-close-button"
                        aria-label="Close"
                        onClick={handleClose}
                    >
                        <img src={close} alt="Close" />
                    </button>
                </div>

                <form onSubmit={handleCancel}>
                    <img src={alert} alt="" />
                    <h2 className="cancel-cover-drawer__heading">{heading}</h2>
                    <Fieldset legend={heading} visuallyHideLegend className="cancel-cover-drawer__fieldset">
                        {!!coversAvailableForCancellation.subscriptionCovers &&
                        coversAvailableForCancellation.subscriptionCovers.length > 0 ? (
                            <>
                                {coversAvailableForCancellation.subscriptionCovers.map((subscriptionCover) => (
                                    <div key={subscriptionCover.policyNumber}>
                                        {renderSubscriptionCheckbox(subscriptionCover)}
                                        {subscriptionCover.mainCover.status !== 'Scheduled' &&
                                            cancelCoverDrawer.formatString(content.cancelSubscriptionInfo, {
                                                endDateTime: formatISO8601DateTime(
                                                    subscriptionCover.mainCover.currentCycleEndTime!,
                                                    subscriptionCover.policyTimezone ?? userTimeZone,
                                                    DateFormat.TIME_AND_DATE,
                                                ),
                                            })}
                                    </div>
                                ))}
                            </>
                        ) : null}

                        {coversAvailableForCancellation.singleCovers.map(renderSingleCoverCheckbox)}
                    </Fieldset>

                    <LoadingButton
                        status="idle"
                        label={content.cancelCoverCTA}
                        width="full"
                        type="submit"
                        disabled={!hasSelectedCoverToCancel}
                        className="cancel-cover-drawer__cta"
                    />
                    <p className="cancel-cover-drawer__cancel-info">{content.cancelInfo}</p>
                </form>
            </Modal>
            <CancelCoverFeedbackDrawer
                isOpen={isCancelFeedback}
                onClose={() => {
                    handleClose();
                    setIsCancelFeedback(false);
                }}
                onCancel={refreshCovers}
                subscriptionsToCancel={subscriptionsToCancel}
                singleCoversToCancel={singleCoversToCancel}
                resetAnyCoversToCancel={resetAnyCoversToCancel}
            />
        </>
    );
};

export default withContent(CancelCoverDrawerV2, contentMap, cancelCoverDrawer);
