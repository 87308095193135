import { assign } from 'xstate';
import errors from '../../../../../../content/common/errors';
import guestCartContent from '../../../../../../content/ui/screens/guest-cart/guestCart';
import Gender from '../../../../../../utils/constants/Gender';
import ResidencyStatusTypes from '../../../../../../utils/constants/ResidencyStatusTypes';
import State from '../../../../../../utils/constants/State';
import { ProfileMachineContext } from '../context/profileMachineContext';
import { Typegen0 } from '../profileMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setEmail = assign<ProfileMachineContext, { type: EventsCausingActions['setEmail']; data: string }>({
    email: (ctx, event) => event.data,
}) as any;

export const setEmailError = assign<ProfileMachineContext, { type: EventsCausingActions['setEmailError'] }>({
    emailError: () => guestCartContent.profileStep.emailError,
}) as any;

export const clearEmailError = assign<
    ProfileMachineContext,
    | {
          type: Extract<EventsCausingActions['clearEmailError'], 'UPDATE_EMAIL'>;
          data: string;
      }
    | {
          type: Exclude<EventsCausingActions['clearEmailError'], 'UPDATE_EMAIL'>;
      }
>({
    emailError: () => '',
}) as any;

export const setFirstName = assign<ProfileMachineContext, { type: EventsCausingActions['setFirstName']; data: string }>(
    {
        firstName: (ctx, event) => event.data,
    },
) as any;

export const setLastName = assign<ProfileMachineContext, { type: EventsCausingActions['setLastName']; data: string }>({
    lastName: (ctx, event) => event.data,
}) as any;

export const setDate = assign<ProfileMachineContext, { type: EventsCausingActions['setDate']; data: string }>({
    dob: (ctx, event) => ({ ...ctx.dob, day: event.data }),
}) as any;

export const setMonth = assign<ProfileMachineContext, { type: EventsCausingActions['setMonth']; data: string }>({
    dob: (ctx, event) => ({ ...ctx.dob, month: event.data }),
}) as any;

export const setYear = assign<ProfileMachineContext, { type: EventsCausingActions['setYear']; data: string }>({
    dob: (ctx, event) => ({ ...ctx.dob, year: event.data }),
}) as any;

export const setInvalidDateFormatError = assign<
    ProfileMachineContext,
    { type: EventsCausingActions['setInvalidDateFormatError'] }
>({
    dob: (ctx) => ({
        ...ctx.dob,
        error: guestCartContent.profileStep.dobError.errorInvalidDate,
    }),
}) as any;

export const setMinAgeError = assign<ProfileMachineContext, { type: EventsCausingActions['setMinAgeError'] }>({
    dob: (ctx) => ({
        ...ctx.dob,
        error: guestCartContent.profileStep.dobError.errorMustBeOver18,
    }),
}) as any;

export const clearDobError = assign<
    ProfileMachineContext,
    | {
          type: Extract<EventsCausingActions['clearDobError'], 'UPDATE_DATE' | 'UPDATE_MONTH' | 'UPDATE_YEAR'>;
          data: string;
      }
    | {
          type: Exclude<EventsCausingActions['clearDobError'], 'UPDATE_DATE' | 'UPDATE_MONTH' | 'UPDATE_YEAR'>;
      }
>({
    dob: (ctx) => ({ ...ctx.dob, error: '' }),
}) as any;

export const setState = assign<ProfileMachineContext, { type: EventsCausingActions['setState']; data: State }>({
    state: (ctx, event) => event.data,
}) as any;

export const setResidencyStatus = assign<
    ProfileMachineContext,
    { type: EventsCausingActions['setResidencyStatus']; data: ResidencyStatusTypes }
>({
    residencyStatus: (ctx, event) => event.data,
}) as any;

export const setGender = assign<ProfileMachineContext, { type: EventsCausingActions['setGender']; data: Gender }>({
    gender: (ctx, event) => event.data,
}) as any;

export const setGenericError = assign<
    ProfileMachineContext,
    { type: EventsCausingActions['setGenericError']; data: unknown }
>({
    genericError: () => errors.errorSomethingWentWrongTryAgain,
}) as any;

export const clearGenericError = assign<ProfileMachineContext, { type: EventsCausingActions['clearGenericError'] }>({
    genericError: () => '',
}) as any;
