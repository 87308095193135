import { LoginCallback } from '@okta/okta-react';
import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Routes from '../../utils/Routes';
import CertificateOfCover from '../certificate-of-cover/CertificateOfCover';

import TrackedRoute from '../../components/tracked-route/TrackedRoute';
import TrackedSecureRoute from '../../components/tracked-route/TrackedSecureRoute';
import redirectToOnboarding from '../../hoc/with-redirect-to-onboarding/withRedirectToOnboarding';
import Activities from '../activities/Activities';
import CartSuccess from '../cart-success/CartSuccess';
import Cart from '../cart/Cart';
import CoverDetails from '../cover-details/CoverDetails';
import Home from '../home/Home';
import Login from '../login/Login';
import ManageCoverV3 from '../manage-cover-v3/ManageCoverV3';
import NotFound from '../not-found/NotFound';
import OnboardingStart from '../onboarding/onboarding-start/OnboardingStart';
import ProfileAccount from '../profile-account/ProfileAccount';
import ProfilePreferences from '../profile-preferences/ProfilePreferences';
import Profile from '../profile/Profile';
import PurchaseOptions from '../puchase-options/PurchaseOptions';
import PurchaseEntry from '../purchase-entry/PurchaseEntry';
import Registration from '../registration/Registration';
import SelectCover from '../select-cover/SelectCover';
import SelectDates from '../select-dates/SelectDates';
import SelectDestinations from '../select-destinations/SelectDestinations';
import SelectStartingDestination from '../select-starting-destination/SelectStartingDestination';
import SelectStartingRegion from '../select-starting-region/SelectStartingRegion';
import YourDetails from '../your-details/YourDetails';

const Router: FC = () => (
    <Switch>
        {/* Landing & dashboard */}
        <TrackedRoute exact path={Routes.LANDING} component={OnboardingStart} />
        <TrackedSecureRoute path={Routes.HOME} component={redirectToOnboarding(Home)} />
        <TrackedSecureRoute path={Routes.MANAGE_COVER} component={ManageCoverV3} />

        {/* User profile forms */}
        <TrackedSecureRoute path={Routes.ACTIVITIES} component={Activities} />
        <TrackedSecureRoute path={Routes.YOUR_DETAILS} component={YourDetails} />

        {/* Auth */}
        <TrackedRoute path={Routes.REGISTER} exact component={Registration} />
        <TrackedRoute path={Routes.LOGIN_CALLBACK} component={LoginCallback} />
        {/* Catch all for Okta widget scenarios below - must go after Register and callback */}
        <TrackedRoute path={Routes.LOGIN} component={Login} />

        {/* Account */}
        <TrackedSecureRoute exact path={Routes.ACCOUNT} component={Profile} />
        <TrackedSecureRoute path={Routes.ACCOUNT_DETAILS} component={ProfileAccount} />
        <TrackedSecureRoute path={Routes.ACCOUNT_PREFERENCES} component={ProfilePreferences} />
        <TrackedSecureRoute path={Routes.ACCOUNT_CERTIFICATE_OF_COVER} component={CertificateOfCover} />

        {/* Purchase */}
        <TrackedRoute exact path={Routes.SELECT_COVER} component={SelectCover} />
        <TrackedRoute path={Routes.SELECT_PRODUCT_OPTIONS} component={PurchaseOptions} />
        <TrackedRoute path={Routes.SELECT_DATES} component={SelectDates} />
        <TrackedRoute path={Routes.SELECT_DESTINATIONS} component={SelectDestinations} />
        <TrackedRoute path={Routes.SELECT_STARTING_DESTINATION} component={SelectStartingDestination} />
        <TrackedRoute path={Routes.SELECT_STARTING_REGION} component={SelectStartingRegion} />
        <TrackedRoute path={Routes.COVER_DETAILS} component={CoverDetails} />
        {/* Cart paths needs to here to prevent clashing with any of the above purchase routes */}
        <TrackedSecureRoute exact path={Routes.CART} component={Cart} />
        <TrackedSecureRoute exact path={Routes.CART_SUCCESS} component={CartSuccess} />
        {/* Purchase path entry needs to here to prevent clashing with any of the above purchase routes */}
        <TrackedRoute path={Routes.PURCHASE_ENTRY} component={PurchaseEntry} />

        {/* Not found */}
        <Route path={Routes.NOT_FOUND} component={NotFound} />
        <Redirect to={Routes.NOT_FOUND} />
    </Switch>
);

export default Router;
