import { UserPaymentMethod } from '../../business-logic/models/UserPaymentMethod';
import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const getPaymentMethods = async ({
    accessToken,
    axios,
}: { accessToken: string } & ServiceArgs): Promise<UserPaymentMethod[]> => {
    try {
        const { data } = await axios.get(`${baseApiPath}/api/v1/billing/stripe/payment-method`, toHeaders(accessToken));
        return data.Cards || [];
    } catch (error: any) {
        if (error.response.status === 404) {
            return [];
        }

        throw new Error(error);
    }
};

export default asService(getPaymentMethods);
