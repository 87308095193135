import format from 'date-fns-tz/format';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import enAU from 'date-fns/locale/en-AU';
import parse from 'date-fns/parse';
import DateFormat from './constants/DateFormat';

export default (timezone: string, date: string) => {
    return format(zonedTimeToUtc(parse(date, DateFormat.DEFAULT, new Date()), timezone), DateFormat.TIMEZONE, {
        timeZone: timezone,
        locale: enAU,
    });
};
