import LocalizedStrings from 'react-localization';

const profileFlow = new LocalizedStrings({
    en: {
        title: 'Account',
        accountDetails: 'Account details',
        yourAccount: 'Your account',
        yourPrefs: 'Your preferences',
        yourCover: 'Your cover',
        accountCredit: 'Account credit',
        accountCreditText: 'Your account is {creditBalance} in credit',
        noChargeAlert:
            "<strong>Great news, your account is in credit.</strong> Your card won't get charged while your  account is in credit.",
        manageBillingAndPayments: 'Manage billing and payments',
        cancelCoverLabel: 'Cancel cover',
        manageCoverLabel: 'Manage cover',
        yourCertificateOfCover: 'Your Certificate of Cover',
        getInTouch: 'Get in touch',
        viewPrivacyPolicy: 'View Privacy Policy',
        viewTermsAndConditions: 'View Terms & Conditions',
        viewFaqs: 'View FAQs',
        customerNumber: 'Customer number',
        yourPreferences: {
            remindersTitle: 'Reminders',
            remindersDescription: "So you know where your Flip cover's at.",
            productSurveysTitle: 'Product Feedback',
            productSurveysDescription: 'To help us improve the Flip experience.',
            guidesAndPromotionsTitle: 'Guides and promotions',
            guidesAndPromotionsDescription: 'Content and inspiration from Flip.',
            policyAndClaimUpdatesTitle: 'Policy and claims updates',
            policyAndClaimUpdatesDescription:
                'This one’s non-negotiable, sorry. We have to send you updates on your policy and claims – so, for legal reasons, you can’t disable this.',
            accountUpdatesTitle: 'Account updates',
            accountUpdatesDescription:
                'Again, non-negotiable. We have to send you info about your account, protection, security and privacy from time to time – so you can’t disable this one, either.',
        },
        manageCover: {
            subtitle: 'Current',
            viewCoverage: 'View coverage >',
            coverBenefit: 'Up to <strong>$20k</strong> depending on your injury',
            alphaCard: {
                title: 'Subscription',
                subtitle: 'Ongoing cover for peace of mind, cancel anytime.',
            },
            dayPassCard: {
                title: 'Day pass',
                subtitle: 'Flexible cover, just for the days you want it',
            },
            alwaysOnCard: {
                title: 'Always on',
                subtitle: 'Ongoing cover for peace of mind, cancel anytime.',
            },
            activeDayPassAlert:
                "<strong>You have an active Day Pass for today:</strong> you can't cancel a Day Pass once it's started. You're covered until <strong>11:59 PM today.</strong>",
            activeJapanPassAlert:
                "<strong>You have an active Japan Pass:</strong> you can't cancel a Japan Pass once it's started. You're covered until <strong>{endDate} at {endTime} Japan ({timezone})</strong>",
            backToProfile: 'Back to profile',
        },
        cancelCoverInfo: {
            title: 'What you need to know before you cancel',
            alphaSubtitle:
                "If you decide to cancel your cover and you're past the cooling-off period, you’re still protected until <strong>{endDate}</strong>. Otherwise, your cover will be cancelled at the end of the current day. You also won't be able to purchase any new Boosts after today.",
            activeSubscriptionSubtitle:
                "If you decide to cancel your cover, you're still protected until <strong>{endDate}</strong>",
            scheduledSubscriptionSubtitle:
                'If you decide to cancel your cover beginning <strong>{startDate}</strong> your card will automatically be reimbursed.',
            activeSingleCoverSubtitle:
                "<strong>If you have an active cover:</strong> you can't cancel a cover once it's started. You'll still be covered until <strong>{endDate} at {endTime} {timezone}. </strong><br /><br /><strong>Upcoming cover:</strong> any cover you've scheduled for later will be automatically cancelled and your card reimbursed.",
            singleCoverSubtitle:
                "<strong>Upcoming cover:</strong> any cover you've scheduled for later will be automatically cancelled and your card reimbursed.",
            alphaDisclaimer:
                'Just know that the <strong>terms and conditions</strong> might change or not be available when you apply for new cover.',
            disclaimer:
                'Just know that <strong>cover terms and conditions</strong> might change when you apply for new cover.',
            understoodButtonLabel: 'I understand',
        },
        cancelCover: {
            title: 'A quick favour? 🙏🏻',
            subtitle: "Letting us know why you're cancelling helps us make Flip even better.",
            description: 'Select all that apply:',
            dayPassCancellationReasons: {
                plansChanged: "My plans changed so I don't need it",
                tooExpensive: "I've decided it's too exxy for what I get",
                usingAnotherInsuranceProduct: "I'm using another insurance product",
                needOngoingCover: 'I need ongoing cover not just for specific days',
                other: 'Other',
            },
            alwaysOnCancellationReasons: {
                weeklySubscriptions: 'I only needed cover for 1-2 weeks',
                goingOverseas: 'I’m going overseas',
                tooExpensive: "I've decided it's too exxy for what I get",
                usingAnotherInsuranceProduct: "I'm using another insurance product",
                turnOnAndOff: 'I want to turn it off and on as I need',
                needToForSpecificDays: 'I want cover for specific days only',
                other: 'Other',
            },
            errorNoCoverToCancel: 'Sorry. You must have cover in order to cancel it.',
            errorNoFutureDayPassesToCancel: "Sorry. Looks like you don't have future cover scheduled to cancel.",
            errorCoverAlreadyCancelled: 'Sorry. Your cover is already cancelled.',
        },
        certificateOfCover: {
            failedToLoadCertificateOfCover: {
                title: 'Oops, there was an error loading your Certificate of Cover',
                subtitle: 'Please try downloading it instead.',
            },
            failedToGetCertificateOfCover: {
                title: 'Oops, there was an error retrieving your Certificate of Cover',
                subtitle: 'Please go back to your Profile and try again.',
                buttonLabel: 'Back to Profile',
            },
        },
    },
});

export default profileFlow;
