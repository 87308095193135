import background from '../../assets/images/background.svg';
import Button from '../../components/button/Button';
import Layout from '../../components/layout/Layout';
import notFoundContent from '../../content/ui/screens/not-found/notFound';
import logo from '../../logo.svg';
import ExternalLinks from '../../utils/constants/ExternalLinks';
import parseString from '../../utils/parseString';

import './NotFound.scss';

export const NotFound = () => {
    return (
        <Layout className="not-found-page" bgPattern={false} showPdsVersion={false} isContentCenteredWide>
            <div className="not-found__container">
                <img src={logo} alt="Flip Insurance" className="not-found__logo" />
                <h1 className="not-found__heading">{notFoundContent.heading}</h1>
                <p className="not-found__description">{parseString(notFoundContent.description)}</p>
                <div className="not-found__button-group">
                    <Button
                        className="not-found__button"
                        label={<span>{notFoundContent.primaryCTA}</span>}
                        // eslint-disable-next-line no-return-assign
                        onClick={() => (window.location.href = ExternalLinks.landingPage)}
                    />
                    <Button
                        className="not-found__button"
                        variant="secondary"
                        label={<span>{notFoundContent.secondaryCTA}</span>}
                        // eslint-disable-next-line no-return-assign
                        onClick={() => (window.location.href = ExternalLinks.productPage)}
                    />
                </div>
            </div>
            <img src={background} alt="" className="not-found__background" />
        </Layout>
    );
};

export default NotFound;
