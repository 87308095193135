import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';
import CustomerResponse, { CustomerResponseCommunication } from '../../business-logic/models/CustomerResponse';
import transformCustomerResponse, { CustomerDetails } from './transformCustomerResponse';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const updateCustomerCommunication = async ({
    accessToken,
    axios,
    communication,
}: {
    accessToken: string;
    communication: Partial<CustomerResponseCommunication>;
} & ServiceArgs): Promise<CustomerDetails> => {
    const { data } = await axios.put<CustomerResponse>(
        `${baseApiPath}/api/v1/customer/communication`,
        communication,
        toHeaders(accessToken),
    );

    return transformCustomerResponse(data);
};

export default asService(updateCustomerCommunication);
