import { createMachine } from 'xstate';
import { ActivityConfig } from '../../../business-logic/models/ActivitiesConfig';
import {
    setActivityFreeText,
    setActivitySelection,
    updateActivitiesToContext,
} from '../actions/activitiesSelectionMachineActions';
import { ActivitiesSelectionMachineContextTypes } from '../context/ActivitiesSelectionMachineContext';
import { updateProfileActivities } from '../services/activitiesSelectionMachineServices';

const ActivitiesSelectionMachine = createMachine(
    {
        id: 'activitiesSelectionMachine',
        initial: 'updateContext',
        schema: {
            context: {} as ActivitiesSelectionMachineContextTypes,
            events: {} as
                | { type: 'CONFIRM_ACTIVITIES'; data: string[] }
                | { type: 'UPDATE_CONTEXT'; data: ActivityConfig[] }
                | {
                      type: 'FREETEXT_UPDATE';
                      data: {
                          activityId: string;
                          freeTextValue: string;
                      };
                  }
                | {
                      type: 'TOGGLE_ACTIVITY_SELECTION';
                      data: {
                          activityId: string;
                          selected: boolean;
                      };
                  },
        },
        tsTypes: {} as import('./activitiesSelectionMachine.typegen').Typegen0,
        context: {
            accessToken: null,
            updateProfile: false, // should customer profile be updated with activities
            activities: [],
        },
        preserveActionOrder: true,
        states: {
            updateContext: {
                tags: ['initialising'],
                on: {
                    UPDATE_CONTEXT: {
                        actions: ['updateActivitiesToContext'],
                        target: 'idle',
                    },
                },
            },
            idle: {
                on: {
                    CONFIRM_ACTIVITIES: {
                        target: 'updateActivities',
                    },
                    TOGGLE_ACTIVITY_SELECTION: {
                        actions: ['setActivitySelection'],
                    },
                    FREETEXT_UPDATE: {
                        actions: ['setActivityFreeText'],
                    },
                },
            },
            updateActivities: {
                tags: ['initialising'],
                invoke: {
                    src: 'updateProfileActivities',
                    onDone: [
                        {
                            target: 'readyToContinue',
                        },
                    ],
                    onError: [
                        {
                            target: 'displayUpdateError',
                        },
                    ],
                },
            },
            readyToContinue: {
                type: 'final',
                entry: ['notifyActivitiesUpdateComplete'],
            },
            displayUpdateError: {
                tags: ['error'],
                on: {
                    CONFIRM_ACTIVITIES: {
                        target: 'updateActivities',
                    },
                },
            },
        },
    },
    {
        actions: {
            updateActivitiesToContext,
            setActivitySelection,
            setActivityFreeText,
        },
        services: {
            updateProfileActivities,
        },
    },
);

export default ActivitiesSelectionMachine;
