import axios from 'axios';
import { CartInstanceResponse, CartResponseItem } from '../../business-logic/models/Cart';
import asService, { ServiceArgs } from '../utils/asService';
import assertAccessTokenValidity from './utils/assertTokenValidity';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const deleteItemsFromCart = async ({
    accessToken,
    coversToDelete,
}: {
    accessToken: string | undefined;
    coversToDelete: CartResponseItem[];
} & ServiceArgs): Promise<CartInstanceResponse | null> => {
    try {
        assertAccessTokenValidity(accessToken);
        const config = {
            headers: {
                Authorization: accessToken,
            },
            data: {
                insuranceCoverIds: [...coversToDelete.map((c) => c.insuranceCoverId)],
            },
        };
        const { data } = await axios.delete(`${baseApiPath}/api/v1/cart/item`, config);
        return data;
    } catch (error) {
        throw new Error(`Error with calling DELETE cart item service: ${error}`);
    }
};

export default asService(deleteItemsFromCart);
