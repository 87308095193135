import { assign } from 'xstate';
import { Typegen0 } from '../kidDetailsFormMachine.typegen';
import { KidDetailsFormMachineContext } from '../context/kidDetailsFormMachineContext';
import Gender from '../../../../utils/constants/Gender';
import kidDetailsFormContent from '../../../../content/ui/components/kid-details-form/kidDetailsForm';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setFirstName = assign<
    KidDetailsFormMachineContext,
    { type: EventsCausingActions['setFirstName']; data: string }
>({
    firstName: (ctx, event) => event.data,
}) as any;

export const setLastName = assign<
    KidDetailsFormMachineContext,
    { type: EventsCausingActions['setLastName']; data: string }
>({
    lastName: (ctx, event) => event.data,
}) as any;

export const setDate = assign<KidDetailsFormMachineContext, { type: EventsCausingActions['setDate']; data: string }>({
    dob: (ctx, event) => ({ ...ctx.dob, day: event.data }),
}) as any;

export const setMonth = assign<KidDetailsFormMachineContext, { type: EventsCausingActions['setMonth']; data: string }>({
    dob: (ctx, event) => ({ ...ctx.dob, month: event.data }),
}) as any;

export const setYear = assign<KidDetailsFormMachineContext, { type: EventsCausingActions['setYear']; data: string }>({
    dob: (ctx, event) => ({ ...ctx.dob, year: event.data }),
}) as any;

export const setInvalidDateFormatError = assign<
    KidDetailsFormMachineContext,
    { type: EventsCausingActions['setInvalidDateFormatError'] }
>({
    dob: (ctx) => ({
        ...ctx.dob,
        error: kidDetailsFormContent.dobError.errorInvalidDate,
    }),
}) as any;

export const setMinAgeError = assign<KidDetailsFormMachineContext, { type: EventsCausingActions['setMinAgeError'] }>({
    dob: (ctx) => ({
        ...ctx.dob,
        error: kidDetailsFormContent.dobError.errorMustBeOver18,
    }),
}) as any;

export const setMaxAgeError = assign<KidDetailsFormMachineContext, { type: EventsCausingActions['setMaxAgeError'] }>({
    dob: (ctx) => ({
        ...ctx.dob,
        error: kidDetailsFormContent.dobError.errorMustBeUnder65,
    }),
}) as any;

export const clearDobError = assign<
    KidDetailsFormMachineContext,
    | {
          type: Extract<EventsCausingActions['clearDobError'], 'UPDATE_DATE' | 'UPDATE_MONTH' | 'UPDATE_YEAR'>;
          data: string;
      }
    | {
          type: Exclude<EventsCausingActions['clearDobError'], 'UPDATE_DATE' | 'UPDATE_MONTH' | 'UPDATE_YEAR'>;
      }
>({
    dob: (ctx) => ({ ...ctx.dob, error: '' }),
}) as any;

export const setGender = assign<
    KidDetailsFormMachineContext,
    { type: EventsCausingActions['setGender']; data: Gender }
>({
    gender: (ctx, event) => event.data,
}) as any;
