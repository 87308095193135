import classNames from 'classnames';
import { FC, ReactNode, useEffect, useRef } from 'react';
import tick from '../../../../assets/images/tick.svg';
import usePrevious from '../../../../hooks/usePrevious';

import './StepItem.scss';

interface StepItemProps {
    stepIndex: number;
    title: string;
    description?: string;
    children: ReactNode;
    open: boolean;
    onToggle: () => void;
    completed?: boolean;
    disabled?: boolean;
    scrollToTopOnOpen?: boolean;
}

const StepItem: FC<StepItemProps> = ({
    stepIndex,
    title,
    description,
    children,
    open,
    onToggle,
    completed,
    disabled,
    scrollToTopOnOpen,
}) => {
    const previousOpenState = usePrevious(open);
    const stepItemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTimeout(() => {
            if (!previousOpenState && open && scrollToTopOnOpen && stepItemRef.current) {
                const stepProgressBar = document.querySelector('#step-progress-bar');

                let progressBarOffset = 0;
                if (stepProgressBar) {
                    progressBarOffset = stepProgressBar.getBoundingClientRect().bottom;
                }

                window.scroll({
                    top: stepItemRef.current.getBoundingClientRect().top + window.pageYOffset - progressBarOffset,
                    behavior: 'smooth',
                });
            }
        }, 0);
    }, [open, previousOpenState, scrollToTopOnOpen]);

    return (
        <div className="step-item" ref={stepItemRef}>
            <button
                className={classNames(
                    'step-item__button',
                    open && 'step-item__button--open',
                    disabled && !open && !completed && 'step-item__button--disabled-style',
                )}
                type="button"
                onClick={onToggle}
                disabled={disabled}
            >
                <div className={classNames('step-item__step', completed && 'step-item__step--completed')}>
                    {completed ? <img src={tick} alt="Completed" /> : stepIndex + 1}
                </div>
                <div className="step-item__header">
                    <p className="step-item__title">{title}</p>
                    {description && <p className="step-item__description">{description}</p>}
                </div>
            </button>
            {open && children}
        </div>
    );
};

export default StepItem;
