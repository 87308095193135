import { PolicyItem, Status, CycleStatus, PaymentStatus } from '../../../business-logic/models/PoliciesResponse';
import CoverCancelStatus from '../../../utils/constants/CoverCancelStatus';
import CoverTypeId from '../../../utils/constants/CoverTypeId';

export function getCancelStatus(policy: PolicyItem): CoverCancelStatus {
    if (policy.mainCover != null) {
        if (policy.mainCover.coverTypeId === CoverTypeId.SINGLE_V1 && policy.mainCover.status === Status.SCHEDULED) {
            return CoverCancelStatus.CANCANCEL;
        }

        if (policy.mainCover.coverTypeId === CoverTypeId.SUBSCRIPTION_V1) {
            if (
                policy.mainCover.currentCycleStatus === CycleStatus.CANCELED ||
                policy.mainCover.paymentStatus !== PaymentStatus.SUCCEEDED
            ) {
                return CoverCancelStatus.NOTAPPLICABLE;
            }

            return CoverCancelStatus.CANCANCEL;
        }
    }

    return CoverCancelStatus.NOTAPPLICABLE;
}

export default getCancelStatus;
