import parse from 'date-fns/parse';
import DateFormat from './constants/DateFormat';

// Little helper to manage number to string;
const toPad = (num: number, places: number) => String(num).padStart(places, '0');

const toDate = (date?: string): { day: string; month: string; year: string } => {
    if (typeof date === 'undefined') return { day: '', month: '', year: '' };

    const dateValue = parse(date, DateFormat.DEFAULT, new Date());

    return {
        day: toPad(dateValue.getDate(), 2),
        month: toPad(dateValue.getMonth() + 1, 2),
        year: toPad(dateValue.getFullYear(), 2),
    };
};

export default toDate;
