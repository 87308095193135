import LocalizedStrings from 'react-localization';

const selectStartingDestinationContent = new LocalizedStrings({
    en: {
        heading: 'Where would you like your cover to start?',
        cta: 'Continue to purchase options',
    },
});

export default selectStartingDestinationContent;
