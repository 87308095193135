import { FC, ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import './SlideInDownTransition.scss';

export interface SlideInDownTransitionProps {
    children: ReactNode;
    in: boolean;
}

const SlideInDownTransition: FC<SlideInDownTransitionProps> = ({ in: inProp, children }) => {
    const nodeRef = useRef(null);
    return (
        <div className="slide-in-down-wrapper">
            <CSSTransition
                mountOnEnter
                nodeRef={nodeRef}
                in={inProp}
                timeout={500}
                classNames="slide-in-down"
                unmountOnExit
            >
                <div ref={nodeRef}>{children}</div>
            </CSSTransition>
        </div>
    );
};

export default SlideInDownTransition;
