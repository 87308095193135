import { useMutation } from '@tanstack/react-query';
import { useUser } from '../../business-logic/context-provider/user-context/UserContext';
import {
    CartCheckout,
    CartError,
    CartErrorDisplay,
    CartInstanceResponse,
    CartRequestItem,
    CartResponseItem,
} from '../../business-logic/models/Cart';
import CartService from '../../services/cart-service/CartService';
import { cartErrorCodesToActions } from './constants/CartErrorMessages';
import withCartErrorHandling from './utils/withCartErrorHandling';

const useCart = () => {
    const { accessToken } = useUser();

    const postCart = (
        selectedCovers: CartRequestItem[],
    ): Promise<CartInstanceResponse | CartErrorDisplay | CartError | null> => {
        return withCartErrorHandling(CartService.createCart({ accessToken, cartItems: selectedCovers }));
    };

    // TODO: Assumes that a UI event is attached to each item iterated from the cart object's coverItems array
    const deleteCovers = async (coversToDelete: CartResponseItem[]) => {
        try {
            return await CartService.deleteItemsFromCart({ accessToken, coversToDelete });
        } catch (error) {
            throw new Error('Authenticated Cart: Error with deleting covers from cart: ' + error);
        }
    };

    const closingCart = async (): Promise<CartCheckout | CartError | null> => {
        const response = await withCartErrorHandling(CartService.closeCart({ accessToken }));
        return {
            checkoutDetails: {
                checkoutDetails: {
                    ...(response as CartInstanceResponse),
                },
                covers: (response as CartInstanceResponse).coverItems,
            },
            paymentMethods: [],
        };
    };
    const createCart = useMutation(postCart, {
        retry: (failureCount, error) => {
            if (error && !!(error as Error).cause) {
                const cartError = cartErrorCodesToActions.find(
                    (item: CartErrorDisplay) =>
                        item.errorCode === ((error as Error).cause as CartErrorDisplay).errorCode,
                );
                if (cartError) return false;
            }
            return failureCount < 3;
        },
    });

    // Temporarily disengaged until needed
    // const getGuestCart = useQuery({
    //     queryKey: ['guestCart'],
    //     queryFn: () => CartService.getGuestCart({ accessToken }),
    //     enabled: !!accessToken && newCartApi,
    // });

    /* Authenticated flow */
    const deleteItemsFromCart = useMutation(deleteCovers);
    const closeCart = useMutation(closingCart, { retry: 3 });

    // Temporarily disengaged until needed
    // const getCart = useQuery({
    //     queryKey: ['cart'],
    //     queryFn: () => CartService.getCart({ accessToken }),
    //     enabled: !!accessToken && newCartApi && authState?.isAuthenticated,
    // });

    return {
        createCart,
        deleteItemsFromCart,
        closeCart,
    };
};

export default useCart;
