import { FC } from 'react';
import { WeekPicker, DatePicker, MultipleDatePicker, RangePicker } from './components';
import { NewDatePickerProps } from './types';

import './NewDatePicker.scss';

const NewDatePicker: FC<NewDatePickerProps> = (props) => {
    const { mode } = props;

    return (
        <div className="new-date-picker">
            {mode === 'week' && <WeekPicker {...props} />}
            {mode === 'range' && <RangePicker {...props} />}
            {mode === 'single' && <DatePicker {...props} />}
            {mode === 'multiple' && <MultipleDatePicker {...props} />}
        </div>
    );
};

export default NewDatePicker;
