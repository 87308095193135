import { AnchorHTMLAttributes, FC, ReactNode } from 'react';
import classNames from 'classnames';

import './LinkButton.scss';

export type LinkButtonProps = {
    variant?: 'primary' | 'secondary' | 'tertiary' | 'invert';
    width?: 'full' | 'standard';
    label: string | ReactNode;
    onClick: () => void;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

const LinkButton: FC<LinkButtonProps> = ({ variant = 'primary', width = 'standard', label, onClick, ...props }) => {
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <a
            onClick={onClick}
            onKeyDown={onClick}
            className={classNames('link', `link--${variant}`, `link--${width}`)}
            {...props}
        >
            {label}
        </a>
    );
};

export default LinkButton;
