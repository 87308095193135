import { createMachine } from 'xstate';
import {
    setRecentlyAddedKid,
    setSelectedPersons,
    spawnKidDetailsFormMachine,
} from '../actions/personsInsuredMachineActions';
import { PersonsInsuredMachineContextTypes } from '../context/personsInsuredMachineContext';
import { hasSelectedKid } from '../guards/personsInsuredMachineGuards';
import { PersonsInsuredMachineServices, createDependant } from '../services/personsInsuredMachineServices';

const PersonsInsuredMachine = createMachine(
    {
        id: 'PersonsInsuredMachine',
        initial: 'determineIfSelectionIsValid',
        schema: {
            context: {} as PersonsInsuredMachineContextTypes,
            events: {} as
                | { type: 'SELECT_MYSELF' }
                | { type: 'SELECT_KIDS' }
                | { type: 'CONTINUE' }
                | { type: 'ADD_A_KID' }
                | { type: 'SET_MYSELF_INSURED'; data: { selectedPersons: string[] } }
                | { type: 'SET_SELECTED_PERSONS'; data: { selectedPersons: string[] } }
                | { type: 'SAVE_KID' }
                | { type: 'CANCEL_ADD_A_KID' },
            services: {} as PersonsInsuredMachineServices,
        },
        tsTypes: {} as import('./personsInsuredMachine.typegen').Typegen0,
        context: {
            isAccountHolderInsured: false,
            selectedPersons: [],
            dependants: [],
            kidDetailsFormMachineRef: null,
            accessToken: '',
        },
        preserveActionOrder: true,
        states: {
            determineIfSelectionIsValid: {
                always: [{ cond: 'hasSelectedKid', target: 'readyToContinue' }, { target: 'idle' }],
            },
            idle: {
                on: {
                    ADD_A_KID: {
                        target: 'addAKid',
                    },
                    SET_SELECTED_PERSONS: {
                        actions: 'setSelectedPersons',
                        target: 'determineIfSelectionIsValid',
                    },
                    SET_MYSELF_INSURED: {
                        actions: 'setSelectedPersons',
                        target: 'determineIfSelectionIsValid',
                    },
                },
            },
            readyToContinue: {
                on: {
                    ADD_A_KID: {
                        target: 'addAKid',
                    },
                    SET_SELECTED_PERSONS: {
                        actions: 'setSelectedPersons',
                        target: 'determineIfSelectionIsValid',
                    },
                    SET_MYSELF_INSURED: {
                        actions: 'setSelectedPersons',
                        target: 'determineIfSelectionIsValid',
                    },
                    CONTINUE: {
                        actions: 'notifyDependantsCreationComplete',
                    },
                },
            },
            addAKid: {
                initial: 'spawnKidDetailsFormMachine',
                states: {
                    spawnKidDetailsFormMachine: {
                        always: {
                            actions: 'spawnKidDetailsFormMachine',
                            target: 'ready',
                        },
                    },
                    ready: {
                        on: {
                            SAVE_KID: {
                                target: 'createDependant',
                            },
                            CANCEL_ADD_A_KID: {
                                target: '#PersonsInsuredMachine.determineIfSelectionIsValid',
                            },
                        },
                    },
                    createDependant: {
                        invoke: {
                            src: 'createDependant',
                            onDone: {
                                actions: ['updateUserContext', 'setRecentlyAddedKid'],
                                target: '#PersonsInsuredMachine.determineIfSelectionIsValid',
                            },
                            onError: {
                                target: 'displayCreateDependantError',
                            },
                        },
                    },
                    displayCreateDependantError: {
                        on: {
                            SAVE_KID: {
                                target: 'createDependant',
                            },
                            CANCEL_ADD_A_KID: {
                                target: '#PersonsInsuredMachine.determineIfSelectionIsValid',
                            },
                        },
                    },
                },
            },
        },
    },
    {
        guards: {
            hasSelectedKid,
        },
        actions: {
            setRecentlyAddedKid,
            setSelectedPersons,
            spawnKidDetailsFormMachine,
        },
        services: {
            createDependant,
        },
    },
);

export default PersonsInsuredMachine;
