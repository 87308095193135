import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import ldWaitForInitialization from '../../utils/third-party-dependencies/ldWaitForInitialization';

/**
 * This HOC blocks rendering until feature flags are initialised and ready to use.
 * Use this HOC with caution and only in places where you're trying to prevent flicker
 * in the DOM due to possible delay in initial loading of feature flags.
 *
 * @param WrappedComponent The component to render
 * @returns React.FC
 */
function requireFlags<T extends JSX.IntrinsicAttributes>(WrappedComponent: React.FC<T>): React.FC<T> {
    return (props: T) => {
        const ldClient = useLDClient();
        const [isFlagsLoaded, setIsFlagsLoaded] = useState(false);

        useEffect(() => {
            if (ldClient) {
                ldWaitForInitialization(ldClient).then(() => {
                    setIsFlagsLoaded(true);
                });
            }
        }, [ldClient]);

        if (isFlagsLoaded) {
            return <WrappedComponent {...props} />;
        }

        return <LoadingSpinnerOverlay />;
    };
}

export default requireFlags;
