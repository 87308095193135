import { ButtonHTMLAttributes, FC } from 'react';
import classNames from 'classnames';
import arrowLeftBlue from '../../assets/images/arrow-left-blue.svg';

import './DashboardButton.scss';

export enum DashboardButtonStyle {
    REGULAR = 'REGULAR',
    LITE = 'LITE',
    ERROR = 'ERROR',
    SYSTEM = 'SYSTEM',
    SUCCESS = 'SUCCESS',
}

export type DashboardButtonProps = {
    label: string;
    icon?: string;
    description?: string;
    onClick: () => void;
    className?: string;
    variant?: 'none' | 'primary';
    style?: DashboardButtonStyle;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const DashboardButton: FC<DashboardButtonProps> = ({
    label,
    icon,
    description,
    className,
    variant = 'none',
    style = DashboardButtonStyle.REGULAR,
    onClick,
    ...props
}) => {
    return style === DashboardButtonStyle.LITE ? (
        <button
            type="button"
            onClick={onClick}
            className={classNames(
                'dashboard-button',
                `dashboard-button--${variant}`,
                'dashboard-button--lite',
                className,
            )}
            {...props}
        >
            <div className="dashboard-button__title">
                <img src={icon} alt="" className="dashboard-button__title__icon" />
                <span>{label}</span>
            </div>
        </button>
    ) : (
        <button
            type="button"
            onClick={onClick}
            className={classNames('dashboard-button', `dashboard-button--${variant}`, className)}
            {...props}
        >
            <div className="dashboard-button__title">
                <span>{label}</span>
                <div className="dashboard-button__title__icon__wrapper">
                    <img src={arrowLeftBlue} alt="" className="dashboard-button__title__icon" />
                </div>
            </div>
            <div className="dashboard-button__content">
                <img src={icon} alt="" className="dashboard-button__content__icon" />
                <span>{description}</span>
            </div>
        </button>
    );
};

export default DashboardButton;
