interface Headers {
    headers: {
        Authorization: string;
        'content-type': string;
        Accept: string;
    };
}

const toHeaders = (accessToken?: string): Headers => ({
    headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
    },
});

export default toHeaders;
