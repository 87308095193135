import LocalizedStrings from 'react-localization';

const selectCoverContent = new LocalizedStrings({
    en: {
        heading: 'Select the cover you need',
        benefitsCta: 'View benefits and cover details',
    },
});

export default selectCoverContent;
