import { PaymentElement } from '@stripe/react-stripe-js';
import { useActor } from '@xstate/react';
import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { ActorRefFrom } from 'xstate';

import paymentsStrings from '../../../../strings/payments';
import LoadingSpinner from '../../../loading-spinner/LoadingSpinner';
import Toggle from '../../../toggle/Toggle';
import stripePaymentElementMachine from './stripe-payment-element-machine/stripePaymentElementMachine';

import './StripePaymentElement.scss';

interface StripePaymentElementProps {
    stripePaymentElementMachineRef: ActorRefFrom<typeof stripePaymentElementMachine>;
    isChangingCard?: boolean;
}

const StripePaymentElement: FC<StripePaymentElementProps> = ({ stripePaymentElementMachineRef, isChangingCard }) => {
    const [state, send] = useActor(stripePaymentElementMachineRef);

    const showToggle = state.matches('defaultCardToggle.showToggle');
    const isLoadingPaymentElement = state.matches('paymentElement.init');

    useEffect(() => {
        send('PAYMENT_ELEMENT_LOADING');
    }, []);

    return (
        <div className={classNames({ 'stripe-payment-element--loading-payment-element': isLoadingPaymentElement })}>
            {isLoadingPaymentElement && (
                <div className="stripe-payment-element__inputs-loading-overlay">
                    <LoadingSpinner />
                </div>
            )}
            <div
                className={classNames('stripe-payment-element__inputs', {
                    'stripe-payment-element__inputs--loading-payment-element': isLoadingPaymentElement,
                    'stripe-payment-element__inputs--changing-card': isChangingCard,
                    'stripe-payment-element__inputs--no-toggle': !showToggle,
                })}
            >
                <PaymentElement
                    onChange={(e) => send({ type: 'PAYMENT_ELEMENT_CHANGE', data: e })}
                    options={{ fields: { billingDetails: { name: 'auto' } } }}
                    onReady={() => send('PAYMENT_ELEMENT_READY')}
                />
            </div>

            {showToggle && (
                <div
                    className={classNames('stripe-payment-element__default-toggle-wrapper', {
                        'stripe-payment-element__default-toggle-wrapper--changing-card': isChangingCard,
                        'stripe-payment-element__default-toggle-wrapper--loading-payment-element':
                            isLoadingPaymentElement,
                    })}
                >
                    <Toggle
                        id="set-as-default-card"
                        label={
                            <span className="stripe-payment-element__default-toggle-label">
                                {paymentsStrings.saveThisCard}
                            </span>
                        }
                        checked={state.context.setAsDefault}
                        onToggle={() => send({ type: 'TOGGLE_DEFAULT_CARD' })}
                    />
                </div>
            )}
        </div>
    );
};

export default StripePaymentElement;
