import LocalizedStrings from 'react-localization';

const common = new LocalizedStrings({
    en: {
        flipSupportEmail: 'help@getflip.com.au',
        support: 'Support',
        legal: 'Legal',
        signup: 'Sign up',
        login: 'Log in',
        logout: 'Log out',
        yes: 'Yes',
        no: 'No',
        ok: 'Ok',
        agree: 'Agree',
        disagree: 'Disagree',
        continue: 'Continue',
        understand: 'I understand',
        understandContinue: 'I understand, continue',
        start: 'Start',
        done: 'Done',
        back: 'Back',
        next: 'Next',
        skip: 'Skip',
        goBack: 'Go back',
        exit: 'Exit',
        fullName: 'Full name',
        dateOfBirth: 'Date of birth',
        email: 'Email',
        phone: 'Phone',
        password: 'Password',
        state: 'State',
        DateOfBirth: 'Date of birth',
        bankDetails: 'Bank details',
        accountName: 'Account name',
        accountNumber: 'Account number',
        bsb: 'Bsb',
        ausPermResident: 'Australian permanent resident',
        directDebitAgreement: 'Direct Debit Agreement',
        customerSupport: 'Customer Support',
        reachOutToUs: 'reach out to us',
        productDisclosureStatement: 'Product Disclosure Statement',
        productDisclosureStatementShort: 'PDS',
        productDisclosureStatementAndFinancialGuide: 'Product Disclosure Statement and Financial Services Guide',
        productDisclosureStatementFinancialGuideWithComma: 'Product Disclosure Statement, Financial Services Guide',
        targetMarketDetermination: 'Target Market Determination',
        pdsTmdDisclaimer:
            'Full details of your cover including definitions and legal jazz are found in the {pds} and {tmd} documents.',
        privacyPolicy: 'Privacy Policy',
        charsRemaining: 'characters remaining',
        date: 'Date',
        am: 'AM',
        pm: 'PM',
        download: 'Download',
        uploadDocument: {
            buttonLabel: '+ Upload file',
            error: 'Sorry, looks like you tried uploading an invalid file',
        },
        errorSomethingWentWrong: 'Sorry, something went wrong.',
        errorSomethingWentWrongTryAgain: 'Sorry, something went wrong at our end. Please try again later.',
        tryAgain: 'Try again',
        close: 'Close',
        navBarLabels: {
            home: 'Home',
            claims: 'Claims',
            profile: 'Account',
            cover: 'Cover',
        },
        phoneNumberPlaceholder: '0412345678',
        add: 'Add',
        select: 'Select',
        confirm: 'Confirm',
        learnMore: 'Learn more',
        buyNow: 'Buy now',
        skipForNow: 'Skip for now',
        perDay: 'per day',
        perWeek: 'per week',
        maybeLater: 'Maybe later',
        other: 'Other',
        today: 'Today',
        tomorrow: 'Tomorrow',
        copy: 'Copy',
        copySuccess: 'Copied link to clipboard',
        termsAndConditionsApply: 'T&Cs apply.',
        creditBanner: 'You have a {creditBalance} credit waiting 🙌',
        billingCenter: 'billing center',
        partnerEventBanner: 'Complete your profile to manage your {eventName} cover',
        anotherWeek: '+ Add another week',
        confirmDates: 'Confirm dates',
        pdsVersion: 'PDS Version {pdsVersion}',
    },
});

export default common;
