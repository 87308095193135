import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import shieldIconBlue from '../../assets/images/icon-shield-blue.svg';
import shieldIcon from '../../assets/images/icon-shield.svg';
import claimsActive from '../../assets/images/nav/claims-active.svg';
import claims from '../../assets/images/nav/claims.svg';
import homeActive from '../../assets/images/nav/home-active.svg';
import home from '../../assets/images/nav/home.svg';
import profileActive from '../../assets/images/nav/profile-active.svg';
import profileMd from '../../assets/images/nav/profile-md.svg';
import profile from '../../assets/images/nav/profile.svg';
import logo from '../../logo.svg';
import common from '../../strings/common';
import ClaimsDeprecateModal from '../claims-deprecation-modal/ClaimsDeprecateModal';

import './Navigation.scss';

const Navigation: React.FC = () => {
    const { navBarLabels } = common;
    const [openClaimsDeprecation, setOpenClaimsDeprecation] = useState(false);
    return (
        <>
            <nav className="nav">
                <NavLink className="nav__logo" to="/dashboard">
                    <img src={logo} alt="logo" />
                </NavLink>
                <NavLink className="nav__item" to="/dashboard">
                    <img src={home} alt="home" className="nav__item--sm" />
                    <img src={homeActive} alt="home" className="nav__item--sm-active" />
                    <span className="nav__label">{navBarLabels.home}</span>
                </NavLink>
                <NavLink className="nav__item" to="/manage-cover">
                    <img src={shieldIcon} alt="home" className="nav__item--sm" />
                    <img src={shieldIconBlue} alt="home" className="nav__item--sm-active" />
                    <span className="nav__label">{navBarLabels.cover}</span>
                </NavLink>
                <button
                    type="button"
                    className="nav__item nav__item--button"
                    onClick={() => {
                        setOpenClaimsDeprecation(true);
                    }}
                >
                    <img src={claims} alt="claims" className="nav__item--sm" />
                    <img src={claimsActive} alt="claims" className="nav__item--sm-active" />
                    <span className="nav__label">{navBarLabels.claims}</span>
                </button>
                <NavLink className="nav__item" to="/profile">
                    <img src={profile} alt="profile" className="nav__item--sm" />
                    <img src={profileActive} alt="profile" className="nav__item--sm-active" />
                    <img src={profileMd} alt="profile" className="nav__item--md" />
                    <span className="nav__label nav__label--mobile">{navBarLabels.profile}</span>
                </NavLink>
            </nav>
            <ClaimsDeprecateModal isOpen={openClaimsDeprecation} onClose={() => setOpenClaimsDeprecation(false)} />
        </>
    );
};

export default Navigation;
