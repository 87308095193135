import { useMachine } from '@xstate/react';
import { FC, FormEvent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Destination } from '../../business-logic/models/CoverConfig';
import Alert, { AlertSizes, AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import Button from '../../components/button/Button';
import Fieldset from '../../components/form/fieldset/Fieldset';
import RadioButton from '../../components/form/radio-button/RadioButton';
import Layout from '../../components/layout/Layout';
import Sticky from '../../components/sticky/Sticky';
import selectStartingDestinationContent from '../../content/ui/screens/select-starting-destination/selectStartingDestination';
import withContent from '../../hoc/with-content/withContent';
import useCoverConfig from '../../hooks/useCoverConfig';
import usePathParams from '../../hooks/usePathParams';
import common from '../../strings/common';
import { PurchaseState } from '../../types/PurchaseState';
import Routes from '../../utils/Routes';
import SelectStartingDestinationMachine from './select-starting-destination-machine/selectStartingDestinationMachine';

import './SelectStartingDestination.scss';

const contentMap = {
    heading: 'ui.heading',
    cta: 'ui.cta',
};

interface SelectStartingDestinationProps {
    content: Record<keyof typeof contentMap, string>;
}

const SelectStartingDestination: FC<SelectStartingDestinationProps> = ({ content }) => {
    const history = useHistory();
    const location = useLocation<PurchaseState>();
    const { selectedProductGroup: selectedProductGroupId, setSelectedProductGroupParam } = usePathParams();
    const chosenProductGroupId = selectedProductGroupId as string;
    const destinations = useCoverConfig(location.state.selectedProductOption!.representedByCoverCode)?.destinations;

    const [state, send] = useMachine(SelectStartingDestinationMachine, {
        context: {
            selectedProductGrouping: selectedProductGroupId,
            selectedProductOption: location.state.selectedProductOption,
            chosenDestinations: location?.state?.destination?.destinations ?? [],
        },
        actions: {
            redirectToPurchase: () => {
                history.push({
                    pathname: Routes.SELECT_COVER,
                    state: {
                        selectedProductGrouping: null,
                        selectedProductOption: null,
                        coverStartDates: [],
                        destination: null,
                    },
                });
            },
            redirectToSelectDates: (ctx) => {
                // one region for chosen destination
                const chosenDestination = destinations?.find((x) => x.destination === ctx.startDestination) ?? null;
                if (!chosenDestination) return; // TODO
                const { regions } = chosenDestination;
                history.push({
                    pathname: setSelectedProductGroupParam(Routes.SELECT_DATES, chosenProductGroupId),
                    state: {
                        selectedProductGrouping: selectedProductGroupId,
                        selectedProductOption: ctx.selectedProductOption,
                        coverStartDates: [],
                        destination: {
                            destinations: ctx.chosenDestinations,
                            startingDestination: ctx.startDestination,
                            startingRegion: regions[0].region,
                            timezone: regions[0].timezone,
                        },
                    },
                });
            },
            redirectToChooseStartingRegion: (ctx) => {
                // multiple regions
                const chosenDestination = destinations?.find((x) => x.destination === ctx.startDestination) ?? null;
                if (!chosenDestination) return; // TODO

                history.push({
                    pathname: setSelectedProductGroupParam(Routes.SELECT_STARTING_REGION, chosenProductGroupId),
                    state: {
                        selectedProductGrouping: chosenProductGroupId,
                        selectedProductOption: ctx.selectedProductOption,
                        destination: {
                            destinations: ctx.chosenDestinations,
                            startingDestination: ctx.startDestination,
                            startingRegion: '',
                            timezone: '',
                        },
                    },
                });
            },
        },
    });

    const confirmStartingDestination = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        send({ type: 'CONTINUE' });
    };

    useEffect(() => {
        if (!!destinations && destinations.length > 0) {
            send({
                type: 'LOAD_DESTINATIONS',
                data: {
                    availableDestinations: destinations as Destination[],
                },
            });
        }
    }, [send, destinations]);

    return (
        <Layout title={content.heading} showBackButton showLoading={!destinations || state.hasTag('loading')}>
            {!state.hasTag('loading') && !destinations && (
                <Alert
                    type={AlertTypes.ALERT}
                    size={AlertSizes.LARGE}
                    message={ErrorMessages.refreshOrComebackWithApologies}
                />
            )}
            <form onSubmit={confirmStartingDestination}>
                <Fieldset legend={content.heading} visuallyHideLegend>
                    {!!destinations &&
                        destinations.length > 0 &&
                        !state.hasTag('loading') &&
                        destinations
                            .filter((item) => state.context.chosenDestinations.includes(item.destination))
                            .map((item: Destination) => (
                                <div key={item.destination}>
                                    <RadioButton
                                        id={item.destination}
                                        name="destination__option"
                                        className="select__start-destination"
                                        label={item.destination}
                                        onChange={(e) => {
                                            send({
                                                type: 'SELECT_START_DESTINATION',
                                                data: e.target.checked ? item.destination : null,
                                            });
                                        }}
                                        checked={state.context.startDestination === item.destination}
                                    />
                                </div>
                            ))}
                    <Sticky>
                        <Button
                            width="full"
                            type="submit"
                            variant="primary"
                            label={common.continue}
                            disabled={!!state.context.startDestination === false}
                            className="select-destinations__cta"
                        />
                    </Sticky>
                </Fieldset>
            </form>
        </Layout>
    );
};

export default withContent(SelectStartingDestination, contentMap, selectStartingDestinationContent);
