import { FC } from 'react';

import { useUser } from '../../../../business-logic/context-provider/user-context';
import useLazyDependency from '../../../../hooks/lazy-dependency/useLazyDependency';
import common from '../../../../strings/common';
import formatPrice from '../../../../utils/formatPrice';
import BaseBanner from '../base-banner/BaseBanner';

const CreditBanner: FC = () => {
    const creditBalance = useLazyDependency(useUser().creditBalance);

    return (
        <BaseBanner
            text={
                creditBalance.value
                    ? common.formatString(common.creditBanner, {
                          creditBalance: formatPrice(creditBalance.value, true),
                      })
                    : undefined
            }
        />
    );
};

export default CreditBanner;
