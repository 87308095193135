import { SetupIntent, Stripe, StripeElements } from '@stripe/stripe-js';
import asService, { ServiceArgs } from '../utils/asService';

const stripeConfirmSetup = async ({
    stripe,
    elements,
}: {
    stripe: Stripe;
    elements: StripeElements;
} & ServiceArgs): Promise<SetupIntent | undefined> => {
    const { setupIntent, error } = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
    });

    if (error) {
        throw new Error(error.message);
    }

    return setupIntent;
};

export default asService(stripeConfirmSetup);
