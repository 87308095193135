/**
 * Trigger retries up to a specified number upon success or error/rejection for an async function.
 * Automatically retries on failure if failure callback not defined.
 * Will only retry on success if success callback is defined and returns true.
 *
 * @param func the async function
 * @param shouldRetryOnSuccess callback that receives the response from the async function,
 * provide this if you want to retry when the async function has resolved successfully
 * @param shouldRetryOnFailure callback that receives the error caught, provide this if you want to
 * add conditions that determine if this util should try invoking the function again
 * @param numRetries number of retries on failure, default 3 retries
 * @returns func response or throws an error
 */
async function withRetriesAsync<T, U = any>(
    func: () => Promise<T>,
    shouldRetryOnSuccess?: (response: T) => boolean,
    shouldRetryOnFailure?: (error: U) => boolean,
    numRetries = 3,
): Promise<T> {
    try {
        const response = await func();

        if (numRetries > 0 && shouldRetryOnSuccess && shouldRetryOnSuccess(response)) {
            return withRetriesAsync(func, shouldRetryOnSuccess, shouldRetryOnFailure, numRetries - 1);
        }

        return response;
    } catch (e: any) {
        if (numRetries > 0 && (!shouldRetryOnFailure || shouldRetryOnFailure(e))) {
            return withRetriesAsync(func, shouldRetryOnSuccess, shouldRetryOnFailure, numRetries - 1);
        }
        throw e;
    }
}

export default withRetriesAsync;
