import React from 'react';
import classNames from 'classnames';
import { ListItem, ListItemProps } from '../list-item/ListItem';
import './List.scss';

export interface ListProps {
    items: ListItemProps[];
    header?: string;
    innerHeader?: string;
    customInnerHeader?: React.ReactElement;
    className?: string;
}

export const List: React.FC<ListProps> = ({ header, innerHeader, customInnerHeader, items, className }) => {
    return (
        <div className={classNames('list', className)}>
            {header && <h2 className="list__header">{header}</h2>}
            <div className="list__container">
                {innerHeader && <p className="list__inner-header">{innerHeader}</p>}
                {customInnerHeader && customInnerHeader}
                <ul className="list__ul">
                    {items.map((item: ListItemProps) => (
                        <ListItem key={item.id} {...item} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default List;
