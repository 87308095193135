import { FC, useMemo } from 'react';
import { InterpreterFrom, Prop, StateFrom } from 'xstate';
import checkoutMachine from '../../../screens/checkout/checkout-machine/checkoutMachine';
import common from '../../../strings/common';
import LoadingButton, { LoadingButtonProps } from '../../loading-button/LoadingButton';

const PaymentMethodNone: FC<{
    state: StateFrom<typeof checkoutMachine>;
    send: Prop<InterpreterFrom<typeof checkoutMachine>, 'send'>;
}> = ({ state, send }) => {
    const checkoutStatus: LoadingButtonProps['status'] = useMemo(() => {
        if (state.matches('ready.checkout.showCheckoutSuccess') || state.matches('ready.checkout.successRedirect')) {
            return 'success';
        }
        if (state.matches('ready.checkout.initiateCheckout')) {
            return 'loading';
        }
        return 'idle';
    }, [state]);

    return (
        <LoadingButton
            status={checkoutStatus}
            label={common.confirm}
            width="full"
            onClick={() =>
                send({
                    type: 'INITIATE_CHECKOUT',
                    data: {
                        paymentMethodId: undefined,
                        setAsDefault: undefined,
                    },
                })
            }
            type="submit"
        />
    );
};

export default PaymentMethodNone;
