import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, createContext, useContext, useEffect, useRef, useState } from 'react';

import './Zendesk.scss';

declare global {
    interface Window {
        zE?: (...args: unknown[]) => void;
    }
}

interface IZendeskContext {
    /**
     * Is zendesk loaded into the page
     */
    isZendeskLoaded: boolean;
    /**
     * Is zendesk visible to the user
     */
    isZendeskVisible: boolean;
    /**
     * Sets zendesk to be shown to the user
     */
    showZendesk: () => void;
    /**
     * Sets zendesk to display none - completely hide zendesk
     */
    hideZendesk: () => void;
    /**
     * Opens up the zendesk messenger box
     */
    openZendesk: () => void;
    /**
     * Minimise the zendesk messenger box
     */
    minimiseZendesk: () => void;
    /**
     * Set if the current page has nav bar
     * If yes, zendesk widget will offset itself on mobile
     */
    setWithNavBar: (withNavBar: boolean) => void;
}

const ZENDESK_SCRIPT_ID = 'ze-snippet';

// Custom selector for Zendesk Web Messenger as the new Zendesk API doesn't support hiding and showing just yet
const ZENDESK_SELECTOR = 'iframe[data-product="web_widget"] + div';

export const ZendeskContext = createContext({} as IZendeskContext);

export const useZendesk = (): IZendeskContext => {
    const context: IZendeskContext = useContext(ZendeskContext);
    if (typeof context === 'undefined') {
        throw new Error('App Context must be used within the AppProvider');
    }
    return context;
};

const ZendeskProvider = (props: any) => {
    const { zendesk: isZendeskEnabled } = useFlags();
    const [isZendeskLoaded, setIsZendeskLoaded] = useState(document.getElementById(ZENDESK_SCRIPT_ID) !== null);
    const [isZendeskVisible, setIsZendeskVisible] = useState(!!document.querySelector(ZENDESK_SELECTOR));
    const [withNavBar, setWithNavBar] = useState(false);

    const zendeskWidget = useRef<HTMLDivElement | null>(null);

    const setupZendeskWidgetWrapperRef = () => {
        const zendeskWidgetWrapper = document.querySelector(ZENDESK_SELECTOR);

        if (zendeskWidgetWrapper) {
            zendeskWidget.current = zendeskWidgetWrapper as HTMLDivElement;
            setIsZendeskLoaded(true);
        }
    };

    const showZendesk = () => {
        if (!zendeskWidget.current) {
            // Attempt to setup zendesk widget wrapper
            setupZendeskWidgetWrapperRef();
        }

        if (zendeskWidget.current) {
            setIsZendeskVisible(true);
        }

        if (window && window.zE) {
            window.zE('webWidget', 'show');
        }
    };

    const hideZendesk = () => {
        if (!zendeskWidget.current) {
            // Attempt to setup zendesk widget wrapper
            setupZendeskWidgetWrapperRef();
        }

        if (zendeskWidget.current) {
            setIsZendeskVisible(false);
        }

        if (window && window.zE) {
            window.zE('webWidget', 'hide');
        }
    };

    const openZendesk = () => {
        if (!isZendeskVisible) {
            showZendesk();
        }

        if (window && window.zE) {
            window.zE('webWidget', 'open');
        }
    };

    const minimiseZendesk = () => {
        if (window && window.zE) {
            window.zE('webWidget', 'hide');
        }
    };

    const loadZendesk = useCallback(() => {
        const script = document.createElement('script');

        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${import.meta.env.VITE_ZENDESK_KEY}`;
        script.id = 'ze-snippet';
        script.addEventListener('load', () => {
            // The zendesk widget takes some time to load so use Mutation Observer
            // to log when the widget itself is loaded onto the screen
            const targetNode = document.querySelector('body');
            const config = { childList: true };

            const observer = new MutationObserver(() => {
                setupZendeskWidgetWrapperRef();

                if (zendeskWidget.current) {
                    hideZendesk();
                    observer.disconnect();
                }
            });

            // Pre hide widget in case observer doesn't pick up the widget
            if (window && window.zE) {
                window.zE('webWidget', 'hide');
            }

            // Start observing the target node for configured mutations
            // targetNode is body, would never be undefined
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            observer.observe(targetNode!, config);
        });

        document.body.appendChild(script);
    }, []);

    useEffect(() => {
        if (isZendeskEnabled) {
            if (!isZendeskLoaded) {
                loadZendesk();
            } else {
                zendeskWidget.current = document.querySelector(ZENDESK_SELECTOR) as HTMLDivElement;
            }
        }
    }, [isZendeskEnabled, isZendeskLoaded, loadZendesk]);

    useEffect(() => {
        if (zendeskWidget.current) {
            if (withNavBar) {
                zendeskWidget.current.classList.add('zendesk--with-nav-bar');
            } else {
                zendeskWidget.current.classList.remove('zendesk--with-nav-bar');
            }
        }
    }, [withNavBar]);

    const value: IZendeskContext = {
        isZendeskLoaded,
        isZendeskVisible,
        showZendesk,
        hideZendesk,
        openZendesk,
        minimiseZendesk,
        setWithNavBar,
    };

    const { children, ...passThroughProps } = props;

    return (
        <ZendeskContext.Provider value={value} {...passThroughProps}>
            {children}
        </ZendeskContext.Provider>
    );
};

export default ZendeskProvider;
