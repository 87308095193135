enum TimezoneByState {
    'NSW' = 'Australia/Sydney',
    'VIC' = 'Australia/Melbourne',
    'QLD' = 'Australia/Brisbane',
    'TAS' = 'Australia/Hobart',
    'SA' = 'Australia/Adelaide',
    'NT' = 'Australia/Darwin',
    'ACT' = 'Australia/Sydney',
    'WA' = 'Australia/Perth',
}

export default TimezoneByState;
