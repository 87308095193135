import classNames from 'classnames';
import { ChangeEvent, forwardRef } from 'react';
import { ActivityConfig } from '../../business-logic/models/ActivitiesConfig';
import Checkbox from '../form/checkbox/Checkbox';
import TextField, { TextFieldStyle } from '../text-field/TextField';
import activityIcon from '../../assets/images/activities/activity.svg';
import activityInverseIcon from '../../assets/images/activities/activityInverse.svg';

import './ActivitiesList.scss';

export const MIN_FREE_TEXT_LENGTH = 1;
export const MAX_FREE_TEXT_LENGTH = 256;

export type ActivityTileState = {
    selected: boolean;
    freeTextValue: string | null;
};

export interface Activity {
    activityState: ActivityTileState;
    activityConfig: ActivityConfig;
}

export interface ActivityTileProp {
    activityConfig: ActivityConfig;
    activityState: ActivityTileState;
    onChange: (activityId: string, e: ChangeEvent<HTMLInputElement>) => void;
    onFreeTextChange: (activityId: string, e: ChangeEvent<HTMLInputElement>) => void;
}

const ActivityTile = forwardRef<HTMLInputElement, ActivityTileProp>(
    ({ activityConfig, activityState, onChange, onFreeTextChange }, ref) => {
        return (
            <div key={activityConfig.id}>
                <Checkbox
                    id={activityConfig.id}
                    key={activityConfig.id}
                    name="activity"
                    className={classNames(
                        'checkbox--button-style',
                        'activities-list__option',
                        activityState.selected && 'checked',
                    )}
                    label={
                        <div className="activities-list__option__content">
                            {!activityConfig && (
                                <img
                                    src={activityState.selected ? activityInverseIcon : activityIcon}
                                    alt=""
                                    className="activities-list__option__icon"
                                />
                            )}
                            <img
                                src={activityState.selected ? activityConfig.inverseIcon : activityConfig?.icon}
                                alt={activityConfig?.label}
                                className="activities-list__option__icon"
                            />
                            <span className="activities-list__option__label">{activityConfig?.label}</span>
                        </div>
                    }
                    onChange={(e) => onChange(activityConfig.id, e)}
                    checked={activityState.selected}
                />
                <div
                    className={classNames(
                        'activities-list__free-text',
                        activityState.selected && !!activityConfig.freeText && 'activities-list__free-text--show',
                    )}
                >
                    <p className="activities-list__free-text__label">{activityConfig?.freeText?.label}</p>
                    <TextField
                        ref={ref}
                        id="freetext-activity"
                        name="freetext-activity"
                        style={TextFieldStyle.MATERIAL}
                        prefixIcon={activityIcon}
                        placeholder={activityConfig?.freeText?.placeholder}
                        value={activityState.freeTextValue ?? ''}
                        inputMode="text"
                        maxLength={MAX_FREE_TEXT_LENGTH}
                        minLength={MIN_FREE_TEXT_LENGTH}
                        onChange={(e) => {
                            onFreeTextChange(activityConfig.id, e);
                        }}
                    />
                </div>
            </div>
        );
    },
);

export default ActivityTile;
