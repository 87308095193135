const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2,
});

const shortFormatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
});

// how much to charge in the smallest currency unit (e.g., 100 cents to charge $1.00)
const formatPrice = (price: number, short?: boolean): string => {
    return short ? shortFormatter.format(price / 100) : formatter.format(price / 100);
};

export default formatPrice;
