import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';
import CustomerResponse, { CustomerResponseBankAccount } from '../../business-logic/models/CustomerResponse';
import transformCustomerResponse, { CustomerDetails } from './transformCustomerResponse';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const updateCustomerBankAccount = async ({
    accessToken,
    axios,
    bankAccount,
}: {
    accessToken: string;
    bankAccount: CustomerResponseBankAccount;
} & ServiceArgs): Promise<CustomerDetails> => {
    const { data } = await axios.put<CustomerResponse>(
        `${baseApiPath}/api/v1/customer/bank-account`,
        bankAccount,
        toHeaders(accessToken),
    );

    return transformCustomerResponse(data);
};

export default asService(updateCustomerBankAccount);
