import ProductResponse from '../../business-logic/models/ProductResponse';
import asService, { ServiceArgs } from '../utils/asService';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const getProducts = async ({
    insuranceProductId,
    pdsVersion,
    filterTestProducts,
    fetchAllProducts,
    axios,
}: {
    insuranceProductId?: string;
    pdsVersion?: string;
    filterTestProducts?: boolean;
    fetchAllProducts?: boolean;
} & ServiceArgs): Promise<ProductResponse[]> => {
    const { data } = await axios.get<{ products: ProductResponse[] }>(
        `${baseApiPath}/api/v1/pub/bff-products/products`,
        {
            params: {
                status: fetchAllProducts ? 'all' : undefined,
                insuranceProductId,
                pdsVersion,
            },
        },
    );
    return filterTestProducts ? data.products.filter((product) => !product.productName.match(/test/i)) : data.products;
};

export default asService(getProducts);
