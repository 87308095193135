import CustomerResponse from '../../business-logic/models/CustomerResponse';
import UserDetails from '../../business-logic/models/UserDetails';
import UserPreferences from '../../business-logic/models/UserPreferences';
import State from '../../utils/constants/State';

export interface CustomerDetails {
    userDetails: UserDetails;
    userPreferences: UserPreferences;
    variables: Record<string, unknown>;
}

const transformCustomerResponse = (customerResponse: CustomerResponse): CustomerDetails => {
    const userDetails: UserDetails = {
        id: customerResponse.identityId,
        personId: customerResponse.userId,
        customerNumber: customerResponse.customerNumber.toString(),
        firstName: customerResponse.profile.firstName || undefined,
        lastName: customerResponse.profile.lastName || undefined,
        email: customerResponse.email,
        dob: customerResponse.profile.dateOfBirth || undefined,
        gender: customerResponse.profile.gender || undefined,
        bankDetails: {
            accountName: customerResponse.bankAccounts.length ? customerResponse.bankAccounts[0].accountName : null,
            accountNumber: customerResponse.bankAccounts.length ? customerResponse.bankAccounts[0].accountNumber : null,
            bsb: customerResponse.bankAccounts.length ? customerResponse.bankAccounts[0].bsb : null,
        },
        state: (customerResponse.profile.state?.toUpperCase() as State) || undefined,
        residencyStatusType: customerResponse.profile.residencyStatusType || [],
        activities: customerResponse.profile.activities || [],
    };

    const { reminders, productSurveys, promotions, policyUpdates, accountUpdates } = customerResponse.communication;

    const userPreferences: UserPreferences = {
        reminders,
        productSurveys,
        guidesAndPromotions: promotions,
        policyAndClaimUpdates: policyUpdates,
        accountUpdates,
    };

    return { userDetails, userPreferences, variables: customerResponse.variables };
};

export default transformCustomerResponse;
