import LocalizedStrings from 'react-localization';

const kidDetailsFormContent = new LocalizedStrings({
    en: {
        firstName: {
            label: 'First name',
        },
        lastName: {
            label: 'Last name',
        },
        gender: {
            label: 'Select gender',
        },
        dropdownPlaceholder: 'Please select',
        dobError: {
            errorInvalidDate: 'Sorry, the date doesn’t look right',
            errorMustBeOver18: 'Sorry, your kid needs to be 5 or over to get covered with Flip Kids insurance',
            errorMustBeUnder65: 'Sorry, your kid needs to be 17 or under to get covered with Flip Kids insurance',
        },
    },
});

export default kidDetailsFormContent;
