const defaultClassNames = {
    container: 'DayPicker',
    wrapper: 'DayPicker-wrapper',
    interactionDisabled: 'DayPicker--interactionDisabled',
    months: 'DayPicker-Months',
    month: 'DayPicker-Month',

    navBar: 'DayPicker-NavBar',
    navButtonPrev: 'DayPicker-NavButton DayPicker-NavButton--prev',
    navButtonNext: 'DayPicker-NavButton DayPicker-NavButton--next',
    navButtonInteractionDisabled: 'DayPicker-NavButton--interactionDisabled',

    caption: 'DayPicker-Caption',
    weekdays: 'DayPicker-Weekdays',
    weekdaysRow: 'DayPicker-WeekdaysRow',
    weekday: 'DayPicker-Weekday',
    body: 'DayPicker-Body',
    week: 'DayPicker-Week',
    weekNumber: 'DayPicker-WeekNumber',
    day: 'DayPicker-Day',
    footer: 'DayPicker-Footer',
    todayButton: 'DayPicker-TodayButton',

    // default modifiers
    today: 'today',
    selected: 'selected',
    disabled: 'disabled',
    outside: 'outside',
};

export default defaultClassNames;
