import { PurchaseEntryMachineContextTypes } from '../context/purchaseEntryMachineContext';

export const isValidProductGroup = (ctx: PurchaseEntryMachineContextTypes): boolean => {
    return (
        ctx.selectedProductGroupingParam !== undefined &&
        !!ctx.productGroups.find((group) => group.id.toLowerCase() === ctx.selectedProductGroupingParam!.toLowerCase())
    );
};

export const isProductOptionSelectionRequired = (ctx: PurchaseEntryMachineContextTypes): boolean => {
    // Asserted to be not undefined via state this is invoked in
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return ctx.selectedProductGrouping!.options.length > 1;
};

export const isDestinationSelectionRequired = (ctx: PurchaseEntryMachineContextTypes): boolean => {
    // Asserted to be not undefined via state this is invoked in
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return ctx.selectedProductOption!.requireDestinations === true;
};
