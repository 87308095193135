import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import format from 'date-fns/format';
import DateFormat from './constants/DateFormat';

// Specifically converts dates from ISO8601 format to date strings
// displaying at preferred format
export default (
    dateString: string,
    timeZone: string,
    dateFormat: DateFormat = DateFormat.DEFAULT_AND_TIMEZONE,
): string => {
    const zonedDate = utcToZonedTime(dateString, timeZone);
    return format(zonedDate, dateFormat);
};
