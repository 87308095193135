import { CoverInformationV2 } from '../business-logic/models/cdn-content/CoverInformation';
import { useProduct } from '../business-logic/context-provider/ProductContext';

/**
 * Returns CoverInformationV2 content for a given cover code.
 * Only works for main cover for now.
 * If PDS is undefined, will return version available for purchase, otherwise null.
 *
 * @param coverCode Cover code to get content for
 * @param PDSVersion PDSVersion of cover code to get content for
 *
 * @returns CoverInformationV2 if content exists, null if content not found
 */
const useCoverInformation = (coverCode: string, PDSVersion?: string): CoverInformationV2 | null => {
    const { coverInformation, products } = useProduct();

    const contentByCoverCode = coverInformation[coverCode];

    if (contentByCoverCode === undefined) {
        // No content for given cover code
        return null;
    }

    // If specific PDS version is requested
    if (PDSVersion !== undefined) {
        const contentByPDSVersion = contentByCoverCode[PDSVersion];

        if (contentByPDSVersion === undefined) {
            // No content for given PDSVersion
            return null;
        }

        return contentByPDSVersion;
    }

    const correspondingProductOnSale = products.find((p) => p.productSpec.mainCoverType.coverCode === coverCode);

    // No corresponding product on sale
    if (correspondingProductOnSale === undefined) {
        return null;
    }

    const pdsVersionOnSale = `${correspondingProductOnSale.pdsVersionMajor}.${correspondingProductOnSale.pdsVersionMinor}`;

    // If PDS version on sale has no corresponding content
    if (coverInformation[coverCode] === undefined || coverInformation[coverCode][pdsVersionOnSale] === undefined) {
        return null;
    }

    return coverInformation[coverCode][pdsVersionOnSale];
};

export default useCoverInformation;
