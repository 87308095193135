import { FC } from 'react';
import AnnouncementKeys from './AnnouncementKeys';
import MobileAppLaunchAnnouncement from './mobile-app-launch-announcement/MobileAppLaunchAnnouncement';

interface AnnouncementsProps {
    showAnnouncement: AnnouncementKeys;
}

const Announcements: FC<AnnouncementsProps> = ({ showAnnouncement }) => {
    if (showAnnouncement === AnnouncementKeys.MOBILE_APP_LAUNCH) {
        return <MobileAppLaunchAnnouncement />;
    }
    return null;
};

export default Announcements;
