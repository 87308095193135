import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import tick from '../../assets/images/toast-tick.svg';
import cross from '../../assets/images/toast-cross.svg';

import './Toast.scss';

export interface ToastProps {
    variant?: 'default' | 'success' | 'error';
    message: string | ReactNode;
}

const Toast: FC<ToastProps> = ({ variant, message }) => (
    <div
        className={classNames('toast', {
            'toast--success': variant === 'success',
            'toast--error': variant === 'error',
        })}
    >
        <img src={variant === 'error' ? cross : tick} alt="" className="toast__icon" />
        {message}
    </div>
);

Toast.defaultProps = {
    variant: 'default',
};

export default Toast;
