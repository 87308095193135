import { ChangeEvent, useReducer } from 'react';
import { useUser } from '../../../business-logic/context-provider/user-context';
import yourDetails from '../../../content/ui/screens/your-details/yourDetails';
import isNan from '../../../utils/isNan';
import toDate from '../../../utils/toDate';
import toOption from '../../../utils/toOption';
import getAge from '../../../validation/getAge';
import isValidDate from '../../../validation/isValidDate';
import { YourDetailsDispatchAction, YourDetailsFormState } from './YourDetailsTypes';

const useYourDetails = () => {
    const { userDetails } = useUser();
    const date = toDate(userDetails.dob);
    const {
        gender: { options },
    } = yourDetails;

    const initialState = {
        firstName: userDetails.firstName || '',
        lastName: userDetails.lastName || '',
        gender: toOption(options, userDetails.gender),
        dobDay: !isNan(date.day) ? date.day : '',
        dobMonth: !isNan(date.month) ? date.month : '',
        dobYear: !isNan(date.year) ? date.year : '',
        dobError: '',
    };
    const reducer = (state: YourDetailsFormState, action: YourDetailsDispatchAction): YourDetailsFormState => {
        switch (action.type) {
            case 'SET_FIRST_NAME':
                return { ...state, firstName: action.payload };
            case 'SET_LAST_NAME':
                return { ...state, lastName: action.payload };
            case 'SET_DAY_OF_BIRTH':
                return { ...state, dobDay: action.payload };
            case 'SET_MONTH_OF_BIRTH':
                return { ...state, dobMonth: action.payload };
            case 'SET_YEAR_OF_BIRTH':
                return { ...state, dobYear: action.payload };
            case 'SET_GENDER':
                return { ...state, gender: action.payload };
            case 'SET_DATE_OF_BIRTH_ERROR':
                return { ...state, dobError: action.payload };
            default:
                throw new Error();
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    const { dobDay, dobMonth, dobYear } = state;

    const isFormatValid = isValidDate(dobDay, dobMonth, dobYear);
    const isNotEmpty = dobDay.length > 0 && dobMonth.length > 0 && dobYear.length === 4;
    const isValidAge = getAge(dobDay, dobMonth, dobYear) < 120 && getAge(dobDay, dobMonth, dobYear) > 0;
    const isValidDateOfBirth = isNotEmpty && isFormatValid && isValidAge;

    const handleDateValidation = () => {
        dispatch({ type: 'SET_DATE_OF_BIRTH_ERROR', payload: '' });
        if (!isNotEmpty) return;

        if (!isFormatValid || !isValidDateOfBirth) {
            dispatch({ type: 'SET_DATE_OF_BIRTH_ERROR', payload: yourDetails.dateOfBirth.errorInvalidDate });
        }
    };

    const handleDateOnBlur = () => {
        handleDateValidation();
    };

    const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: 'SET_FIRST_NAME', payload: e.target.value });
    };

    const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: 'SET_LAST_NAME', payload: e.target.value });
    };

    const handleGenderDropDown = (value: any) => {
        dispatch({ type: 'SET_GENDER', payload: value });
    };

    const handleDayChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: 'SET_DAY_OF_BIRTH', payload: e.target.value });
        handleDateValidation();
    };

    const handleMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: 'SET_MONTH_OF_BIRTH', payload: e.target.value });
        handleDateValidation();
    };

    const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: 'SET_YEAR_OF_BIRTH', payload: e.target.value });
        handleDateValidation();
    };

    return {
        state,
        handleYearChange,
        handleMonthChange,
        handleDayChange,
        handleDateOnBlur,
        handleGenderDropDown,
        handleLastNameChange,
        handleFirstNameChange,
    };
};

export default useYourDetails;
