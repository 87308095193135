import ProductResponse from '../business-logic/models/ProductResponse';
import CoverCode from './constants/CoverCode';

export default function getPdsVersion({
    products,
    coverCode,
}: {
    products: ProductResponse[];
    coverCode: string;
}): string | null {
    const selectedProduct = products.find((x) => x.productSpec.mainCoverType.coverCode === coverCode);
    if (!selectedProduct) return null;
    return `${selectedProduct?.pdsVersionMajor}.${selectedProduct?.pdsVersionMinor}`;
}
