export const SCHEDULED_FOR = {
    TODAY: 'Today',
    FUTURE: 'Future',
};

export const CONTEXT_TYPE = {
    PARTNER_EVENT: 'partner_event',
};

export default {
    SCHEDULED_FOR,
    CONTEXT_TYPE,
};
