import { useEffect } from 'react';
import LazyDependency from '../../business-logic/models/LazyDependency';

/**
 * Use this hook to access the value of a `LazyDependency` where it is needed.
 * On mount this hook will check if the dependency data has been fetched, and go
 * fetch it if it hasn't.
 *
 * @param lazyDependency `LazyDependency`
 * @returns `LazyDependency`
 */
const useLazyDependency = <T>(lazyDependency: LazyDependency<T>, shouldFetch = true): LazyDependency<T> => {
    useEffect(() => {
        if (lazyDependency.value === undefined && shouldFetch) {
            lazyDependency.fetch();
        }
    }, []);

    return lazyDependency;
};

export default useLazyDependency;
