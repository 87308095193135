import { CustomerResponseCommunication } from '../../business-logic/models/CustomerResponse';
import UserPreferences from '../../business-logic/models/UserPreferences';

const transformCommunicationPreferences = ({
    reminders,
    productSurveys,
    guidesAndPromotions,
    policyAndClaimUpdates,
    accountUpdates,
}: UserPreferences): CustomerResponseCommunication => {
    return {
        reminders,
        productSurveys,
        promotions: guidesAndPromotions,
        policyUpdates: policyAndClaimUpdates,
        accountUpdates,
    };
};

export default transformCommunicationPreferences;
