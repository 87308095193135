import LocalizedStrings from 'react-localization';

const alertErrorMessages = new LocalizedStrings({
    en: {
        tryAgainOrContactUs: 'Sorry something went wrong at our end. {tryAgain} or {contactUs}.',
        refreshOrContactUs: 'Sorry something went wrong at our end. {refresh} or {contactUs}',
        refreshOrComeback: 'It looks like somethings gone wrong. {refresh} or {comebackLater}.',
        refreshOrComebackWithApologies: 'Sorry something went wrong at our end. {refresh} or {comebackLater}.',
        contactUs: 'Contact us',
        refresh: 'Try refreshing your page',
        comebackLater: 'come back later',
    },
});

export default alertErrorMessages;
