import './ManageCoverCard.scss';

interface ManageCoverEmptyStateProps {
    name: string;
    isActive: boolean;
}

const ManageCoverEmptyState = ({ name, isActive }: ManageCoverEmptyStateProps) => {
    return (
        <div className="manage-cover-card--empty">
            {name} no {isActive ? 'scheduled cover' : 'cover history'}
        </div>
    );
};

export default ManageCoverEmptyState;
