import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isSameDay from 'date-fns/isSameDay';
import parse from 'date-fns/parse';
import { CheckoutDetailsResponse } from '../business-logic/models/CheckoutDetails';
import DateFormat from '../utils/constants/DateFormat';
import { Route } from '../utils/Routes';
import { InvoiceType } from './Analytics.d';
import { SCHEDULED_FOR } from './AnalyticsConstants';
import PageEvents from './PageEvents';

export const getAnalyticsPageName = (route?: Route): string => {
    const pathname = route || window?.location.pathname;
    if (pathname) {
        const pageEventItem = PageEvents[pathname as Route];
        return pageEventItem ? pageEventItem[1] : '';
    }
    return '';
};

export const getScheduledForValue = (daysToSchedule: string[], userTimeZone: string): string[] => {
    const hasToday = daysToSchedule.find((day) =>
        isSameDay(utcToZonedTime(new Date(), userTimeZone), parse(day, DateFormat.DEFAULT, new Date())),
    );

    const hasFuture = daysToSchedule.find(
        (day) => !isSameDay(utcToZonedTime(new Date(), userTimeZone), parse(day, DateFormat.DEFAULT, new Date())),
    );

    const returnValue = [];

    if (hasToday) {
        returnValue.push(SCHEDULED_FOR.TODAY);
    }

    if (hasFuture) {
        returnValue.push(SCHEDULED_FOR.FUTURE);
    }

    return returnValue;
};

export const getStartingInDays = (daysToSchedule: string[], userTimeZone: string): number[] => {
    const zonedToday = utcToZonedTime(new Date(), userTimeZone);

    return daysToSchedule.map((day) =>
        differenceInCalendarDays(parse(day, DateFormat.DEFAULT, new Date()), zonedToday),
    );
};

export const getInvoiceType = (checkoutDetails: CheckoutDetailsResponse): InvoiceType => {
    if (checkoutDetails.invoice.amountDue === 0) {
        return 'free';
    }
    if (checkoutDetails.invoice.balanceApplied > 0 || checkoutDetails.invoice.couponAmountOff > 0) {
        return 'discounted';
    }
    return 'full price';
};
