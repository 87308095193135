import PriceLineItem from '../price-line-item/PriceLineItem';
import paymentsStrings from '../../../../strings/payments';
import formatPrice from '../../../../utils/formatPrice';
import { CheckoutDetailsResponse } from '../../../../business-logic/models/CheckoutDetails';
import { ListItemProps } from '../../../../components/list-item/ListItem';

export default (checkoutDetails: CheckoutDetailsResponse): ListItemProps => ({
    id: 'amount-due',
    customDataElement: (
        <PriceLineItem description={paymentsStrings.total} amount={formatPrice(checkoutDetails.invoice.amountDue)} />
    ),
});
