import { createMachine } from 'xstate';
import { ReviewCoverMachineContextTypes } from '../context/reviewCoverMachineContext';
import { CartErrorDisplay, CartInstanceResponse } from '../../../../../business-logic/models/Cart';
import {
    trackProductsSelected,
    setCartTotal,
    setCoversToDisplay,
    setCartError,
    resetCartError,
} from '../actions/reviewCoverMachineActions';

const ReviewCoverMachine = createMachine(
    {
        id: 'ReviewCoverMachine',
        initial: 'createCart',
        schema: {
            context: {} as ReviewCoverMachineContextTypes,
            events: {} as
                | { type: 'CART_TRY_AGAIN' }
                | { type: 'CART_CREATED'; data: CartInstanceResponse }
                | { type: 'CART_CREATE_ERROR'; data: CartErrorDisplay },
        },
        context: {
            cartError: null,
            purchaseState: null,
            products: [],
            productGroups: [],
            coverInformation: null,
            userTimeZone: '',
            insuredPersons: {
                accountHolder: false,
                dependants: [],
            },
            cartTotal: 0,
            cartItemsByCoverCode: [],
        },
        tsTypes: {} as import('./reviewCoverMachine.typegen').Typegen0,
        preserveActionOrder: true,
        states: {
            createCart: {
                entry: ['openCart'],
                tags: ['initialising'],
                on: {
                    CART_TRY_AGAIN: {
                        actions: ['resetCartError'],
                        target: 'createCart',
                    },
                    CART_CREATE_ERROR: {
                        actions: ['setCartError'],
                        target: 'displayCartError',
                    },
                    CART_CREATED: {
                        actions: ['setCoversToDisplay', 'setCartTotal', 'trackProductsSelected'],
                        target: 'readyToContinue',
                    },
                },
            },
            readyToContinue: {
                type: 'final',
            },
            displayCartError: {
                tags: ['error'],
                on: {
                    CART_TRY_AGAIN: {
                        actions: ['resetCartError'],
                        target: 'createCart',
                    },
                },
            },
        },
    },
    {
        actions: {
            trackProductsSelected,
            setCartError,
            resetCartError,
            setCartTotal,
            setCoversToDisplay,
        },
    },
);

export default ReviewCoverMachine;
