import axios from 'axios';
import { AllCoverConfig } from '../../business-logic/models/CoverConfig';
import asService from '../utils/asService';
import isAxios404Error from '../utils/isAxios404Error';

const baseApiPath = import.meta.env.VITE_BASE_CDN_PATH;

const getCoverConfig = async (): Promise<AllCoverConfig> => {
    try {
        const { data } = await axios.get<AllCoverConfig>(`${baseApiPath}/json/coverConfig.json`);
        return data;
    } catch (error) {
        if (isAxios404Error(error)) {
            throw new Error(`Unable to retrieve cover config from Flip content delivery network.`);
        }
        throw new Error(`Error retrieving cover config from Flip content delivery network`);
    }
};

export default asService(getCoverConfig, false);
