import { SecureRoute } from '@okta/okta-react';
import { OnAuthRequiredFunction } from '@okta/okta-react/bundles/types/OktaContext';
import { useEffect } from 'react';
import * as ReactRouterDom from 'react-router-dom';
import Analytics from '../../analytics/Analytics';
import PageEvents from '../../analytics/PageEvents';
import { Route } from '../../utils/Routes';

const TrackedSecureRoute = (
    props: {
        onAuthRequired?: OnAuthRequiredFunction;
        errorComponent?: React.ComponentType<{ error: Error }>;
    } & ReactRouterDom.RouteProps,
) => {
    const match = ReactRouterDom.useRouteMatch(props);

    useEffect(() => {
        if (match != null) {
            const pageEvent = PageEvents[match?.path as Route];
            if (pageEvent) {
                Analytics.identify();
                Analytics.trackScreen(pageEvent);
            }
        }
    }, [match, match?.path]);
    return <SecureRoute {...props} />;
};

export default TrackedSecureRoute;
