import LocalizedStrings from 'react-localization';
import ExternalLinks from '../utils/constants/ExternalLinks';

const oktaFlow = new LocalizedStrings({
    en: {
        registration: {
            formTitle: 'Get started with Flip',
            formSubmit: 'Continue',
            passwordMinLength: 'Your password needs to be at least 8 characters long',
            invalidEmail: 'Sorry, that doesn’t look right',
            passwordExcludeUsername: 'Does not contain part of email',
            formDescription: 'Open a free account in 2 minutes. Flip on cover whenever you need. ',
            formPrivacyPolicy: `By continuing you agree to the <a href="${ExternalLinks.termsAndConditions}" target="_blank">Flip Terms of Use</a> and <a href="${ExternalLinks.privacyPolicy}" target="_blank">Privacy Policy</a>.`,
            referralVerificationSuccess: 'Complete sign up to get 3 weeks free',
            referralVerificationError: 'Sorry your referral link is invalid',
            alreadyHaveAnAccount: 'Already have an account? {loginHere}',
            loginHere: 'Log in here.',
            continueWithEmail: 'Continue with email',
        },
        login: {
            sendEmailCode: 'Send sign in code',
            enterCode: 'Enter code',
            forgotPassword: 'Forgot password?',
        },
        error: {
            usernameRequired: 'Please enter an email',
            passwordNoUsername: 'no parts of your email',
            passwordNoUsernameDescription: 'No parts of your email',
        },
    },
});

export default oktaFlow;
