import { useOktaAuth } from '@okta/okta-react';
import { useQuery } from '@tanstack/react-query';
import { useMachine } from '@xstate/react';
import { ChangeEvent, useEffect, useRef } from 'react';
import activitiesContent from '../../content/ui/screens/activities/activities';
import CdnService from '../../services/cdn-service/CdnService';
import withRetriesAsync from '../../services/utils/withRetriesAsync';
import commonStrings from '../../strings/common';
import parseString from '../../utils/parseString';
import ActivitiesList from '../activities-list/ActivitiesList';
import Alert, { AlertSizes, AlertTypes } from '../alert/Alert';
import LoadingSpinnerOverlay from '../loading-spinner-overlay/LoadingSpinnerOverlay';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import activitiesSelectionMachine from './activities-selection-machine/activitiesSelectionMachine';

interface ActivitiesSelectionProps {
    onActivitiesComplete: (activities: string[]) => void;
    updateProfile: boolean;
}
const ActivitiesSelection = ({ onActivitiesComplete, updateProfile }: ActivitiesSelectionProps) => {
    const { authState } = useOktaAuth();
    const freeTextRef = useRef<HTMLInputElement | null>(null);

    const activities = useQuery({
        queryKey: ['activities'],
        queryFn: () => withRetriesAsync(() => CdnService.getActivitiesConfig({})),
    });

    const [state, send] = useMachine(activitiesSelectionMachine, {
        context: {
            accessToken: authState?.accessToken?.accessToken ?? '',
            updateProfile,
        },
        actions: {
            notifyActivitiesUpdateComplete: (ctx) => {
                const activitiesToSubmit = ctx.activities
                    .filter((x) => x.activityState.selected)
                    .map((y) => {
                        return !!y.activityConfig.freeText && y.activityState.freeTextValue
                            ? y.activityState.freeTextValue
                            : y.activityConfig.label;
                    });
                onActivitiesComplete(activitiesToSubmit);
            },
        },
    });
    useEffect(() => {
        if (activities.isSuccess) {
            send({ type: 'UPDATE_CONTEXT', data: activities.data });
        }
    }, [activities, send]);

    if (state.hasTag('initialising')) {
        return <LoadingSpinnerOverlay />;
    }
    return (
        <div>
            <p className="activities-selection__summary">
                {state.context.updateProfile
                    ? parseString(activitiesContent.signUp)
                    : parseString(activitiesContent.purchase)}
            </p>
            {activities.error && (
                <Alert
                    className="activities-selection__alert"
                    message={commonStrings.errorSomethingWentWrongTryAgain}
                    type={AlertTypes.ERROR}
                    size={AlertSizes.LARGE}
                    cta={{
                        variant: 'primary',
                        width: 'full',
                        size: 'small',
                        label: 'Try again',
                        onClick: () => {
                            activities.refetch();
                        },
                        type: 'button',
                    }}
                />
            )}
            {!activities.error && activities.isLoading && <LoadingSpinner />}
            <ActivitiesList
                displayActivities={state.context.activities}
                onValueChange={(activityId: string, e: ChangeEvent<HTMLInputElement>) => {
                    if (activities?.data?.find((x) => x.id === activityId && x.freeText !== undefined)) {
                        setTimeout(() => {
                            freeTextRef!.current!.scrollIntoView({
                                behavior: 'smooth',
                            });
                            freeTextRef!.current!.focus();
                        }, 100);
                    }
                    send({
                        type: 'TOGGLE_ACTIVITY_SELECTION',
                        data: {
                            activityId,
                            selected: e.target.checked,
                        },
                    });
                }}
                onFreeTextChange={(activityId: string, e: ChangeEvent<HTMLInputElement>) => {
                    send({
                        type: 'FREETEXT_UPDATE',
                        data: {
                            activityId,
                            freeTextValue: e.target.value,
                        },
                    });
                }}
                handleContinueClick={() => {
                    send('CONFIRM_ACTIVITIES');
                }}
                error={state.hasTag('error') ? commonStrings.errorSomethingWentWrongTryAgain : ''}
                disableContinue={
                    state.context.activities.filter((x) => x.activityState.selected).length === 0 ||
                    !!state.context.activities.find(
                        (x) =>
                            x.activityConfig.freeText !== undefined &&
                            x.activityState.selected &&
                            !x.activityState.freeTextValue,
                    )
                }
                freeTextRef={freeTextRef}
            />
        </div>
    );
};

export default ActivitiesSelection;
