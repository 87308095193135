import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import './TabButton.scss';
import usePrevious from '../../hooks/usePrevious';

export interface TabButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    id: string;
    label: string;
    onClick: () => void;
    isActive?: boolean;
}

export const TabButton: React.FC<TabButtonProps> = ({ id, label, onClick, isActive, ...props }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const previouslyFocused = usePrevious(isActive);

    useEffect(() => {
        // If currently active and previously not focused, force focus
        // This happens when the active tab is changed via keyboard navigation
        if (isActive === true && previouslyFocused === false && buttonRef.current) {
            buttonRef.current.focus();
        }
    }, [isActive, previouslyFocused]);

    return (
        <button
            ref={buttonRef}
            role="tab"
            aria-selected={isActive ? 'true' : 'false'}
            aria-controls={`tabpanel-${id}`}
            tabIndex={isActive ? 0 : -1}
            type="button"
            className={classNames('tab-button', isActive && 'active')}
            onClick={onClick}
            {...props}
        >
            {label}
        </button>
    );
};

export default TabButton;
