import React, { CSSProperties } from 'react';
import Lottie, { EventListener } from 'react-lottie';
import successTick from '../../assets/lotties/success-tick.json';

export interface SuccessTickProps {
    size?: number | string;
    loop?: boolean;
    eventListeners?: EventListener[];
    style?: CSSProperties;
}

const SuccessTick: React.FC<SuccessTickProps> = ({ size, loop, eventListeners, style }) => (
    <Lottie
        options={{ animationData: successTick, loop }}
        height={size}
        width={size}
        ariaRole="alert"
        ariaLabel="Success"
        eventListeners={eventListeners}
        style={style}
    />
);

SuccessTick.defaultProps = {
    size: 156,
    loop: false,
};

export default SuccessTick;
