import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useEffect, useState } from 'react';
import appStoreText from '../../../assets/images/app-store-text.svg';
import appStore from '../../../assets/images/app-store.svg';
import playStoreText from '../../../assets/images/play-store-text.svg';
import playStore from '../../../assets/images/play-store.svg';
import { useUser } from '../../../business-logic/context-provider/user-context';
import mobileAppLaunchAnnouncement from '../../../content/ui/components/announcements/mobile-app-launch-announcement/mobileAppLaunchAnnouncement';
import withContent from '../../../hoc/with-content/withContent';
import ExternalLinks from '../../../utils/constants/ExternalLinks';
import LinkButton from '../../link-button/LinkButton';
import BaseAnnouncement from '../base-announcement/BaseAnnouncement';
import shouldShowAnnouncement from './utils/shouldShowAnnouncement';
import updateAnnouncementViewCount from './utils/updateAnnouncementViewCount';

import './MobileAppLaunchAnnouncement.scss';

const contentMap = {
    title: 'ui.title',
    description: 'ui.description',
    notNowCTA: 'ui.notNowCTA',
    getTheAppCTA: 'ui.getTheAppCTA',
    appStoreActionText: 'ui.appStoreActionText',
    appStoreStoreName: 'ui.appStoreStoreName',
    playStoreActionText: 'ui.playStoreActionText',
    playStoreStoreName: 'ui.playStoreStoreName',
};

interface MobileAppLaunchAnnouncementProps {
    content: Record<keyof typeof contentMap, string>;
}

const MobileAppLaunchAnnouncement: FC<MobileAppLaunchAnnouncementProps> = ({ content }) => {
    const { variables, setUserVariables, accessToken } = useUser();

    const { showMobileAppLaunchAnnouncementBanner } = useFlags();
    const [isShowing, setIsShowing] = useState(false);

    const shouldShow = shouldShowAnnouncement(variables, showMobileAppLaunchAnnouncementBanner);

    const handleGetApp = () => {
        setIsShowing(false);
        // For a user who has clicked on get app, set the view count to max views ie don't show again
        updateAnnouncementViewCount(variables, accessToken!, setUserVariables!, true);
    };

    const handleClose = () => {
        setIsShowing(false);
    };

    useEffect(() => {
        if (shouldShow) {
            // Add a delay before showing pop up to draw attention with animation
            setTimeout(() => {
                setIsShowing(true);
            }, 500);

            updateAnnouncementViewCount(variables, accessToken!, setUserVariables!);
        }
    }, []);

    const renderButtonContent = (imageSrc: string, textImageSrc: string, accessibleText: string) => {
        return (
            <>
                <img src={imageSrc} alt="" className="mobile-app-launch-announcement__btn-icon" />
                <img src={textImageSrc} alt="" />
                <span className="mobile-app-launch-announcement__btn-hidden-text">{accessibleText}</span>
            </>
        );
    };

    return (
        <BaseAnnouncement isShowing={isShowing} showCloseButton onClose={handleClose}>
            <p className="mobile-app-launch-announcement__title">{content.title}</p>
            <div className="mobile-app-launch-announcement__content">
                <p className="mobile-app-launch-announcement__desc">{content.description}</p>
                <div className="mobile-app-launch-announcement__button-row">
                    <LinkButton
                        label={renderButtonContent(
                            appStore,
                            appStoreText,
                            `${content.appStoreActionText} ${content.appStoreStoreName}`,
                        )}
                        href={ExternalLinks.mobileAppAppleAppStore}
                        onClick={() => handleGetApp()}
                        target="_blank"
                        variant="invert"
                    />
                    <LinkButton
                        label={renderButtonContent(
                            playStore,
                            playStoreText,
                            `${content.playStoreActionText} ${content.playStoreStoreName}`,
                        )}
                        href={ExternalLinks.mobileAppGooglePlayStore}
                        onClick={() => handleGetApp()}
                        target="_blank"
                        variant="invert"
                    />
                </div>
            </div>
        </BaseAnnouncement>
    );
};

export default withContent(MobileAppLaunchAnnouncement, contentMap, mobileAppLaunchAnnouncement);
