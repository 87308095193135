import { createContext, FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import FadeInUpTransitionGroup from '../transitions/fade-in-up/FadeInUpTransitionGroup';
import Toast, { ToastProps } from './Toast';

import './Toast.scss';

export interface IToastContext {
    triggerToast: (toastProp: ToastProps, removeAfter?: number) => void;
}

export const ToastContext = createContext<any>(undefined);

const ToastProvider: FC = (props) => {
    const { children, ...passThroughProps } = props;
    const [toasts, setToasts] = useState<(ToastProps & { key: string })[]>([]);

    const triggerToast = (toastProp: ToastProps, removeAfter = 2000) => {
        // Create unique key
        const key = uuidv4();

        // Add toast
        setToasts((prevToasts) => [...prevToasts, { ...toastProp, key }]);

        // Set to remove toast after 2 seconds
        setTimeout(() => {
            setToasts((prevToasts) => prevToasts.filter((toast) => toast.key !== key));
        }, removeAfter);
    };

    return (
        <ToastContext.Provider value={{ triggerToast }} {...passThroughProps}>
            {children}
            <div className="toast__wrapper">
                <FadeInUpTransitionGroup>
                    {toasts.map((toast) => (
                        <Toast {...toast} />
                    ))}
                </FadeInUpTransitionGroup>
            </div>
        </ToastContext.Provider>
    );
};

export default ToastProvider;
