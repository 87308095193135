import { assign } from 'xstate';
import { ActivitiesSelectionMachineContextTypes } from '../context/ActivitiesSelectionMachineContext';
import { ActivityConfig } from '../../../business-logic/models/ActivitiesConfig';
import { Typegen0 } from '../activities-selection-machine/activitiesSelectionMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setActivitySelection = assign<
    ActivitiesSelectionMachineContextTypes,
    {
        type: EventsCausingActions['setActivitySelection'];
        data: { activityId: string; selected: boolean };
    }
>({
    activities: (ctx, event) =>
        ctx.activities.map((x) => {
            return {
                ...x,
                activityState: {
                    ...x.activityState,
                    selected:
                        x.activityConfig.id === event.data.activityId ? event.data.selected : x.activityState.selected,
                },
            };
        }),
}) as any;

export const setActivityFreeText = assign<
    ActivitiesSelectionMachineContextTypes,
    {
        type: EventsCausingActions['setActivityFreeText'];
        data: { activityId: string; freeTextValue: string };
    }
>({
    activities: (ctx, event) =>
        ctx.activities.map((x) => {
            return {
                ...x,
                activityState: {
                    ...x.activityState,
                    freeTextValue:
                        x.activityConfig.id === event.data.activityId &&
                        x.activityConfig.freeText &&
                        !!event.data.freeTextValue
                            ? event.data.freeTextValue
                            : '',
                },
            };
        }),
}) as any;

export const updateActivitiesToContext = assign<
    ActivitiesSelectionMachineContextTypes,
    { type: EventsCausingActions['updateActivitiesToContext']; data: ActivityConfig[] }
>({
    activities: (ctx, event) =>
        event.data.map((config) => {
            return {
                activityState: {
                    selected: false,
                    freeTextValue: '',
                },
                activityConfig: config,
            };
        }) ?? [],
}) as any;
