import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isSameDay from 'date-fns/isSameDay';
import parse from 'date-fns/parse';
import { isNil } from 'lodash';
import { FC, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import Analytics from '../../analytics/Analytics';
import { SCHEDULED_FOR } from '../../analytics/AnalyticsConstants';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import Button from '../../components/button/Button';
import Layout from '../../components/layout/Layout';
import PdsTmdDisclaimer from '../../components/pds-tmd-disclaimer/PdsTmdDisclaimer';
import RoamingProductModal from '../../components/roaming-product-modal/RoamingProductModal';
import SlideInCoverTermsCTA from '../../components/slide-in-cover-terms-cta/SlideInCoverTermsCTA';
import Sticky from '../../components/sticky/Sticky';
import coverDetailsContent from '../../content/ui/screens/cover-details/coverDetailsContent';
import withContent from '../../hoc/with-content/withContent';
import useCoverInformation from '../../hooks/useCoverInformation';
import useProductSpec from '../../hooks/useProductSpec';
import { PurchaseState } from '../../types/PurchaseState';
import CoverCode from '../../utils/constants/CoverCode';
import CoverTypeId from '../../utils/constants/CoverTypeId';
import DateFormat from '../../utils/constants/DateFormat';
import PageTitles from '../../utils/constants/PageTitles';
import formatDateToString from '../../utils/formatDateToString';
import getPdsVersion from '../../utils/getPdsVersion';
import getTimezone from '../../utils/getTimezone';
import Routes, { Route } from '../../utils/Routes';

import './CoverDetails.scss';

const contentMap = {
    heading: 'ui.heading',
    benefitsCta: 'ui.benefitsCTA',
    cta: 'ui.continueCTA',
};

interface YourCoverDetailsProps {
    content: Record<keyof typeof contentMap, string>;
}

const YourCoverDetailsWithPurchaseOptionValidated: FC<YourCoverDetailsProps> = ({ content }) => {
    const location = useLocation<PurchaseState>();
    const [openRoamingModal, setOpenRoamingModal] = useState<boolean>(false);
    const { destination } = location.state;
    const { selectedProductOption, coverStartDates, selectedProductGrouping } = location.state;
    const coverInformation = useCoverInformation(selectedProductOption!.representedByCoverCode);
    const history = useHistory();
    const productSpec = useProductSpec(selectedProductOption!.representedByCoverCode);
    const { products } = useProduct();
    const coverType = products.find(
        (x) => x.productSpec.mainCoverType.coverCode === selectedProductOption!.representedByCoverCode,
    )?.productSpec.mainCoverTypeId;
    const { userTimeZone } = useUser();
    const pdsVersion = getPdsVersion({ products, coverCode: selectedProductOption!.representedByCoverCode });

    const trackRoamingCancellation = (nextSlide: boolean) => {
        Analytics.trackRoamingCancellation(!nextSlide ? 'First' : 'Last');
    };

    const redirectToPurchase = () => {
        history.push({
            pathname: Routes.SELECT_COVER,
            // RESET
            state: {
                selectedProductGrouping: null,
                selectedProductOption: null,
                coverStartDates: [],
                destinations: null,
            },
        });
    };

    const handleContinue = () => {
        if (coverInformation && productSpec) {
            const zonedToday = utcToZonedTime(new Date(), userTimeZone);
            coverStartDates.forEach((date) => {
                const parsedDate = parse(date, DateFormat.DEFAULT, new Date());
                const zonedDate = utcToZonedTime(parsedDate, userTimeZone);

                Analytics.trackProductAdded({
                    productGroup: selectedProductGrouping,
                    variant: formatDateToString(date, DateFormat.ANALYTICS),
                    scheduledFor: isSameDay(zonedToday, zonedDate) ? SCHEDULED_FOR.TODAY : SCHEDULED_FOR.FUTURE,
                    startingInDays: differenceInCalendarDays(zonedDate, zonedToday),
                });
            });
            history.push({
                pathname: Routes.CART,
                state: {
                    products,
                    selectedProductGrouping: location.state.selectedProductGrouping,
                    selectedProductOption: location.state.selectedProductOption,
                    coverStartDates: [...coverStartDates.map((date) => formatDateToString(date, DateFormat.DEFAULT))],
                    ...(location.state.destination !== null &&
                    // only pass along destination data to the cart service if any exists
                    !Object.values(location.state.destination).every((val) => isNil(val))
                        ? {
                              destination: {
                                  destinations: location.state.destination?.destinations,
                                  startingDestination: location.state.destination?.startingDestination,
                                  startingRegion: location.state?.destination?.startingRegion,
                                  timezone: location.state?.destination?.timezone,
                              },
                          }
                        : {
                              destination: null,
                          }),
                },
            });
        } else {
            redirectToPurchase();
        }
    };

    const totalPrice = (coverStartDates.length * (productSpec?.billing.premium ?? 0 / 100)) / 100;
    const coverItems = coverStartDates.map((date) => {
        return {
            startTime: formatDateToString(date, DateFormat.VERBOSE_WITH_DAY),
            price: coverInformation?.price,
        };
    });

    const trackBenefitsDrawer = () => {
        const pageName = PageTitles[history?.location.pathname as Route];
        Analytics.trackBenefitsDrawerViewed(pageName, selectedProductOption?.representedByCoverCode ?? '');
    };

    return (
        <Layout title={content.heading} showBackButton className="cover-details__layout">
            <RoamingProductModal
                isOpen={openRoamingModal}
                onCancelYesClick={handleContinue}
                onExitClick={redirectToPurchase}
                trackCancelClick={trackRoamingCancellation}
            />
            <div className="cover-details__layout-wrapper">
                {coverInformation?.coverDetailsContent?.heading != null && (
                    <div className="cover-details__header">
                        <h3 className="cover-details__header__text">
                            {coverInformation!.coverDetailsContent!.heading!}
                        </h3>
                        <p className="cover-details__header__price">${totalPrice}</p>
                    </div>
                )}
                <div className="cover-details__wrapper">
                    <div className="cover-details__package__title">
                        {coverType === CoverTypeId.SINGLE_V1 && (
                            <span className="cover-details__package__count">
                                x {location.state.coverStartDates.length}
                            </span>
                        )}
                        <p className="cover-details__package__title__text">
                            {coverType === CoverTypeId.SUBSCRIPTION_V1
                                ? 'Your subscription starts:'
                                : `${coverInformation?.purchaseOptionsContent?.optionLabel ?? ''}:`}
                        </p>
                    </div>
                    {coverItems.length > 0 && (
                        <ul className="cover-details__list">
                            {coverItems.map((coverItem) => {
                                return (
                                    <li className="cover-details__list__cover" key={coverItem.startTime}>
                                        <span className="cover-details__list__cover__date">{coverItem.startTime}</span>
                                        <span className="cover-details__list__cover__price">{coverItem.price}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    <p className="cover-details__timezone">
                        Times displayed in{' '}
                        {getTimezone(location.state?.destination?.timezone ?? userTimeZone, coverStartDates[0])}
                    </p>
                    {destination !== null && !Object.values(destination).every((destVal) => isNil(destVal)) && (
                        <div className="cover-details__destinations">
                            <hr className="cover-details__divider" />
                            <p className="cover-details__destination">
                                <span className="cover-details__destination__header">Dest: </span>
                                {destination!.destinations.join(', ') ?? ''}
                            </p>
                            <p className="cover-details__destination">
                                <span className="cover-details__destination__header">Start: </span>
                                {destination!.startingRegion ?? destination!.startingDestination}
                            </p>
                        </div>
                    )}
                    {!!coverInformation?.coverDetailsContent &&
                        !!coverInformation?.coverDetailsContent.checkoutInstruction && (
                            <>
                                <hr className="cover-details__divider" />
                                <p className="cover-details__checkout__content">
                                    {coverInformation?.coverDetailsContent.checkoutInstruction}
                                </p>
                            </>
                        )}
                </div>
                {pdsVersion && (
                    <SlideInCoverTermsCTA
                        coverCode={selectedProductOption!.representedByCoverCode as CoverCode}
                        pdsVersion={pdsVersion}
                        benefitsContent={{
                            benefitsCTA: content.benefitsCta,
                        }}
                        trackDrawerOpen={() => trackBenefitsDrawer()}
                    />
                )}
            </div>
            <PdsTmdDisclaimer />
            <Sticky>
                <div className="cover-details__total">
                    <p className="cover-details__total__label">Price per person</p>
                    <p className="cover-details__total__price">${totalPrice}</p>
                </div>
                <Button
                    label={content.cta}
                    onClick={() => {
                        if (selectedProductOption?.requireDestinations) {
                            setOpenRoamingModal(true);
                        } else {
                            handleContinue();
                        }
                    }}
                    width="full"
                />
            </Sticky>
        </Layout>
    );
};

const YourCoverDetails: FC<YourCoverDetailsProps> = (props) => {
    const location = useLocation<PurchaseState>();

    if (location.state === undefined) {
        return <Redirect to={Routes.SELECT_COVER} />;
    }

    const { selectedProductOption, selectedProductGrouping, coverStartDates } = location.state;

    if (selectedProductOption === null || selectedProductGrouping === null || coverStartDates.length === 0) {
        return <Redirect to={Routes.SELECT_COVER} />;
    }

    return <YourCoverDetailsWithPurchaseOptionValidated {...props} />;
};

export default withContent(YourCoverDetails, contentMap, coverDetailsContent);
