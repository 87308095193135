import LocalizedStrings from 'react-localization';

const mobileAppLaunchAnnouncement = new LocalizedStrings({
    en: {
        title: "We've launched an app!",
        description:
            "Download the mobile app now to flip your cover on and off in just a few clicks, when you're on the go.",
        notNowCTA: 'Not now',
        getTheAppCTA: 'Get the app',
        appStoreActionText: 'Download on the',
        appStoreStoreName: 'App Store',
        playStoreActionText: 'Get it on',
        playStoreStoreName: 'Google Play',
    },
});

export default mobileAppLaunchAnnouncement;
