import visa from '../../../../assets/images/cards/visa.svg';
import mastercard from '../../../../assets/images/cards/mastercard.svg';
import amex from '../../../../assets/images/cards/amex.svg';
import discover from '../../../../assets/images/cards/discover.svg';
import diners from '../../../../assets/images/cards/diners.svg';
import jcb from '../../../../assets/images/cards/jcb.svg';

export interface Card {
    label: string;
    icon: string;
}

interface Cards {
    [key: string]: Card;
}

const Cards: Cards = {
    visa: {
        label: 'Visa',
        icon: visa,
    },
    diners_club: {
        label: 'Diners Club',
        icon: diners,
    },
    amex: {
        label: 'American Express',
        icon: amex,
    },
    mastercard: {
        label: 'MasterCard',
        icon: mastercard,
    },
    discover: {
        label: 'Discover',
        icon: discover,
    },
    jcb: {
        label: 'JCB',
        icon: jcb,
    },
};

export default Cards;
