import { ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import AlertInfoIcon from '../../assets/images/alert-info.svg';
import AlertIcon from '../../assets/images/alert-alert.svg';
import AlertErrorIcon from '../../assets/images/alert-error.svg';
import AlertInfoLargeIcon from '../../assets/images/alert-info-large.svg';
import AlertLargeIcon from '../../assets/images/alert-alert-large.svg';
import AlertErrorLargeIcon from '../../assets/images/alert-error-large.svg';
import Card from '../card/Card';
import Button, { ButtonProps } from '../button/Button';
import ErrorMessages from './error-messages/ErrorMessages';

import './Alert.scss';

export enum AlertTypes {
    ALERT = 'alert',
    INFO = 'info',
    ERROR = 'error',
}

export enum AlertSizes {
    SMALL = 'small',
    LARGE = 'large',
}
export interface AlertProps {
    size?: AlertSizes;
    type?: AlertTypes;
    icon?: string;
    message?: string | ReactNode;
    className?: string;
    // CTA only applies to large Alerts
    cta?: ButtonProps;
}

const Alert: React.FC<AlertProps> = ({
    size = AlertSizes.SMALL,
    type = AlertTypes.ALERT,
    icon,
    message,
    className,
    cta,
}) => {
    const cardIcon = useMemo(() => {
        if (icon) {
            return icon;
        }

        if (size === AlertSizes.LARGE) {
            if (type === AlertTypes.INFO) {
                return AlertInfoLargeIcon;
            }
            if (type === AlertTypes.ERROR) {
                return AlertErrorLargeIcon;
            }
            return AlertLargeIcon;
        }

        if (type === AlertTypes.INFO) {
            return AlertInfoIcon;
        }
        if (type === AlertTypes.ERROR) {
            return AlertErrorIcon;
        }
        return AlertIcon;
    }, [icon, type, size]);

    const content = useMemo(() => message || ErrorMessages.default, [message]);

    const renderSmallAlert = () => (
        <div className={classNames('alert', `alert--${type}`, className)}>
            <img src={cardIcon} alt={`icon-${type}`} role="presentation" className="alert__icon" />
            {content}
        </div>
    );

    const renderLargeAlert = () => (
        <Card className={classNames('alert--large', `alert--${type}`, className)} icon={cardIcon}>
            <div className="alert--large__content">{content}</div>
            {cta && (
                <Button
                    width="full"
                    variant="primary"
                    {...cta}
                    className={classNames('alert--large__cta', cta.className)}
                />
            )}
        </Card>
    );

    return size === AlertSizes.LARGE ? renderLargeAlert() : renderSmallAlert();
};

export default Alert;
