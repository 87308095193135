import React, { useContext, createContext, useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import ReferralService from '../../services/referral-service/ReferralService';
import useCreateLazyDependency from '../../hooks/lazy-dependency/useCreateLazyDependency';
import LazyDependency from '../models/LazyDependency';

interface ReferralLazyDependencies {
    referralCode: LazyDependency<string>;
}

export type IReferralContext = ReferralLazyDependencies;

export const ReferralContext = createContext<any>({});

export const useReferral = (): IReferralContext => {
    const context: IReferralContext = useContext(ReferralContext);
    if (typeof context === 'undefined') {
        throw new Error('Referral Context must be used within the ReferralProvider');
    }
    return context;
};

export const ReferralProvider: React.FC = (props) => {
    const { oktaAuth } = useOktaAuth();

    // ***************** Fetchers *****************

    const fetchReferralCode = useCallback(async () => {
        const accessToken = oktaAuth.getAccessToken();
        if (typeof accessToken === 'undefined') throw new Error('fetchReferralCode: Access token required');

        const referralCode = await ReferralService.getReferralCode({ accessToken });
        return referralCode;
    }, [oktaAuth]);

    // ***************** Lazy Dependencies *****************

    const referralCode = useCreateLazyDependency(fetchReferralCode);

    // ***************** Render *****************

    const value: IReferralContext = {
        referralCode,
    };

    return <ReferralContext.Provider value={value} {...props} />;
};
