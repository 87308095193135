import { FC, ReactNode } from 'react';
import SlideInUpTransition from '../../transitions/slide-in-up/SlideInUpTransition';
import closeIcon from '../../../assets/images/close-white.svg';

import './BaseAnnouncement.scss';

export interface BaseAnnouncementProps {
    isShowing: boolean;
    showCloseButton?: boolean;
    onClose?: () => void;
    children: ReactNode;
}

/**
 * Base component for banner, to be used in Layout. This component also caters for async loading of content.
 * In async scenarios leave the text prop undefined until content is ready to be displayed. The banner
 * will then load with a slide in animation
 *
 * @param param0
 * @returns
 */
const BaseAnnouncement: FC<BaseAnnouncementProps> = ({ isShowing, showCloseButton, onClose, children }) => {
    return (
        <div className="base-announcement">
            <SlideInUpTransition in={isShowing}>
                <div className="base-announcement__content">
                    {showCloseButton && (
                        <button aria-label="Close" type="button" onClick={onClose} className="base-announcement__close">
                            <img src={closeIcon} alt="Close" />
                        </button>
                    )}
                    {children}
                </div>
            </SlideInUpTransition>
        </div>
    );
};

export default BaseAnnouncement;
