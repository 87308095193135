import oktaFlow from '../../../strings/oktaFlow';
import Routes from '../../../utils/Routes';

const setupForgotPasswordLink = (): void => {
    const link = document.createElement('a');
    link.innerHTML = oktaFlow.login.forgotPassword;
    link.href = Routes.FORGOT_PASSWORD;
    link.className = 'okta-sign-in-widget__forgot-password-link';
    const passwordInput = document.querySelector('form.mfa-verify-password .o-form-input-name-password');

    if (passwordInput) {
        passwordInput.insertAdjacentElement('afterend', link);
    }
};

export default setupForgotPasswordLink;
