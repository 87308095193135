import PriceLineItem from '../price-line-item/PriceLineItem';
import paymentsStrings from '../../../../strings/payments';
import formatPrice from '../../../../utils/formatPrice';
import { CheckoutDetailsResponse } from '../../../../business-logic/models/CheckoutDetails';
import { ListItemProps } from '../../../../components/list-item/ListItem';

export default (checkoutDetails: CheckoutDetailsResponse, creditBalance: number): ListItemProps => {
    const remainingCredit =
        creditBalance - checkoutDetails.invoice.balanceApplied < 0
            ? 0
            : creditBalance - checkoutDetails.invoice.balanceApplied;

    return {
        header: paymentsStrings.accountCredit,
        customDataElement: (
            <div className="checkout-summary__account-credit">
                <PriceLineItem
                    description={paymentsStrings.creditApplied}
                    amount={`-${formatPrice(checkoutDetails.invoice.balanceApplied)}`}
                    withTopSpacing
                />
                <PriceLineItem
                    description={<strong>{paymentsStrings.creditBalance}</strong>}
                    amount={`${formatPrice(remainingCredit)}`}
                    withTopSpacing
                    className="checkout-summary__account-credit_balance"
                />
            </div>
        ),
    };
};
