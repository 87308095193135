import { FC } from 'react';
import Analytics from '../../analytics/Analytics';
import commonStrings from '../../strings/common';
import onBoardingStrings from '../../strings/onboardingFlow';
import ExternalLinks from '../../utils/constants/ExternalLinks';
import ExternalLink from '../external-link/ExternalLink';

import './ReviewCoverFinePrint.scss';

interface ReviewCoverFinePrintProps {
    ctaLabel: string;
}

const ReviewCoverFinePrint: FC<ReviewCoverFinePrintProps> = ({ ctaLabel }) => {
    const { reviewCover: contentStrings } = onBoardingStrings;

    return (
        <div className="review-cover-fine-print">
            {onBoardingStrings.formatString(contentStrings.agreementTitle, {
                ctaLabel,
            })}
            <ul>
                <li>
                    {onBoardingStrings.formatString(contentStrings.agreementBulletPoint1, {
                        pdsFsg: (
                            <ExternalLink
                                to={ExternalLinks.productDisclosureStatement}
                                label={contentStrings.agreementBulletPoint1PdsFsg}
                                onClick={Analytics.trackPDSLinkClicked}
                            />
                        ),
                    })}
                </li>
                <li>
                    {onBoardingStrings.formatString(contentStrings.agreementBulletPoint2, {
                        directDebitAgreement: (
                            <ExternalLink
                                to={ExternalLinks.directDebitAgreement}
                                label={commonStrings.directDebitAgreement}
                            />
                        ),
                    })}
                </li>
                <li>
                    {onBoardingStrings.formatString(contentStrings.agreementBulletPoint3, {
                        customerSupport: (
                            <a href={`mailto:${commonStrings.flipSupportEmail}?subject=Understanding my cover`}>
                                {commonStrings.customerSupport}
                            </a>
                        ),
                    })}
                </li>
            </ul>
        </div>
    );
};

export default ReviewCoverFinePrint;
