import React from 'react';
import { Layout } from '../../components/layout/Layout';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import Pattern from '../../assets/images/dashboard-pattern.svg';
import Logo from '../../logo.svg';
import './Loading.scss';

const Loading: React.FC = () => (
    <Layout isContentCentered={false} className="loading__layout" bgPattern={false} showPdsVersion={false}>
        <img src={Pattern} className="loading__pattern-bg" alt="pattern" />
        <img src={Logo} className="loading__logo" alt="logo" />
        <div className="loading__spinner">
            <LoadingSpinner size={240} />
        </div>
    </Layout>
);

export default Loading;
