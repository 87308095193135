import React from 'react';
import { useHistory } from 'react-router-dom';
import { CartErrorDisplay } from '../../business-logic/models/Cart';
import { CartErrorResolveActionLabel, CartErrorResolveActions } from '../../hooks/use-cart/constants/CartErrorMessages';
import commonStrings from '../../strings/common';
import ExternalLinks from '../../utils/constants/ExternalLinks';
import Routes from '../../utils/Routes';
import Alert, { AlertTypes } from '../alert/Alert';
import Button from '../button/Button';

import './CartError.scss';

export interface CartErrorProps {
    cartError: CartErrorDisplay;
    prevStepEvent: () => void;
    tryAgainEvent: () => void;
    showAction?: boolean;
}

export const CartError: React.FC<CartErrorProps> = ({ cartError, prevStepEvent, tryAgainEvent, showAction = true }) => {
    const history = useHistory();

    const cartErrorAction = (errorCode: string) => {
        switch (errorCode) {
            case CartErrorResolveActions.GO_TO_FLIP:
                window.location.href = ExternalLinks.productPage;
                break;
            case CartErrorResolveActions.DASHBOARD:
                history.push(Routes.HOME);
                break;
            case CartErrorResolveActions.CUSTOMER_SUPPORT:
                window.location.href = `mailto:${commonStrings.flipSupportEmail}`;
                break;
            case CartErrorResolveActions.TRY_AGAIN:
                tryAgainEvent();
                break;
            case CartErrorResolveActions.PREV_STEP:
                prevStepEvent();
                break;
            default:
                console.log('No action taken');
        }
    };

    return (
        <>
            <Alert
                className="cart-error"
                message={(cartError as CartErrorDisplay).errorMessage}
                type={AlertTypes.ERROR}
            />
            {showAction && (
                <Button
                    width="full"
                    className="cart-error__button"
                    label={CartErrorResolveActionLabel[(cartError as CartErrorDisplay).resolveAction]}
                    onClick={() => cartErrorAction((cartError as CartErrorDisplay).resolveAction)}
                />
            )}
        </>
    );
};

export default CartError;
