import classNames from 'classnames';
import React from 'react';

import { useHistory } from 'react-router-dom';
import ArrowLeft from '../../assets/images/arrow-left-black.svg';
import ArrowLeftWhite from '../../assets/images/arrow-left-white.svg';
import common from '../../strings/common';

import './BackButton.scss';

interface BackButtonProps {
    className?: string;
    variant?: 'default' | 'white';
    onClick?: () => void;
    disabled?: boolean;
}

export const BackButton: React.FC<BackButtonProps> = ({ className, variant, onClick, disabled = false }) => {
    const history = useHistory();
    const goBack = () => {
        if (onClick) {
            onClick();
        } else {
            history.goBack();
        }
    };

    return (
        <button className={classNames('go-back-link', className)} type="button" onClick={goBack} disabled={disabled}>
            <img src={variant === 'white' ? ArrowLeftWhite : ArrowLeft} alt={common.goBack} />
            <span>Back</span>
        </button>
    );
};

BackButton.defaultProps = {
    variant: 'default',
};

export default BackButton;
