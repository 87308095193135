import React, { forwardRef } from 'react';
import classNames from 'classnames';
import CardInfoIcon from '../../assets/images/card-info.svg';
import CardErrorIcon from '../../assets/images/card-error.svg';
import chevron from '../../assets/images/chevron-right-harbour.svg';
import './Card.scss';

export enum CardTypes {
    BASIC = 'basic',
    INFO = 'info',
    ATTENTION = 'attention',
}
export interface CardProps {
    type?: CardTypes;
    title?: string;
    icon?: string;
    showPattern?: boolean;
    showChevron?: boolean;
    className?: string;
    children?: React.ReactNode;
    onClick?: () => void;
}

export const Card = forwardRef<HTMLDivElement, CardProps>(
    (
        { type = CardTypes.BASIC, title, icon, showPattern = false, showChevron = false, className, children, onClick },
        ref,
    ) => {
        const getIcon = () => {
            if (type === CardTypes.INFO) return CardInfoIcon;
            if (type === CardTypes.ATTENTION) return CardErrorIcon;
            if (icon === undefined) return null;
            return icon;
        };

        const cardIcon = getIcon();

        return (
            <div
                className={classNames(
                    'card',
                    `card--${type}`,
                    className,
                    showPattern && 'card--with-pattern',
                    onClick && 'card--clickable',
                )}
                onClick={onClick}
                onKeyDown={onClick}
                role="presentation"
                ref={ref}
            >
                {(title || cardIcon || showChevron) && (
                    <div className="card__title">
                        {title && <h2>{title}</h2>}
                        {cardIcon && <img src={cardIcon} alt="icon" />}
                        {showChevron && <img src={chevron} alt="" />}
                    </div>
                )}
                {children}
            </div>
        );
    },
);

export default Card;
