import React, { ReactNode } from 'react';
import Cards from './Cards';
import editCardLogo from '../../../../assets/images/edit-card.svg';
import closeLogo from '../../../../assets/images/close.svg';
import paymentStrings from '../../../../strings/payments';

import './SavedCard.scss';

interface SavedCardProps {
    brand: string;
    last4?: string;
    onChange: () => void;
    onCancel: () => void;
    isChangingCardDetails?: boolean;
    cardDetailsComponent?: ReactNode;
}

const SavedCard: React.FC<SavedCardProps> = ({
    brand,
    last4,
    onChange,
    onCancel,
    isChangingCardDetails = false,
    cardDetailsComponent,
}) => (
    <div className="saved-card">
        <div className="saved-card__row">
            <div className="saved-card__card">
                <img src={Cards[brand]?.icon} alt="card" />
                <p className="saved-card__card-number">•••• {last4}</p>
            </div>
            <button type="button" className="saved-card__btn" onClick={isChangingCardDetails ? onCancel : onChange}>
                {isChangingCardDetails ? (
                    <>
                        {paymentStrings.cardChangeCancel}{' '}
                        <img src={closeLogo} alt="" className="saved-card__btn-image" />{' '}
                    </>
                ) : (
                    <>
                        {paymentStrings.cardChange} <img src={editCardLogo} alt="" className="saved-card__btn-image" />
                    </>
                )}
            </button>
        </div>
        {isChangingCardDetails && cardDetailsComponent}
    </div>
);

export default SavedCard;
