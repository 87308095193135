import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './Toggle.scss';

export interface ToggleProps {
    id: string;
    label?: string | ReactNode;
    checked: boolean;
    disabled?: boolean;
    onToggle(id: string, checked: boolean): void;
}

export const Toggle: React.FC<ToggleProps> = ({ id, label, checked, disabled, onToggle }) => {
    return (
        <label
            className={classNames('toggle', {
                'toggle--disabled': disabled,
                'toggle--with-label': !!label,
            })}
            htmlFor={id}
        >
            {label}
            <input
                id={id}
                className="toggle__checkbox"
                type="checkbox"
                checked={checked}
                disabled={disabled}
                onChange={(e) => onToggle(id, e.target.checked)}
            />
            <span className="toggle__slider-wrapper">
                <span className="toggle__slider" />
            </span>
        </label>
    );
};

export default Toggle;
