import React from 'react';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import './LoadingSpinnerOverlay.scss';

export const LoadingSpinnerOverlay: React.FC = () => (
    <div className="loading-spinner">
        <LoadingSpinner />
    </div>
);

export default LoadingSpinnerOverlay;
