import { useProduct } from '../business-logic/context-provider/ProductContext';
import { ProductOption } from '../business-logic/models/ProductGroupsResponse';

/**
 * Given a product group id, returns the product options available for purchase
 *
 * @param productGroupId product group id
 * @returns product options available for purchase
 */
const useProductOptionsAvailableForPurchase = (productGroupId: string): ProductOption[] => {
    const { productGroups, products, coverInformation } = useProduct();

    const productGroup = productGroups.find((p) => p.id.toLowerCase() === productGroupId.toLowerCase());

    if (productGroup === undefined) {
        return [];
    }

    // Keep only product options for which all cover codes are valid for sale
    return productGroup.options.filter((option) => {
        // For each cover code in the option
        return option.coverCodes.every((coverCode) => {
            const correspondingProductOnSale = products.find(
                (p) => p.productSpec.mainCoverType.coverCode === coverCode,
            );

            // Check if cover code is available for purchase
            if (correspondingProductOnSale === undefined) {
                return false;
            }

            const pdsVersionOnSale = `${correspondingProductOnSale.pdsVersionMajor}.${correspondingProductOnSale.pdsVersionMinor}`;

            // Check if corresponding cover information exist for PDS version on sale
            if (
                coverInformation[coverCode] === undefined ||
                coverInformation[coverCode][pdsVersionOnSale] === undefined
            ) {
                return false;
            }

            return true;
        });
    });
};

export default useProductOptionsAvailableForPurchase;
