import axios from 'axios';
import { CoverCategory } from '../../business-logic/models/cdn-content/CoverCategory';
import asService from '../utils/asService';
import isAxios404Error from '../utils/isAxios404Error';

const baseApiPath = import.meta.env.VITE_BASE_CDN_PATH;

const getCoverCategories = async (): Promise<CoverCategory[]> => {
    try {
        const { data } = await axios.get<CoverCategory[]>(`${baseApiPath}/json/coverCategories.json`);
        return data;
    } catch (error) {
        if (isAxios404Error(error)) {
            throw new Error(`Unable to retrieve cover categories from Flip content delivery network.`);
        }
        throw new Error(`Error retrieving cover categories from Flip content delivery network`);
    }
};

export default asService(getCoverCategories, false);
