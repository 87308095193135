import LocalizedStrings from 'react-localization';

const dateOfBirthInput = new LocalizedStrings({
    en: {
        day: 'Day',
        month: 'Month',
        year: 'Year',
    },
});

export default dateOfBirthInput;
