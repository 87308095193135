import { useActor } from '@xstate/react';
import { FC, MouseEventHandler } from 'react';
import { ActorRefFrom } from 'xstate';

import paymentElementMachine from '../payment-element-machine/paymentElementMachine';
import BasePaymentButton from '../../ui-elements/base-payment-button/BasePaymentButton';
import getCheckoutStatus from './getCheckoutStatus';

interface SavedCardPaymentButtonProps {
    paymentElementMachineRef: ActorRefFrom<typeof paymentElementMachine>;
    amountDue: number;
}

const SavedCardPaymentButton: FC<SavedCardPaymentButtonProps> = ({ paymentElementMachineRef, amountDue }) => {
    const [state, send] = useActor(paymentElementMachineRef);

    const handleClick: MouseEventHandler = (e) => {
        e.preventDefault();
        send({
            type: 'SAVED_CARD_CLICK_PAY',
        });
    };

    return (
        <BasePaymentButton
            amountDue={amountDue}
            checkoutStatus={getCheckoutStatus(state)}
            disabled={!state.hasTag('VALID_CARD')}
            onClick={handleClick}
        />
    );
};

export default SavedCardPaymentButton;
