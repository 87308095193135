import LocalizedStrings from 'react-localization';

const oktaSignInWidget = new LocalizedStrings({
    en: {
        signIn: 'Sign in',
        continue: 'Continue',
    },
});

export default oktaSignInWidget;
