import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const linkReferralCode = async ({
    accessToken,
    referralCode,
    axios,
}: { accessToken: string; referralCode: string } & ServiceArgs) => {
    return axios.post(
        `${baseApiPath}/api/v1/referral/refer/${encodeURIComponent(referralCode)}`,
        undefined,
        toHeaders(accessToken),
    );
};

export default asService(linkReferralCode);
