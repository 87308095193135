import { datadogRum } from '@datadog/browser-rum';
import axios from 'axios';

// TODO: Transition to async/await pattern

const baseUrl = import.meta.env.VITE_BASE_API_PATH;

const toPolicyUrl = (query = '') => `${baseUrl}/api/policy${query}`;

const generatePolicyDocument = (accessToken: string | undefined) =>
    axios
        .get(toPolicyUrl('/document'), {
            responseType: 'arraybuffer',
            headers: { Authorization: `Bearer ${accessToken}`, Accept: 'application/pdf' },
        })
        .then((response) => response.data)
        .catch((error) => {
            datadogRum.addError(new Error(error), {
                serviceName: 'generatePolicyDocument',
                description: 'Failed to generate certificate of cover',
            });
            throw new Error(error);
        });

export default { generatePolicyDocument };
