import { FC, ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import './SlideInUpTransition.scss';

export interface SlideInUpTransitionProps {
    children: ReactNode;
    in: boolean;
}

const SlideInUpTransition: FC<SlideInUpTransitionProps> = ({ in: inProp, children }) => {
    const nodeRef = useRef(null);

    return (
        <div className="slide-in-up-wrapper">
            <CSSTransition
                mountOnEnter
                nodeRef={nodeRef}
                in={inProp}
                timeout={500}
                classNames="slide-in-up"
                unmountOnExit
            >
                <div ref={nodeRef}>{children}</div>
            </CSSTransition>
        </div>
    );
};

export default SlideInUpTransition;
