import CoverCode from './constants/CoverCode';

const isRoamingCover = (cover: CoverCode): boolean => {
    switch (cover) {
        case CoverCode.FLIP_ROAMING_WEEKLY:
            return true;
        case CoverCode.FLIP_ROAMING_KIDS_WEEKLY:
            return true;
        default:
            return false;
    }
};

export default isRoamingCover;
