import classNames from 'classnames';

import { RadioCheckboxProps } from '../RadioCheckboxProps';

import './RadioButton.scss';

const RadioButton: React.FC<RadioCheckboxProps & { variant?: 'primary' | 'secondary' }> = ({
    id,
    name,
    label,
    className,
    checked,
    form,
    onChange,
    onBlur,
    onFocus,
    variant = 'primary',
    disabled,
}) => {
    return (
        <label
            htmlFor={id}
            className={classNames(
                'radio-button',
                `radio-button--${variant}`,
                disabled && 'radio-button--disabled',
                { checked },
                className,
            )}
        >
            <input
                type="radio"
                id={id}
                name={name}
                checked={checked}
                className="radio-button__input"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                form={form}
                disabled={disabled}
            />
            <div
                className={classNames('radio-button__decorated-radio', `radio-button__decorated-radio--${variant}`, {
                    checked,
                })}
            />
            {label}
        </label>
    );
};

export default RadioButton;
