import classNames from 'classnames';
import { FC, ReactNode, useMemo } from 'react';
import close from '../../assets/images/close.svg';
import commonStrings from '../../strings/common';
import parseString from '../../utils/parseString';
import Alert from '../alert/Alert';
import { Button, ButtonProps } from '../button/Button';
import Modal from '../modal/Modal';
import { ModalAlert, ModalWithCTAAlert } from './utils/ModalWithCTAAlertTypes';

import './ModalWithCTA.scss';

interface ModalCTAProps {
    onClick: () => void;
    label?: string;
    variant?: ButtonProps['variant'];
    disabled?: boolean;
}

export interface ModalWithCTAProps {
    isOpen: boolean;
    onClose?: () => void;
    textContent: string | ReactNode;
    hasError?: boolean;
    errorMessage?: string | ReactNode;
    primaryCTA: ModalCTAProps;
    secondaryCTA?: ModalCTAProps;
    tertiaryCTA?: ModalCTAProps;
    icon?: ModalAlert;
    className?: string;
    variant?: 'pop-up' | 'slide-in' | 'slide-up' | 'slide-to-center';
    title?: string;
    arrangeButtons?: 'row' | 'column';
    universalClose?: boolean;
}

const ModalWithCTA: FC<ModalWithCTAProps> = ({
    isOpen,
    onClose,
    textContent,
    hasError,
    errorMessage,
    primaryCTA,
    secondaryCTA,
    tertiaryCTA,
    icon,
    className,
    variant,
    title,
    arrangeButtons,
    universalClose = false,
}) => {
    const primaryCTALabel = useMemo(() => {
        if (primaryCTA?.label) {
            return primaryCTA.label;
        }

        return hasError ? commonStrings.tryAgain : commonStrings.yes;
    }, [hasError, primaryCTA]);

    const secondaryCTALabel = useMemo(() => {
        if (secondaryCTA?.label) {
            return secondaryCTA.label;
        }

        return hasError ? commonStrings.close : commonStrings.no;
    }, [hasError, secondaryCTA]);

    const tertiaryCTALabel = useMemo(() => {
        if (tertiaryCTA?.label) {
            return parseString(tertiaryCTA.label);
        }

        return hasError ? commonStrings.close : commonStrings.no;
    }, [hasError, tertiaryCTA]);

    return (
        <Modal variant={variant} isOpen={isOpen} onClose={onClose} parentClass="#root" className={className}>
            <div className="modal-with-cta__header">
                {universalClose && !!onClose && (
                    <div className="modal-with-cta__close-button-wrapper">
                        <button
                            type="button"
                            className="modal-with-cta__close-button"
                            aria-label="Close Modal"
                            onClick={onClose}
                        >
                            <img src={close} alt="Close" />
                        </button>
                    </div>
                )}
                <div className="modal-with-cta__icon">
                    <img src={ModalWithCTAAlert[icon || 'ALERT']} alt="" />
                </div>
            </div>
            {title && <h2 className="modal-with-cta__heading">{title}</h2>}
            <div
                className={classNames('modal-with-cta__text', {
                    'modal-with-cta__text-error': hasError,
                })}
            >
                {textContent}
            </div>
            {hasError && <Alert className="modal-with-cta__error-alert" message={errorMessage} />}
            <div className="modal-with-cta__buttons">
                <div
                    className={classNames('modal-with-cta__btn-group', {
                        'modal-with-cta__btn-group--row': !!secondaryCTA && arrangeButtons === 'row',
                        'modal-with-cta__btn-group--col': !!secondaryCTA && arrangeButtons === 'column',
                    })}
                >
                    {secondaryCTA && (
                        <Button
                            className="modal-with-cta__btn-secondary"
                            variant={secondaryCTA.variant || 'secondary'}
                            label={secondaryCTALabel}
                            width="full"
                            onClick={secondaryCTA.onClick}
                            disabled={secondaryCTA.disabled}
                        />
                    )}
                    <Button
                        variant={primaryCTA.variant || 'primary'}
                        label={primaryCTALabel}
                        width="full"
                        onClick={primaryCTA.onClick}
                        disabled={primaryCTA.disabled}
                    />
                </div>
                {tertiaryCTA && (
                    <Button
                        variant={tertiaryCTA.variant || 'link'}
                        label={tertiaryCTALabel}
                        width="full"
                        onClick={tertiaryCTA.onClick}
                        disabled={tertiaryCTA.disabled}
                    />
                )}
            </div>
        </Modal>
    );
};

export default ModalWithCTA;
