import { useCallback, useState } from 'react';
import LazyDependency from '../../business-logic/models/LazyDependency';

/**
 * This hook creates a `LazyDependency` - a dependency whose data will only be fetched when
 * when it is needed.
 *
 * Use the `useLazyDependency` hook to access the dependency where it is needed.
 *
 * @param fetchMethod a method that when called will fetch and return the dependency data
 * @returns `LazyDependency`
 */
const useCreateLazyDependency = <T>(fetchMethod: () => Promise<T>): LazyDependency<T> => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [value, setValue] = useState<T>();

    const fetch = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetchMethod();
            setValue(response);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }, [fetchMethod]);

    return {
        isLoading,
        hasError,
        value,
        fetch,
    };
};

export default useCreateLazyDependency;
