import UserDetails from '../../../../../business-logic/models/UserDetails';
import PersonService from '../../../../../services/person-service/PersonService';
import formatDateToString from '../../../../../utils/formatDateToString';
import { PersonsInsuredMachineContextTypes } from '../context/personsInsuredMachineContext';

export type PersonsInsuredMachineServices = {
    createDependant: {
        data: UserDetails;
    };
};

export const createDependant = async (ctx: PersonsInsuredMachineContextTypes): Promise<UserDetails> => {
    const child = ctx.kidDetailsFormMachineRef?.getSnapshot()?.context;
    const { firstName, lastName, dob, gender } = child!;
    const { dependants } = await PersonService.createDependants({
        accessToken: ctx.accessToken,
        dependants: [
            {
                firstName,
                lastName,
                dateOfBirth: formatDateToString(new Date(`${dob.year}/${dob.month}/${dob.day}`)),
                gender: gender!,
            },
        ],
    });
    return dependants[0];
};
