import { FC } from 'react';
import Alert, { AlertTypes } from '../../components/alert/Alert';
import Card from '../../components/card/Card';
import profile from '../../strings/profileFlow';
import formatPrice from '../../utils/formatPrice';
import parseString from '../../utils/parseString';

const AccountCredit: FC<{
    creditBalanceValue: number;
    hasActiveSubscription: boolean;
}> = ({ creditBalanceValue, hasActiveSubscription }) => (
    <Card className="profile__credit profile__list">
        <p className="profile__credit-header">{profile.accountCredit}</p>
        <Card className="profile__credit-amount">
            <strong>
                {profile.formatString(profile.accountCreditText, {
                    creditBalance: formatPrice(creditBalanceValue, true),
                })}
            </strong>
        </Card>
        {hasActiveSubscription && (
            <Alert
                type={AlertTypes.INFO}
                message={<span>{parseString(profile.noChargeAlert)}</span>}
                className="profile__credit-no-charge-alert"
            />
        )}
    </Card>
);

export default AccountCredit;
