import LocalizedStrings from 'react-localization';

const purchaseOptionsContent = new LocalizedStrings({
    en: {
        heading: 'Select dates',
        cta: 'Confirm dates',
    },
});

export default purchaseOptionsContent;
