import { FC } from 'react';
import { ActorRefFrom, InterpreterFrom, Prop, StateFrom } from 'xstate';

import checkoutMachine from '../../screens/checkout/checkout-machine/checkoutMachine';
import paymentStrings from '../../strings/payments';
import CoverTypeId from '../../utils/constants/CoverTypeId';
import parseString from '../../utils/parseString';
import Alert, { AlertTypes } from '../alert/Alert';
import PaymentMethodNone from './none/PaymentMethodNone';
import PaymentMethodPaymentElement from './payment-element/PaymentMethodPaymentElement';
import paymentElementMachine from './payment-element/payment-element-machine/paymentElementMachine';

import './PaymentMethods.scss';

const PaymentMethods: FC<{
    state: StateFrom<typeof checkoutMachine>;
    send: Prop<InterpreterFrom<typeof checkoutMachine>, 'send'>;
}> = ({ state, send }) => {
    const isPaymentMethodRequired =
        state.context.checkoutDetails.invoice.amountDue > 0 ||
        state.context.covers.find((cover) => cover.coverTypeId === CoverTypeId.SUBSCRIPTION_V1);

    return isPaymentMethodRequired ? (
        <>
            {state.context.checkoutDetails.invoice.amountDue <= 0 && (
                <Alert
                    type={AlertTypes.INFO}
                    message={<span>{parseString(paymentStrings.noChargeAlert)}</span>}
                    className="payment-methods__no-charge-alert"
                />
            )}
            <PaymentMethodPaymentElement
                paymentElementMachineRef={
                    state.context.paymentMethodMachine as ActorRefFrom<typeof paymentElementMachine>
                }
                amountDue={state.context.checkoutDetails.invoice.amountDue}
            />
        </>
    ) : (
        <PaymentMethodNone state={state} send={send} />
    );
};

export default PaymentMethods;
