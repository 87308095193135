import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import * as ReactRouterDom from 'react-router-dom';
import Analytics from '../../analytics/Analytics';
import PageEvents from '../../analytics/PageEvents';
import Routes, { Route } from '../../utils/Routes';

const TrackedRoute = (props: ReactRouterDom.RouteProps) => {
    const match = ReactRouterDom.useRouteMatch(props);
    const { authState } = useOktaAuth();

    useEffect(() => {
        if (match !== null) {
            const pageEvent = PageEvents[match.path as Route];
            const waitForAuthStateToResolveBeforeTracking =
                match.path === Routes.REGISTER || match.path === Routes.LOGIN || match.path === Routes.LANDING;
            if (pageEvent) {
                if (waitForAuthStateToResolveBeforeTracking) {
                    // If auth state is resolved
                    if (authState !== null) {
                        // Only call identify if not authenticated
                        // If authenticated, will auto redirect to dashboard
                        // which will then call authenticated tacking from there
                        // this prevents additional tracking that is caused by redirects
                        if (!authState.isAuthenticated) {
                            Analytics.identify();
                            Analytics.trackScreen(pageEvent);
                        }
                    }
                } else {
                    Analytics.identify();
                    Analytics.trackScreen(pageEvent);
                }
            }
        }
    }, [authState, match, match?.path]);
    return <ReactRouterDom.Route {...props} />;
};

export default TrackedRoute;
