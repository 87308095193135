import React from 'react';
import './Counter.scss';

type TProps = {
    current: number;
    total: number;
};

const Counter: React.FC<TProps> = ({ current, total }: TProps) => {
    return (
        <div className="counter">
            {current}/{total}
        </div>
    );
};

export default Counter;
