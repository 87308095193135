import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import '@okta/okta-auth-js/polyfill';
import { Security } from '@okta/okta-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { loadAnalytics } from '../../analytics/segmentAnalytics';
import oktaAuthClient from '../../auth/oktaAuthClient';
import { UserProvider } from '../../business-logic/context-provider/user-context';
import Routes from '../../utils/Routes';
import PageTitles from '../../utils/constants/PageTitles';
import getAndClearLocationState from '../../utils/getAndClearLocationState';
import secretCode from '../../utils/secretCode';
import storeLocationState from '../../utils/storeLocationState';
import loadDatadogRUM from '../../utils/third-party-dependencies/loadDatadogRUM';
import Providers from './Providers';
import Router from './Router';

const App: React.FC = () => {
    const history = useHistory();
    const location = useLocation();

    const cartQueryClient = new QueryClient();

    const redirectToLogin = () => {
        // Always store anything in location state before redirect to login
        storeLocationState(location);
        history.replace(Routes.LOGIN);
    };

    const restoreOriginalUri = useCallback(
        async (_oktaAuth: OktaAuth, originalUri: string) => {
            // Restore anything in location state prior to redirect to login
            const locationState = getAndClearLocationState();
            // Ensure first param to URL constructor is relative using toRelativeUrl
            const urlObj = new URL(toRelativeUrl(originalUri, window.location.origin), window.location.origin);

            // Use urlObj to pass in search and hash as separate to pathname
            // Otherwise react router will not match pathnames correctly
            // resulting in 404 when original url has search or hash
            history.replace({
                state: locationState,
                pathname: urlObj.pathname,
                search: urlObj.search,
                hash: urlObj.hash,
            });
        },
        [history],
    );

    const setOnboardingState = () => {
        if (queryString.parse(location.search).isOnboarding === 'true') {
            history.replace({ state: { ...(location.state || {}), isOnboarding: true } });
        }
    };

    useEffect(() => {
        loadAnalytics();
        loadDatadogRUM();
        setOnboardingState();
        secretCode();
    }, []);

    useEffect(() => {
        const generatedPageTitle = Object.entries(PageTitles).find(
            ([route]) =>
                matchPath(location.pathname, {
                    path: route,
                    exact: true,
                    strict: false,
                }) !== null,
        );

        document.title = generatedPageTitle ? `Flip | ${generatedPageTitle[1]}` : 'Flip Insurance';
    }, [location]);

    return (
        <Security oktaAuth={oktaAuthClient} restoreOriginalUri={restoreOriginalUri} onAuthRequired={redirectToLogin}>
            <UserProvider>
                <QueryClientProvider client={cartQueryClient}>
                    <Providers>
                        <Router />
                    </Providers>
                </QueryClientProvider>
            </UserProvider>
        </Security>
    );
};

export default App;
