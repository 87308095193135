import React from 'react';
import Lottie from 'react-lottie';
import loadingSpinner from '../../assets/lotties/loading-spinner.json';
import loadingSpinnerTransparent from '../../assets/lotties/loading-spinner-transparent.json';

export interface LoadingSpinnerProps {
    size?: number | string;
    transparentBg?: boolean;
    loop?: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size, transparentBg, loop }) => (
    <Lottie
        options={{
            animationData: transparentBg ? loadingSpinnerTransparent : loadingSpinner,
            loop,
        }}
        height={size}
        width={size}
        ariaRole="alert"
        ariaLabel="Loading"
    />
);

LoadingSpinner.defaultProps = {
    size: 156,
    transparentBg: false,
    loop: true,
};

export default LoadingSpinner;
