import payCheckout from './payCheckout';
import applyPromoCode from './applyPromoCode';
import setupCard from './setupCard';
import getPaymentMethods from './getPaymentMethods';
import getCreditBalance from './getCreditBalance';
import getSetupIntent from './getSetupIntent';
import stripeConfirmSetup from './stripeConfirmSetup';

export default {
    setupCard,
    payCheckout,
    applyPromoCode,
    getPaymentMethods,
    getCreditBalance,
    getSetupIntent,
    stripeConfirmSetup,
};
