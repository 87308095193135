export const CancelSingleCoverFeedbackOptions = [
    {
        key: 'NOT_REQUIRED',
        label: 'My plans changed so I no longer need injury insurance.',
        index: 0,
    },
    {
        key: 'ALTERNATIVE_PRODUCT',
        label: 'I found an alternative insurance product that better meets my needs.',
        index: 1,
    },
    {
        key: 'EXPENSIVE',
        label: 'It&apos;s too expensive for what I can get.',
        index: 2,
    },
    {
        key: 'OTHER',
        label: 'Other',
        index: 3,
    },
];

export const CancelSubscriptionCoverFeedbackOptions = [
    {
        key: 'SHORT_DURATION',
        label: 'I prefer shorter term cover like Daily or Weekly.',
        index: 0,
    },
    {
        key: 'FLEXIBLE',
        label: 'I want to turn subscription on and off as I need.',
        index: 1,
    },
    {
        key: 'EXPENSIVE',
        label: 'It&apos;s too expensive for what I can get.',
        index: 2,
    },
    {
        key: 'ALTERNATIVE_PRODUCT',
        label: 'I found an alternative insurance product that better meets my needs.',
        index: 3,
    },
    {
        key: 'OTHER',
        label: 'Other',
        index: 4,
    },
];
