import CustomerService from '../../../../services/customer-service/CustomerService';
import AnnouncementKeys from '../../AnnouncementKeys';
import MobileAppLaunchAnnouncementConfig from '../MobileAppLaunchAnnouncementConfig';

const updateAnnouncementViewCount = (
    variables: Record<string, unknown>,
    accessToken: string,
    setUserVariables: (variables: Record<string, unknown>) => void,
    toMaxViews?: boolean,
): void => {
    const newViewCount =
        variables.announcementViewCount &&
        (variables.announcementViewCount as Record<string, number>)[AnnouncementKeys.MOBILE_APP_LAUNCH] !== undefined
            ? // If view count exists, increment
              (variables.announcementViewCount as Record<string, number>)[AnnouncementKeys.MOBILE_APP_LAUNCH] + 1
            : // Initialise with first view count
              1;

    const newVariables = {
        ...variables,
        announcementViewCount: {
            [AnnouncementKeys.MOBILE_APP_LAUNCH]: toMaxViews
                ? MobileAppLaunchAnnouncementConfig.MAX_VIEWS
                : newViewCount,
        },
    };

    CustomerService.updateCustomerVariables({
        accessToken,
        variables: newVariables,
    });

    setUserVariables(newVariables);
};

export default updateAnnouncementViewCount;
