import { useProduct } from '../business-logic/context-provider/ProductContext';
import { CoverTerms } from '../business-logic/models/cdn-content/CoverTerms';

/**
 * Returns CoverTermsV3 content type for a given cover code.
 * Only works for main cover for now.
 * If PDS is undefined, will return version available for purchase, otherwise null.
 *
 * @param coverCode Cover code to get content for
 * @param PDSVersion PDSVersion of cover code to get content for
 *
 * @returns CoverTermsV3 content if content exists, null if content not found
 */
const useCoverTerms = (coverCode: string, PDSVersion?: string): CoverTerms | null => {
    const { coverTerms, products } = useProduct();
    const contentByCoverCode = coverTerms[coverCode];
    if (contentByCoverCode === undefined) {
        // No content for given cover code
        return null;
    }

    // If specific PDS version is requested
    if (PDSVersion !== undefined) {
        const contentByPDSVersion = contentByCoverCode[PDSVersion];

        if (contentByPDSVersion === undefined) {
            // No content for given PDSVersion
            return null;
        }

        return contentByPDSVersion;
    }

    const correspondingProductOnSale = products.find((p) => p.productSpec.mainCoverType.coverCode === coverCode);

    // No corresponding product on sale
    if (correspondingProductOnSale === undefined) {
        return null;
    }

    const pdsVersionOnSale = `${correspondingProductOnSale.pdsVersionMajor}.${correspondingProductOnSale.pdsVersionMinor}`;

    // If PDS version on sale has no corresponding content
    if (coverTerms[coverCode] === undefined || coverTerms[coverCode][pdsVersionOnSale] === undefined) {
        return null;
    }

    return coverTerms[coverCode][pdsVersionOnSale];
};

export default useCoverTerms;
