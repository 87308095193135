enum CoverCode {
    FLIP_ACTIVE_DAILY = 'FLIP_ACTIVE_DAILY',
    FLIP_ACTIVE_WEEKLY = 'FLIP_ACTIVE_WEEKLY',
    FLIP_ACTIVE_90_DAYS = 'FLIP_ACTIVE_90_DAYS',
    FLIP_ACTIVE_180_DAYS = 'FLIP_ACTIVE_180_DAYS',
    FLIP_ACTIVE_SUB_MONTHLY = 'FLIP_ACTIVE_SUB_MONTHLY',
    FLIP_KIDS_DAILY = 'FLIP_KIDS_DAILY',
    FLIP_KIDS_WEEKLY = 'FLIP_KIDS_WEEKLY',
    FLIP_KIDS_90_DAYS = 'FLIP_KIDS_90_DAYS',
    FLIP_KIDS_180_DAYS = 'FLIP_KIDS_180_DAYS',
    FLIP_KIDS_SUB_MONTHLY = 'FLIP_KIDS_SUB_MONTHLY',
    FLIP_ROAMING_WEEKLY = 'FLIP_ROAMING_WEEKLY',
    FLIP_ROAMING_KIDS_WEEKLY = 'FLIP_ROAMING_KIDS_WEEKLY',
    GROUP_SPORTS_CONTRACT = 'GROUP_SPORTS_CONTRACT',
}

export default CoverCode;
