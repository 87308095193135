import React, { useState, useEffect } from 'react';
import { Layout } from '../../components/layout/Layout';
import { Toggle } from '../../components/toggle/Toggle';
import { useUser } from '../../business-logic/context-provider/user-context';
import profileStrings from '../../strings/profileFlow';
import common from '../../strings/common';
import Analytics from '../../analytics/Analytics';
import CustomerService from '../../services/customer-service/CustomerService';
import transformCommunicationPreferences from '../../services/customer-service/transformCommunicationPreferences';

import './ProfilePreferences.scss';

export const ProfilePreferences: React.FC = () => {
    const { yourPreferences } = profileStrings;
    // TODO: Improvement task - move into it's own hook, similar to the Stripe implementation
    const {
        accessToken,
        userPreferences,
        loading: appLoading,
        refreshUserPreferences,
        updateUserPreference,
    } = useUser();
    const { reminders, productSurveys, guidesAndPromotions, policyAndClaimUpdates, accountUpdates } = userPreferences;

    // loaders and errors
    const [isSettingPreference, setIsSettingPreference] = useState(false);
    const [hasErrorSettingPreference, setHasErrorSettingPreference] = useState<{
        [id: string]: boolean;
    }>({
        reminders: false,
        productSurveys: false,
        guidesAndPromotions: false,
        policyAndClaimUpdates: false,
        accountUpdates: false,
    });

    const onPreferenceToggle = async (id: string, checked: boolean) => {
        setIsSettingPreference(true);
        setHasErrorSettingPreference({
            reminders: false,
            productSurveys: false,
            guidesAndPromotions: false,
        });
        updateUserPreference(id, checked);

        try {
            await CustomerService.updateCustomerCommunication({
                accessToken: accessToken!,
                communication: transformCommunicationPreferences({ ...userPreferences, [id]: checked }),
            });
            Analytics.identify({ comm_preferences: userPreferences });
        } catch (error) {
            console.log(error);
            // undo check if failed to set preference and show error
            updateUserPreference(id, !checked);
            setHasErrorSettingPreference({ ...hasErrorSettingPreference, [id]: true });
            setIsSettingPreference(false);
        }

        setIsSettingPreference(false);
    };

    useEffect(() => {
        refreshUserPreferences();
    }, []);

    const preferences = [
        {
            id: 'reminders',
            header: yourPreferences.remindersTitle,
            label: yourPreferences.remindersDescription,
            checked: reminders,
            onToggle: onPreferenceToggle,
            disabled: isSettingPreference,
        },
        {
            id: 'productSurveys',
            header: yourPreferences.productSurveysTitle,
            label: yourPreferences.productSurveysDescription,
            checked: productSurveys,
            onToggle: onPreferenceToggle,
            disabled: isSettingPreference,
        },
        {
            id: 'guidesAndPromotions',
            header: yourPreferences.guidesAndPromotionsTitle,
            label: yourPreferences.guidesAndPromotionsDescription,
            checked: guidesAndPromotions,
            onToggle: onPreferenceToggle,
            disabled: isSettingPreference,
        },
        {
            id: 'policyAndClaimUpdates',
            header: yourPreferences.policyAndClaimUpdatesTitle,
            label: yourPreferences.policyAndClaimUpdatesDescription,
            checked: policyAndClaimUpdates,
            onToggle: () => {},
            disabled: true,
        },
        {
            id: 'accountUpdates',
            header: yourPreferences.accountUpdatesTitle,
            label: yourPreferences.accountUpdatesDescription,
            checked: accountUpdates,
            onToggle: () => {},
            disabled: true,
        },
    ];

    return (
        <Layout title={profileStrings.yourPrefs} bgPattern={false} showLoading={appLoading} showBackButton>
            {!appLoading &&
                preferences.map((pref) => (
                    <div key={pref.id} className="profile-preference__section">
                        <h2>{pref.header}</h2>
                        <p>{pref.label}</p>
                        <div className="profile-preference__toggle">
                            <Toggle
                                label={
                                    pref.id === 'productSurveys' ? `${common.email} & ${common.phone}` : common.email
                                }
                                id={pref.id}
                                checked={pref.checked}
                                onToggle={pref.onToggle}
                                disabled={pref.disabled}
                            />
                        </div>
                        {hasErrorSettingPreference[pref.id] && (
                            <p className="profile-preference__error">{common.errorSomethingWentWrong}</p>
                        )}
                    </div>
                ))}
        </Layout>
    );
};

export default ProfilePreferences;
