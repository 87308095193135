import { useMachine } from '@xstate/react';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Analytics from '../../../../analytics/Analytics';
import stripeLogo from '../../../../assets/images/stripe-logo.svg';
import { useUser } from '../../../../business-logic/context-provider/user-context/UserContext';
import { CartCheckout, CartErrorDisplay } from '../../../../business-logic/models/Cart';
import ProductGroupsResponse from '../../../../business-logic/models/ProductGroupsResponse';
import ProductResponse from '../../../../business-logic/models/ProductResponse';
import { AllCoverInformation } from '../../../../business-logic/models/cdn-content/CoverInformation';
import Alert, { AlertSizes, AlertTypes } from '../../../../components/alert/Alert';
import CartError from '../../../../components/cart-error/CartError';
import DisclosureWidget from '../../../../components/disclosure-widget/DisclosureWidget';
import ExternalLink from '../../../../components/external-link/ExternalLink';
import { LoadingButtonProps } from '../../../../components/loading-button/LoadingButton';
import LoadingSpinner from '../../../../components/loading-spinner/LoadingSpinner';
import PaymentMethods from '../../../../components/payment-methods/PaymentMethods';
import TextFieldWithButton from '../../../../components/text-field-with-button/TextFieldWithButton';
import useCart from '../../../../hooks/use-cart/useCart';
import commonStrings from '../../../../strings/common';
import paymentsStrings from '../../../../strings/payments';
import { PurchaseState } from '../../../../types/PurchaseState';
import Routes from '../../../../utils/Routes';
import ExternalLinks from '../../../../utils/constants/ExternalLinks';
import StorageKeys from '../../../../utils/constants/StorageKeys';
import checkoutMachine from '../../../checkout/checkout-machine/checkoutMachine';
import CheckoutSummary from '../../../checkout/checkout-summary/CheckoutSummary';

import './PaymentStep.scss';

interface PaymentStepProps {
    products: ProductResponse[];
    productGroups: ProductGroupsResponse | null;
    coverInformation: AllCoverInformation | null;
    purchaseState: PurchaseState;
    insuredPersons: {
        accountHolder: boolean;
        dependants: string[];
    };
    onInvalidCoverSelection: () => void;
    onPaymentComplete: () => void;
    onGoToPrevStep: () => void;
}

// CLONE OF CHECKOUT, WITH STYLE CHANGES
const PaymentStep: React.FC<PaymentStepProps> = ({
    products,
    productGroups,
    coverInformation,
    purchaseState,
    insuredPersons,
    onInvalidCoverSelection,
    onPaymentComplete,
    onGoToPrevStep,
}) => {
    const history = useHistory();

    const { accessToken, creditBalance } = useUser();

    const { closeCart } = useCart();

    const [state, send] = useMachine(checkoutMachine, {
        context: {
            products,
            productGroups,
            coverInformation,
            accessToken,
            purchaseState,
            insuredPersons,
            fetchCreditBalance: creditBalance.fetch,
            selectedPaymentMethod: 'card',
        },
        actions: {
            trackCheckoutStarted: () => {
                Analytics.trackCheckoutStarted({
                    products: state.context.covers.map((c) => ({
                        coverCode: c.coverCode,
                        quantity: 1,
                        variant: c.startTime,
                    })),
                });
            },
            closeCart: () => {
                closeCart.mutate(undefined, {
                    onError: (err) => {
                        send({
                            type: 'CART_CLOSE_ERROR',
                            data: (err as Error).cause as CartErrorDisplay,
                        });
                    },
                    onSuccess: (data) => {
                        send({ type: 'CART_CLOSE', data: data as CartCheckout });
                    },
                });
            },
            goBack: () => {
                history.goBack();
            },
            redirectToSuccessPage: () => {
                onPaymentComplete();

                sessionStorage.removeItem(StorageKeys.CART_COVER_SELECTION);
                history.push({
                    pathname: Routes.CART_SUCCESS,
                    state: {
                        selectedProductGrouping: null,
                        selectedProductOption: null,
                        coverStartDates: [],
                        destination: null,
                    },
                });
            },
        },
    });

    const applyDiscountCodeStatus: LoadingButtonProps['status'] = useMemo(() => {
        if (state.matches('ready.discountCode.displayDiscountSuccess')) {
            return 'success';
        }
        if (state.matches('ready.discountCode.applyDiscount')) {
            return 'loading';
        }
        return 'idle';
    }, [state]);

    const renderDiscountCodeSection = () => (
        <div className="payment-step__discount-code-disclosure-wrapper">
            <DisclosureWidget
                summary={
                    <span className="payment-step__discount-code-disclosure-summary">
                        {paymentsStrings.discountCode}
                    </span>
                }
            >
                <form className="payment-step__discount-code-form">
                    <div className="checkout__discount-code">
                        <TextFieldWithButton
                            textFieldProps={{
                                placeholder: paymentsStrings.discountCode,
                                id: 'discountCode',
                                name: 'discountCode',
                                className: 'checkout__discount-code--input',
                                value: state.context.discountCode,
                                onChange: (e) => send('ENTER_DISCOUNT_CODE', { data: e.target.value }),
                                isError: state.matches('ready.discountCode.displayDiscountError'),
                                disabled:
                                    state.matches('ready.discountCode.applyDiscount') ||
                                    state.matches('ready.discountCode.displayDiscountSuccess'),
                            }}
                            buttonProps={{
                                status: applyDiscountCodeStatus,
                                label: commonStrings.add,
                                className: 'checkout__discount-code--button',
                                onClick: (e) => {
                                    e.preventDefault();
                                    send('APPLY_DISCOUNT_CODE');
                                },
                                disabled:
                                    !state.can('APPLY_DISCOUNT_CODE') ||
                                    state.matches('ready.discountCode.displayDiscountError'),
                                type: 'submit',
                                width: 'full',
                            }}
                        />
                    </div>
                    {state.matches('ready.discountCode.displayDiscountError') && (
                        <Alert
                            type={AlertTypes.ERROR}
                            message={
                                <span className="checkout__discount-code-error-message">
                                    {state.context.discountErrorInfo}
                                </span>
                            }
                            className="checkout__discount-code-error"
                        />
                    )}
                </form>
            </DisclosureWidget>
        </div>
    );

    useEffect(() => {
        if (state.matches('redirectToDashboard')) {
            onInvalidCoverSelection();
        }
    }, [onInvalidCoverSelection, state]);

    const cartError = useMemo(() => {
        if (closeCart.isError) {
            return (closeCart.error as Error).cause;
        }
        return null;
    }, [closeCart.error, closeCart.isError]);

    return (
        <>
            {state.matches('displayErrorPage') && <Alert type={AlertTypes.ERROR} size={AlertSizes.LARGE} />}

            {!!cartError && state.matches('displayCartError') && (
                <>
                    <CartError
                        cartError={cartError as CartErrorDisplay}
                        tryAgainEvent={() => send({ type: 'CART_TRY_AGAIN' })}
                        prevStepEvent={() => onGoToPrevStep()}
                    />
                </>
            )}

            {state.hasTag('initialising') && (
                <div className="payment-step__loading-spinner-wrapper">
                    <LoadingSpinner />
                </div>
            )}

            {state.matches('ready') && (
                <>
                    {renderDiscountCodeSection()}

                    <CheckoutSummary
                        products={state.context.products}
                        checkoutDetails={state.context.checkoutDetails}
                        covers={state.context.covers}
                    />

                    <PaymentMethods state={state} send={send} />

                    {state.matches('ready.checkout.displayCheckoutError') && (
                        <Alert
                            className="checkout__error"
                            type={AlertTypes.ERROR}
                            message={<span className="checkout__error-message">{state.context.checkoutErrorInfo}</span>}
                        />
                    )}

                    <p className="checkout-disclaimer">
                        {paymentsStrings.formatString(paymentsStrings.disclaimer, {
                            stripeTerms: (
                                <ExternalLink to={ExternalLinks.stripeTerms} label={paymentsStrings.stripeTermsLabel} />
                            ),
                        })}
                    </p>
                    <div className="checkout-powered-by">
                        <div className="checkout-powered-by__row">
                            {paymentsStrings.poweredBy} <img src={stripeLogo} alt="Stripe" />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default PaymentStep;
