import React, { ButtonHTMLAttributes, MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import arrow from '../../assets/images/arrow-left-black.svg';
import blueArrow from '../../assets/images/arrow-left-blue.svg';

import './Button.scss';

export interface ButtonProps {
    variant?: 'primary' | 'secondary' | 'tertiary' | 'link' | 'primary-invert' | 'secondary-invert' | 'drawer-link';
    width?: 'full' | 'standard';
    size?: 'large' | 'small';
    label: string | ReactNode;
    className?: string;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    type?: 'submit' | 'reset' | 'button';
}

export const Button: React.FC<ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
    variant = 'primary',
    width = 'standard',
    label,
    disabled,
    className,
    type = 'button',
    size = 'large',
    ...props
}) => {
    return (
        <button
            // button types are already restricted by TS
            // eslint-disable-next-line react/button-has-type
            type={type}
            className={classNames(
                'button',
                `button--${variant}`,
                disabled && 'disabled',
                `button--${width}`,
                `button--${size}`,
                className,
            )}
            disabled={disabled}
            aria-disabled={disabled}
            {...props}
        >
            {label}
            {variant === 'drawer-link' && (
                <div className="button--drawer-link__icon">
                    <img src={arrow} alt="" className="button--drawer-link__icon__arrow arrow--black" />
                    <img src={blueArrow} alt="" className="button--drawer-link__icon__arrow arrow--blue" />
                </div>
            )}
        </button>
    );
};

export default Button;
