import { differenceInYears, parse } from 'date-fns';
import DateFormat from './constants/DateFormat';

export const ACTIVE_MIN_AGE = 18;
export const ACTIVE_MAX_AGE = 64;
export const KIDS_MIN_AGE = 5;
export const KIDS_MAX_AGE = 17;

interface IsAgeWithinRangeProps {
    dateOfBirth: string;
    dates: string[];
    isAdult: boolean;
}

function isAgeWithinRange({ dateOfBirth, dates, isAdult = true }: IsAgeWithinRangeProps): boolean {
    const dob = parse(dateOfBirth, DateFormat.DEFAULT, new Date());

    return dates.every((dateString: string) => {
        const date = parse(dateString, DateFormat.DEFAULT, new Date());

        // Calculate the age at that particular date
        const age = differenceInYears(date, dob);

        // Check if the age is within given range
        return age >= (isAdult ? ACTIVE_MIN_AGE : KIDS_MIN_AGE) && age <= (isAdult ? ACTIVE_MAX_AGE : KIDS_MAX_AGE);
    });
}

export default isAgeWithinRange;
