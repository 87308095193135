import CustomerService from '../../../services/customer-service/CustomerService';
import { ActivitiesSelectionMachineContextTypes } from '../context/ActivitiesSelectionMachineContext';

export type ActivitiesStepMachineServices = {
    updateCustomerProfile: {
        data: void;
    };
};

export const updateProfileActivities = async (ctx: ActivitiesSelectionMachineContextTypes): Promise<void> => {
    const activitiesToSubmit = ctx.activities
        .filter((x) => x.activityState.selected)
        .map((y) => {
            return !!y.activityConfig.freeText && y.activityState.freeTextValue
                ? `${y.activityConfig.label}: ${y.activityState.freeTextValue}`
                : y.activityConfig.label;
        });
    if (ctx.updateProfile) {
        await CustomerService.updateCustomerProfile({
            accessToken: ctx.accessToken!,
            profile: {
                activities: activitiesToSubmit,
            },
        });
    }
};
