import { FC, useRef, useState } from 'react';
import classNames from 'classnames';
import greenCheck from '../../../../assets/images/deep-green-check.svg';
import blueChevron from '../../../../assets/images/icon-chevron-down-blue.svg';
import personIcon from '../../../../assets/images/icon-person.svg';
import { useUser } from '../../../../business-logic/context-provider/user-context/UserContext';

import './ManageCoverPersonSelector.scss';

interface ManageCoverPersonSelectorProps {
    onSelectPerson: (personId: string) => void;
    selectedPersonId: string;
}

const ManageCoverPersonSelector: FC<ManageCoverPersonSelectorProps> = ({ selectedPersonId, onSelectPerson }) => {
    const { dependants } = useUser();
    const [isExpanded, setIsExpanded] = useState(false);
    const listRef = useRef<HTMLDivElement>(null);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="manage-cover-v3__selector">
            <button
                type="button"
                disabled={dependants.length === 0}
                className="manage-cover-v3__selector__expander"
                onClick={() => {
                    if (dependants.length > 0) {
                        toggleExpand();
                    }
                }}
                onKeyDown={() => {
                    onSelectPerson('');
                }}
            >
                <div className="manage-cover-v3__selector__expander__label">
                    <img src={personIcon} alt="" className="manage-cover-v3__selector__expander__icon" />
                    <span>
                        {selectedPersonId === ''
                            ? 'Your'
                            : `${dependants.find((x) => x.personId === selectedPersonId)?.firstName}'s`}{' '}
                        cover
                    </span>
                </div>
                {dependants.length > 0 && (
                    <div className="manage-cover-v3__selector__expander__action">
                        <span className="manage-cover-v3__selector__expander__action__label">change</span>
                        <img
                            src={blueChevron}
                            className={classNames(
                                'manage-cover-v3__selector__expander__action__icon',
                                isExpanded && 'manage-cover-v3__selector__expander__action__icon--selected',
                            )}
                            alt=""
                        />
                    </div>
                )}
            </button>
            {!!dependants.length && (
                <div
                    className={classNames('manage-cover-v3__selector__list')}
                    ref={listRef}
                    style={{
                        maxHeight: isExpanded ? `${listRef?.current?.scrollHeight}px` : '0px',
                    }}
                >
                    <hr className="manage-cover-v3__selector__divider" />
                    <button
                        type="button"
                        className={classNames(
                            'manage-cover-v3__selector__option',
                            selectedPersonId === '' && 'manage-cover-v3__selector__option__label--selected',
                        )}
                        onClick={() => {
                            onSelectPerson(''); // Empty string is accountHolder
                            toggleExpand();
                        }}
                    >
                        <span className="manage-cover-v3__selector__option__label">Your cover</span>
                        {selectedPersonId === '' && (
                            <img src={greenCheck} alt="" className="manage-cover-v3__selector__option__icon" />
                        )}
                    </button>
                    {dependants.map((kid) => (
                        <div key={kid.personId}>
                            <hr className="manage-cover-v3__selector__divider" />
                            <button
                                type="button"
                                className={classNames(
                                    'manage-cover-v3__selector__option',
                                    selectedPersonId === kid.personId &&
                                        'manage-cover-v3__selector__option__label--selected',
                                )}
                                onClick={() => {
                                    onSelectPerson(kid.personId!);
                                    toggleExpand();
                                }}
                            >
                                <span
                                    className={classNames(
                                        'manage-cover-v3__selector__option__label',
                                        selectedPersonId === kid.personId &&
                                            'manage-cover-v3__selector__option--selected',
                                    )}
                                >
                                    {`${kid.firstName}'s`} cover
                                </span>
                                {selectedPersonId === kid.personId && (
                                    <img src={greenCheck} alt="" className="manage-cover-v3__selector__option__icon" />
                                )}
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ManageCoverPersonSelector;
