import LocalizedStrings from 'react-localization';

const login = new LocalizedStrings({
    en: {
        heading: 'Welcome!',
        subheading: 'We use your email address to identify you.\nEnter your email to continue',
    },
});

export default login;
